export interface FlagType {
  flag: string;
  enabled: boolean;
  mock?: boolean;
}

export function getFlags(): FlagType[] {
  const flagsJSON = localStorage.getItem("moovFeatureFlags");
  let flags: FlagType[] = [];
  if (flagsJSON) {
    try {
      flags = JSON.parse(flagsJSON) as FlagType[];
    } catch {
      flags = [];
    }
  }
  return flags;
}

export default function initFeatureFlagHelper() {
  const moovFlags = {
    getEnabledFlags(): string[] {
      return getFlags()
        .filter((flag: FlagType) => flag.enabled === true)
        .map((flag: FlagType) => flag.flag);
    },
    setFlag(flag: string, enabled: boolean, mock = false): void {
      const allFlags = getFlags();
      const flagToEnable = allFlags.find((flagObj: FlagType) => flagObj.flag === flag);
      if (flagToEnable) {
        flagToEnable.enabled = enabled;
        flagToEnable.mock = mock;
      } else {
        allFlags.push({ flag, enabled, mock });
      }
      localStorage.setItem("moovFeatureFlags", JSON.stringify(allFlags));
    }
  };

  // @ts-expect-error TODO
  window.moovFlags = moovFlags;
}
