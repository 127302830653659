import { DeepPartial } from "@moovfinancial/common/types/DeepTypes";
import type { components } from "@moovfinancial/common/types/__generated-types__/moov-api";
import deepMerge from "@moovfinancial/common/utils/deepMerge";
import { TransferState, actions } from "pages/transfers/WalletTransfers/WalletTransferState";
import { mockAccount } from "./accounts/accounts.mock";
import { mockDispute, mockDisputeEnrichedList } from "./disputes.mock";
import { mockCard } from "./paymentMethods/card.mock";
import { mockPaymentMethod } from "./paymentMethods/paymentMethods.mock";

type Transfer = components["schemas"]["Transfer"];
type BankAccount = components["schemas"]["BankAccount"];

export const generateMockTransfer = (override: DeepPartial<Transfer> = {}): Transfer => {
  return deepMerge(baseMockTransfer, override) as Transfer;
};

/**
 * @deprecated Should use dynamic mocks in the future;
 */

const baseMockTransfer: Transfer = {
  transferID: "transfer-id",
  createdOn: "created-on",
  status: "pending",
  amount: {
    currency: "USD",
    value: 100
  },
  source: {
    paymentMethodType: "ach-debit-fund",
    paymentMethodID: "source-payment-method-id",
    account: {
      accountID: "source-account-id",
      email: "source@example.com",
      displayName: "Source Display Name"
    }
  },
  destination: {
    paymentMethodType: "ach-credit-standard",
    paymentMethodID: "destination-payment-method-id",
    account: {
      accountID: "destination-account-id",
      email: "destination@example.com",
      displayName: "Destination Display Name"
    }
  }
};

export const transferV2: Transfer = {
  transferID: "ec7e1848-dc80-4ab0-8827-833969de672ba3",
  createdOn: "2019-08-24T14:15:22Z",
  status: "completed",
  source: {
    transferID: "ec7e1848-dc80-4ab0-8827-dd7fc0737b43",
    paymentMethodID: "ec7e1848-dc80-4ab0-8827-dd7fc0737b43",
    paymentMethodType: "moov-wallet",
    bankAccount: {
      bankAccountID: "ec7e1848-dc80-4ab0-8827-dd7fc0737b43",
      fingerprint: "9948962d92a1ce40c9f918cd9ece3a22bde62fb325a2f1fe2e833969de672ba3",
      status: "verified",
      holderName: "Jules Jackson",
      holderType: "individual",
      bankName: "Chase Bank",
      bankAccountType: "checking",
      routingNumber: "string",
      lastFourAccountNumber: "7000"
    } as BankAccount,
    account: {
      accountID: "3dfff852-927d-47e8-822c-2fffc57ff6b9",
      email: "amanda@classbooker.dev",
      displayName: "Whole Body Fitness"
    },
    achDetails: {
      status: "initiated",
      traceNumber: "124782618117",
      return: {
        code: "string",
        reason: "string",
        description: "string"
      },
      correction: {
        code: "string",
        reason: "string",
        description: "string"
      }
    }
  },
  destination: {
    paymentMethodID: "ec7e1848-dc80-4ab0-8827-dd7fc0737b43",
    paymentMethodType: "moov-wallet",
    bankAccount: {
      bankAccountID: "ec7e1848-dc80-4ab0-8827-dd7fc0737b43",
      holderName: "Jules Jackson",
      holderType: "individual",
      bankName: "Chase Bank",
      bankAccountType: "checking",
      lastFourAccountNumber: "7000"
    } as BankAccount,
    account: {
      accountID: "3dfff852-927d-47e8-822c-2fffc57ff6b9",
      email: "amanda@classbooker.dev",
      displayName: "Whole Body Fitness"
    },
    achDetails: {
      status: "completed",
      traceNumber: "124782618117",
      return: {
        code: "string",
        reason: "string",
        description: "string"
      },
      correction: {
        code: "string",
        reason: "string",
        description: "string"
      }
    }
  },
  amount: {
    currency: "USD",
    value: 1204
  },
  description: "Pay Instructor for May 15 Class"
};

/**
 * @deprecated Should use dynamic mocks in the future;
 */
export const mockTransferState: TransferState = {
  step: 0,
  amount: 15000,
  title: "",
  transferType: "payout",
  detailType: "",
  sourceAccount: mockAccount,
  destinationAccount: mockAccount,
  sourcePaymentMethods: [mockPaymentMethod],
  destinationPaymentMethods: [mockPaymentMethod],
  sourcePaymentMethod: mockPaymentMethod,
  destinationPaymentMethod: mockPaymentMethod,
  description: "A description",
  activeAccount: mockAccount,
  wallet: undefined,
  walletType: "moov-wallet",
  error: null,
  actions: actions,
  idempotencyKey: "guid"
};

/**
 * @deprecated Should use dynamic mocks in the future;
 */
export const mockTransfer: Transfer = {
  transferID: "ec7e1848-dc80-4ab0-8827-dd7fc0737b43",
  createdOn: "2019-08-24T14:15:22Z",
  status: "completed",
  source: {
    paymentMethodID: "ec7e1848-dc80-4ab0-8827-dd7fc0737b43",
    paymentMethodType: "moov-wallet",
    bankAccount: {
      updatedOn: "2019-08-24T14:15:22Z",
      bankAccountID: "ec7e1848-dc80-4ab0-8827-dd7fc0737b43",
      fingerprint: "9948962d92a1ce40c9f918cd9ece3a22bde62fb325a2f1fe2e833969de672ba3",
      status: "verified",
      holderName: "Jules Jackson",
      holderType: "individual",
      bankName: "Chase Bank",
      bankAccountType: "checking",
      routingNumber: "string",
      lastFourAccountNumber: "7000"
    },
    account: {
      accountID: "3dfff852-927d-47e8-822c-2fffc57ff6b9",
      email: "amanda@classbooker.dev",
      displayName: "Whole Body Fitness"
    }
  },
  destination: {
    paymentMethodID: "ec7e1848-dc80-4ab0-8827-dd7fc0737b43",
    paymentMethodType: "moov-wallet",
    bankAccount: {
      updatedOn: "2019-08-24T14:15:22Z",
      bankAccountID: "ec7e1848-dc80-4ab0-8827-dd7fc0737b43",
      fingerprint: "9948962d92a1ce40c9f918cd9ece3a22bde62fb325a2f1fe2e833969de672ba3",
      status: "new",
      holderName: "Jules Jackson",
      holderType: "individual",
      bankName: "Chase Bank",
      bankAccountType: "checking",
      routingNumber: "string",
      lastFourAccountNumber: "7000"
    },
    account: {
      accountID: "3dfff852-927d-47e8-822c-2fffc57ff6b9",
      email: "amanda@classbooker.dev",
      displayName: "Whole Body Fitness"
    }
  },
  amount: {
    currency: "USD",
    value: 1204
  },
  description: "Pay Instructor for May 15 Class"
};

export const mockTransferWithDisputes = {
  ...mockTransfer,
  disputes: mockDisputeEnrichedList()
};

/**
 * @deprecated Should use dynamic mocks in the future;
 */
export const mockRefundedTransfer: Transfer = {
  ...mockTransfer,
  refundedAmount: {
    currency: "USD",
    value: 1204
  },
  refunds: [
    {
      refundID: "ec7e1848-dc80-4ab0-8827-dd7fc0737b43",
      createdOn: "2021-09-21T18:53:04Z",
      updatedOn: "2021-09-21T18:53:05Z",
      status: "completed",
      amount: {
        currency: "USD",
        value: 1204
      }
    }
  ]
};

/**
 * @deprecated Should use dynamic mocks in the future;
 */
export const mockDisputedTransfer: Transfer = {
  ...mockTransfer,
  source: {
    paymentMethodID: "ec7e1848-dc80-4ab0-8827-dd7fc0737b43",
    paymentMethodType: "card-payment",
    account: mockTransfer.source.account,
    card: mockCard,
    cardDetails: {
      status: "completed"
    }
  },
  disputedAmount: mockDispute.amount,
  disputes: [
    {
      disputeID: mockDispute.disputeID,
      createdOn: mockDispute.createdOn,
      amount: mockDispute.amount
    }
  ]
};

export const mockCreditToWalletCancelable = generateMockTransfer({
  source: {
    cardDetails: { status: "confirmed" }
  },
  destination: {
    paymentMethodType: "moov-wallet"
  }
});

export const mockGroupParent = generateMockTransfer({
  transferID: "parent-transfer-id",
  source: {
    paymentMethodType: "ach-debit-fund",
    achDetails: { status: "initiated" }
  },
  destination: {
    paymentMethodType: "ach-credit-standard",
    achDetails: { status: "initiated" }
  },
  groupID: "group-id"
});

export const mockGroup = [
  mockGroupParent,
  {
    source: { transferID: "parent-transfer-id" },
    groupID: "group-id"
  }
].map(generateMockTransfer);

export const mockBankToBankCancelable = generateMockTransfer({
  status: "pending",
  source: {
    paymentMethodType: "ach-debit-fund",
    achDetails: { status: "initiated" }
  },
  destination: {
    paymentMethodType: "ach-credit-standard",
    achDetails: { status: "initiated" }
  }
});

export const mockBankToBankPartialCancellationPending = generateMockTransfer({
  status: "pending",
  cancellations: [{ status: "pending" }],
  source: {
    paymentMethodType: "ach-debit-fund",
    achDetails: { status: "originated" }
  },
  destination: {
    paymentMethodType: "ach-credit-standard",
    achDetails: { status: "initiated" }
  }
});

export const mockBankToBankPartialCancellationReversed = generateMockTransfer({
  status: "reversed",
  cancellations: [{ status: "completed" }],
  source: {
    paymentMethodType: "ach-debit-collect",
    achDetails: { status: "originated" }
  },
  destination: {
    paymentMethodType: "ach-credit-standard",
    achDetails: { status: "initiated" }
  }
});
