import { Chance } from "chance";
import { components } from "@moovfinancial/common/types/__generated-types__/moov-api";

type Cancellation = components["schemas"]["Cancellation"];
type CancellationStatus = components["schemas"]["CancellationStatus"];

const chance = new Chance();

const getRandomCancellationStatus = (): CancellationStatus => {
  return chance.pickone(["pending", "completed", "failed"]);
};

export const generateMockCancellation = ({
  status
}: {
  status?: CancellationStatus;
}): Cancellation => {
  return {
    cancellationID: chance.guid(),
    createdOn: chance.date().toISOString(),
    status: status ?? getRandomCancellationStatus()
  };
};
