import { matchPath } from "react-router";
import { intersection, pathOr, unique } from "remeda";
import { FieldMeta } from "@moovfinancial/cargo";
import { NavigationStep } from "@moovfinancial/cargo/src/components/Complex/Stepper/step.model";
import { type DeepPartial, NestedKeyOf } from "@moovfinancial/common/types/DeepTypes";
import { components } from "@moovfinancial/common/types/__generated-types__/moov-api";
import { CompanyAddressFormState } from "pages/onboarding/company/CompanyAddress";
import { CompanyInfoFormState } from "pages/onboarding/company/CompanyInformation";
import {
  AddOwnerFormState,
  type Representative,
  mapRepresentativeToFormState
} from "pages/onboarding/owners/AddOwner";
import { Account } from "./OnboardingContext";
import { FormFields, StepNavigation } from "./OnboardingStepsContext";

export type BankAccount = components["schemas"]["BankAccount"];
export type FileDetails = components["schemas"]["FileDetails"];
export type Underwriting = components["schemas"]["Underwriting"];
export type ValidatedCompanyInfoFields = keyof ReturnType<typeof getValidatedCompanyInfoFields>;
export type ValidatedCompanyAddressFields = keyof ReturnType<
  typeof getValidatedCompanyAddressFields
>;
export type ValidatedOwnerFields = keyof ReturnType<typeof getValidatedOwnerFields>;
export type ValidatedTransactionsFields = keyof ReturnType<typeof getValidatedTransactionsFields>;
export type ValidatedBankAccountFields = keyof ReturnType<typeof getValidatedBankAccountFields>;

type Invite = components["schemas"]["OnboardingInvite"];

// Matches sub-step paths (e.g., /business/information, /business/address, /owners/add, etc.) to ensure navigation is complete before warning for unsaved changes
// Necessary to prevent duplicate renders of the unsaved changes modal given our nested routes architecture in onboarding
export const ONBOARDING_PATH_WITH_SUB_STEP =
  /(business|owners|expected-activity|bank-account)\/[^/]+/;
const FINISH_STEP_PATH_REGEX = new RegExp(/\/finish/);
export const isAtFinishStep = (location: string) => FINISH_STEP_PATH_REGEX.test(location);

export const getPossiblyNestedProperty = <T extends object>(obj: T, key: NestedKeyOf<T>) => {
  // Splits the keys into an array that pathOr can use. Also works with keys that don't use dot notation.
  // e.g. "profile.business.address.addressLine1" -> ["profile", "business", "address", "addressLine1"]
  const path = key.split(".");
  // @ts-expect-error TODO FE-1210: Fix this in a follow up PR
  return pathOr(obj, path, "");
};

export const isBankAccountsComplete = (bankAccounts: BankAccount[]) => bankAccounts.length > 0;

export const getRequiredFieldKeys = <T extends string>(
  fields: Record<T, Partial<FieldMeta>>
): T[] => {
  return Object.keys(fields).filter((key): key is T => isValidatedField(key, fields));
};

export const isBusinessAddressComplete = (
  account: DeepPartial<Account>,
  addressFields: FormFields<ValidatedCompanyAddressFields>
): boolean => {
  return getRequiredFieldKeys(addressFields).every((field) => {
    const valueAtPath = getPossiblyNestedProperty(account, field as NestedKeyOf<typeof account>);
    return typeof valueAtPath === "string" ? valueAtPath.trim().length > 0 : !!valueAtPath;
  });
};

export const isBusinessInfoComplete = (
  account: DeepPartial<Account>,
  businessFields: FormFields<ValidatedCompanyInfoFields>
) => {
  const business = account.profile?.business;
  if (!business) return false;

  return getRequiredFieldKeys(businessFields).every((field) => {
    const valueAtPath = getPossiblyNestedProperty(business, field as NestedKeyOf<typeof business>);
    return typeof valueAtPath === "string" ? valueAtPath.trim().length > 0 : !!valueAtPath;
  });
};

export const isProcessingStatementsComplete = (
  files: FileDetails[] | undefined,
  certifiedNoStatements: boolean
) => {
  return certifiedNoStatements || (!!files && files.length > 0);
};

const isRepresentativeComplete = (
  representative: Representative,
  ownerFields: FormFields<ValidatedOwnerFields>
) => {
  return getRequiredFieldKeys(ownerFields).every((field) => {
    const isValuePresent = !!getPossiblyNestedProperty(
      representative,
      field as NestedKeyOf<Representative>
    );
    return isValuePresent;
  });
};

export const isRepresentativesComplete = (
  account: DeepPartial<Account>,
  representatives: Representative[]
) => {
  const hasRepresentatives = representatives.length > 0;
  const ownersProvided = !!account.profile?.business?.ownersProvided;
  const allRepresentativesComplete = representatives.every((representative) => {
    return isRepresentativeComplete(
      representative,
      // Note: representative validation requires this mapping since we don't have all representatives stored in the form state
      getValidatedOwnerFields(mapRepresentativeToFormState(representative))
    );
  });

  return ownersProvided && hasRepresentatives && allRepresentativesComplete;
};

export const isUnderwritingComplete = (
  underwriting: DeepPartial<Underwriting>,
  transactionFields: FormFields<ValidatedTransactionsFields>
) => {
  return getRequiredFieldKeys(transactionFields).every((field) => {
    const value = getPossiblyNestedProperty(
      underwriting,
      field as NestedKeyOf<typeof underwriting>
    );
    // 0 is a valid value for underwriting fields, so we explicitly check for that case
    return !!value || value === 0;
  });
};

export const areBusinessCapabilities = (capabilities: Invite["capabilities"]) =>
  unique(intersection(capabilities, ["collect-funds", "send-funds", "card-issuing", "wallet"]))
    .length > 0;

export const getStepNavigation = (
  onboardingPath: string,
  capabilities: Invite["capabilities"],
  isFinancialInstitution: boolean
): StepNavigation =>
  ({
    [`${onboardingPath}/business`]: {
      next: `${onboardingPath}/business/information`,
      previous: `${onboardingPath}`
    },
    [`${onboardingPath}/business/information`]: {
      next: `${onboardingPath}/business/address`,
      previous: `${onboardingPath}/business`
    },
    [`${onboardingPath}/business/address`]: {
      next: areBusinessCapabilities(capabilities)
        ? // Note: no owners step for financial institutions
          isFinancialInstitution
          ? `${onboardingPath}/expected-activity/transactions`
          : `${onboardingPath}/owners`
        : `${onboardingPath}/bank-account`,
      previous: `${onboardingPath}/business/information`
    },
    [`${onboardingPath}/owners`]: {
      next: `${onboardingPath}/expected-activity/transactions`,
      previous: `${onboardingPath}/business/address`
    },
    [`${onboardingPath}/expected-activity/transactions`]: {
      next: capabilities.includes("collect-funds")
        ? `${onboardingPath}/expected-activity/fulfillment`
        : `${onboardingPath}/expected-activity/processing-statements`,
      // Note: no owners step for financial institutions
      previous: isFinancialInstitution
        ? `${onboardingPath}/business/address`
        : `${onboardingPath}/owners`
    },
    [`${onboardingPath}/expected-activity/fulfillment`]: {
      next: `${onboardingPath}/expected-activity/processing-statements`,
      previous: `${onboardingPath}/expected-activity/transactions`
    },
    [`${onboardingPath}/expected-activity/processing-statements`]: {
      next: `${onboardingPath}/bank-account`,
      previous: capabilities.includes("collect-funds")
        ? `${onboardingPath}/expected-activity/fulfillment`
        : `${onboardingPath}/expected-activity/transactions`
    },
    [`${onboardingPath}/bank-account`]: {
      next: `${onboardingPath}/review`,
      previous: areBusinessCapabilities(capabilities)
        ? `${onboardingPath}/expected-activity/processing-statements`
        : `${onboardingPath}/business/address`
    },
    [`${onboardingPath}/review`]: {
      next: `${onboardingPath}/finish`,
      previous: `${onboardingPath}/bank-account`
    }
  }) as const;

export function findCurrentStep(steps: NavigationStep[], location: string) {
  let currentStep: NavigationStep | undefined;
  steps.forEach((step) => {
    const stepPath = typeof step.to === "string" ? step.to : (step.to?.pathname ?? "");
    if (matchPath({ path: stepPath, end: !!step.children?.length }, location)) {
      currentStep = step;
    } else if (step.children) {
      const childStep = findCurrentStep(step.children, location);
      if (childStep) {
        currentStep = childStep;
      }
    }
  });
  return currentStep;
}

/**
 * Type guard to check if a field key has validation configured
 * @param key - Field key to check
 * @param fields - Object containing field metadata
 * @returns boolean - True if field has validation configured
 */
export const isValidatedField = <T extends string>(
  key: string,
  fields: Record<T, Partial<FieldMeta>>
): key is T => {
  return key in fields && !!fields[key as T].validate;
};

/**
 * Returns validated fields for the company information step
 * @param formState - Current form state for the company information step
 * @param hasExpectedActivityStep - Whether expected activity step is enabled
 * @param isFinancialInstitution - Whether business is a financial institution
 * @returns Record of validated fields with validation function
 * @note Some fields are dynamically validated based on the form state. An undefined "validate" function means the field is not required.
 * @example
 * getValidatedCompanyInfoFields(formState, true, false) // returns { legalBusinessName: { validate: ... }, ... }
 */
export const getValidatedCompanyInfoFields = (
  formState: CompanyInfoFormState,
  hasExpectedActivityStep: boolean,
  isFinancialInstitution: boolean
): Record<string, Partial<FieldMeta>> => {
  return {
    businessType: {
      message: "Business type is required",
      validate: (element) => element.value.trim().length > 0
    },
    email: {
      message: "A valid email is required",
      validate: (element) => element.value.trim().length > 0
    },
    industryCodes: {
      message: "A valid industry code is required",
      validate: (element) => element.value.trim().length > 0
    },
    legalBusinessName: {
      message: "Legal business name is required",
      validate: (element) => element.value.trim().length > 0
    },
    phone: {
      message: "A valid phone number is required",
      validate: (element) => element.value.trim().length > 0
    },
    website: {
      message: "A valid website is required",
      validate: (element) => element.value.trim().length > 0
    },
    doingBusinessAs: {
      message: "DBA is required",
      validate: formState.isDBASelected ? (element) => element.value.trim().length > 0 : undefined
    },
    description: {
      message: "Description is required",
      validate: hasExpectedActivityStep ? (element) => element.value.trim().length > 0 : undefined
    },
    ein: {
      message: "A valid ID is required",
      validate: !formState.taxIDProvided ? (element) => element.value.trim().length > 0 : undefined
    },
    primaryRegulator: {
      message: "A primary regulator is required",
      validate: isFinancialInstitution ? (element) => element.value.trim().length > 0 : undefined
    }
  };
};

/**
 * Returns validated fields for the company address step
 * @param formState - Current form state of the company address step
 * @returns Record of validated address fields with validation functions
 * @note Some fields are dynamically validated based on the form state. An undefined "validate" function means the field is not required.
 * @example
 * getValidatedCompanyAddressFields(formState) // returns { "profile.business.address.city": { validate: ... }, ... }
 */
export const getValidatedCompanyAddressFields = (
  formState: CompanyAddressFormState
): Record<string, Partial<FieldMeta>> => {
  return {
    "profile.business.address.addressLine1": {
      message: "Street address is required",
      validate: (element) => element.value.trim().length > 0
    },
    "profile.business.address.addressLine2": {},
    "profile.business.address.city": {
      message: "City is required",
      validate: (element) => element.value.trim().length > 0
    },
    "profile.business.address.postalCode": {
      message: "Postal code is required",
      validate: (element) => element.value.trim().length > 0
    },
    "profile.business.address.stateOrProvince": {
      message: "State or province is required",
      validate: (element) => element.value.trim().length > 0
    },
    "customerSupport.address.addressLine1": {
      message: "Street address is required",
      validate: !formState.isSameSupportAddress
        ? (element) => element.value.trim().length > 0
        : undefined
    },
    "customerSupport.address.addressLine2": {},
    "customerSupport.address.city": {
      message: "City is required",
      validate: !formState.isSameSupportAddress
        ? (element) => element.value.trim().length > 0
        : undefined
    },
    "customerSupport.address.postalCode": {
      message: "Postal code is required",
      validate: !formState.isSameSupportAddress
        ? (element) => element.value.trim().length > 0
        : undefined
    },
    "customerSupport.address.stateOrProvince": {
      message: "State or province is required",
      validate: !formState.isSameSupportAddress
        ? (element) => element.value.trim().length > 0
        : undefined
    }
  };
};

/**
 * Returns validated fields for owner information step
 * @param formState - Current form state of the owner information step
 * @returns Record of validated owner fields with validation functions
 * @note Some fields are dynamically validated based on the form state. An undefined "validate" function means the field is not required.
 * @example
 * getValidatedOwnerFields(formState) // returns { "name.firstName": { validate: ... }, ... }
 */
export const getValidatedOwnerFields = (
  formState: AddOwnerFormState
): Record<string, Partial<FieldMeta>> => {
  const isOwnerOrController = formState.isOwner || formState.isController;

  return {
    "address.addressLine1": {
      message: "A valid street address is required",
      validate: isOwnerOrController ? (element) => element.value.trim().length > 0 : undefined
    },
    birthDate: {
      message: "A valid date of birth is required",
      validate:
        isOwnerOrController && !formState.birthDateProvided
          ? (element) => element.value.trim().length > 0
          : undefined
    },
    "address.city": {
      message: "A valid city is required",
      validate: isOwnerOrController ? (element) => element.value.trim().length > 0 : undefined
    },
    email: {
      message: "A valid email is required",
      validate: isOwnerOrController ? (element) => element.value.trim().length > 0 : undefined
    },
    "name.firstName": {
      message: "A valid first name is required",
      validate: (element) => element.value.trim().length > 0
    },
    governmentID: {
      message: "A valid government ID is required",
      validate:
        !formState.governmentIDProvided && isOwnerOrController
          ? (element) => element.value.trim().length > 0
          : undefined
    },
    "responsibilities.jobTitle": {
      message: "A job title is required",
      validate: formState.isController ? (element) => element.value.trim().length > 0 : undefined
    },
    "name.lastName": {
      message: "A valid last name is required",
      validate: (element) => element.value.trim().length > 0
    },
    "responsibilities.ownershipPercentage": {
      message: "Ownership percentage must be equal to or greater than 25%.",
      validate: formState.isOwner ? (element) => parseInt(element.value) >= 25 : undefined
    },
    "phone.number": {
      message: "A valid phone number is required",
      validate: isOwnerOrController ? (element) => element.value.trim().length > 0 : undefined
    },
    "address.postalCode": {
      message: "A valid zip code is required",
      validate: isOwnerOrController ? (element) => element.value.trim().length > 0 : undefined
    },
    "address.stateOrProvince": {
      message: "A valid state or province is required",
      validate: isOwnerOrController ? (element) => element.value.trim().length > 0 : undefined
    }
  };
};

/**
 * Returns validated fields for transactions step
 * @returns Record of validated transaction fields with validation functions
 * @example
 * getValidatedTransactionsFields() // returns { averageMonthlyTransactionVolume: { validate: ... }, ... }
 */
export const getValidatedTransactionsFields = (): Record<string, Partial<FieldMeta>> => {
  return {
    averageMonthlyTransactionVolume: {
      message: "Average monthly volume is required",
      validate: (element) => element.value !== "$0" && element.value.trim().length > 0
    },
    maxTransactionSize: {
      message: "Maximum transaction amount is required",
      validate: (element) => element.value !== "$0" && element.value.trim().length > 0
    },
    averageTransactionSize: {
      message: "Average transaction amount is required",
      validate: (element) => element.value !== "$0" && element.value.trim().length > 0
    },
    "volumeByCustomerType.businessToBusinessPercentage": {
      message: "Business to business percentage is required",
      validate: (element) => element.value.trim().length > 0
    },
    "volumeByCustomerType.consumerToBusinessPercentage": {
      message: "Consumer to business percentage is required",
      validate: (element) => element.value.trim().length > 0
    },
    "cardVolumeDistribution.cardPresentPercentage": {
      message: "Card present percentage is required",
      validate: (element) => element.value.trim().length > 0
    },
    "cardVolumeDistribution.debtRepaymentPercentage": {
      message: "Debt repayment percentage is required",
      validate: (element) => element.value.trim().length > 0
    },
    "cardVolumeDistribution.ecommercePercentage": {
      message: "E-commerce percentage is required",
      validate: (element) => element.value.trim().length > 0
    },
    "cardVolumeDistribution.mailOrPhonePercentage": {
      message: "Mail or phone percentage is required",
      validate: (element) => element.value.trim().length > 0
    }
  };
};

/**
 * Returns validated fields for bank account step
 * @returns Record of validated bank account fields with validation functions
 * @example
 * getValidatedBankAccountFields() // returns { accountNumber: { validate: ... }, ... }
 */
export const getValidatedBankAccountFields = (): Record<string, Partial<FieldMeta>> => {
  return {
    accountNumber: {
      message: "Please enter a valid account number",
      validate: (element) => element.value.trim().length > 0
    },
    bankAccountType: {
      message: "Please select an account type",
      validate: (element) => element.value.trim().length > 0
    },
    holderName: {
      message: "Please enter the account holder name",
      validate: (element) => element.value.trim().length > 0
    },
    holderType: {
      message: "Please select an account holder type",
      validate: (element) => element.value.trim().length > 0
    },
    routingNumber: {
      message: "Please enter a valid routing number",
      validate: (element) => element.value.trim().length > 0
    }
  };
};
