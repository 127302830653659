import { components } from "@moovfinancial/common/types/__generated-types__/moov-api";
import { PagingFilter } from "./common.model";

export type WalletTransactionType = components["schemas"]["WalletTransactionType"];

export const walletTransactionTypes: WalletTransactionType[] = [
  "account-funding",
  "ach-reversal",
  "adjustment",
  "auto-sweep",
  "card-decline",
  "card-payment",
  "card-reversal",
  "cash-out",
  "dispute-reversal",
  "dispute",
  "facilitator-fee",
  "issuing-auth-hold",
  "issuing-auth-release",
  "issuing-decline",
  "issuing-refund",
  "issuing-transaction-adjustment",
  "issuing-transaction",
  "moov-fee",
  "payment",
  "payout",
  "refund-failure",
  "refund",
  "rtp-failure",
  "top-up",
  "wallet-transfer"
];

export const walletTransactionTypesSet = new Set<WalletTransactionType>(walletTransactionTypes);

export type WalletTransactionSourceType = "transfer" | "dispute" | "issuing-transaction";

export const walletTransactionSourceTypes: WalletTransactionSourceType[] = [
  "transfer",
  "dispute",
  "issuing-transaction"
];

export type WalletTransactionStatus = "pending" | "completed";

export interface WalletTransaction {
  walletID: string;
  transactionID: string;
  transactionType: WalletTransactionType;
  sourceType: WalletTransactionSourceType;
  sourceID: string;
  status: WalletTransactionStatus;
  memo: string;
  createdOn: string;
  completedOn?: string;
  currency: string;
  grossAmount: number;
  fee: number;
  netAmount: number;
  availableBalance: number;
}

export interface WalletTransactionsFilter extends PagingFilter {
  completedEndDateTime?: components["parameters"]["ListTransactionsQuery.completedEndDateTime"];
  completedStartDateTime?: components["parameters"]["ListTransactionsQuery.completedStartDateTime"];
  createdEndDateTime?: components["parameters"]["ListTransactionsQuery.createdEndDateTime"];
  createdStartDateTime?: components["parameters"]["ListTransactionsQuery.createdStartDateTime"];
  sourceID?: components["parameters"]["ListTransactionsQuery.sourceID"];
  sourceType?: components["parameters"]["ListTransactionsQuery.sourceType"];
  status?: components["parameters"]["ListTransactionsQuery.status"];
  sweepID?: components["parameters"]["ListTransactionsQuery.sweepID"];
  /**
   * @deprecated Use 'transactionTypes' instead, which supports both single and multiple transaction types
   */
  transactionType?: components["parameters"]["ListTransactionsQuery.transactionType"];
  transactionTypes?: components["parameters"]["ListTransactionsQuery.transactionTypes"];
}

export const defaultWalletTransactionsFilter: WalletTransactionsFilter = {
  count: 50,
  skip: 0
};
