import { FocusEvent, useContext, useEffect, useState } from "react";
import {
  DollarCurrencyAmountInput,
  FormGroup,
  Textarea
} from "@moovfinancial/cargo/src/components/Form";
import { components } from "@moovfinancial/common/types/__generated-types__/moov-api";
import useAccountLocking from "hooks/useAccountLocking";
import useAccountRequirements from "hooks/useAccountRequirements";
import { UnderwritingLockedBanner } from "../components/AccountLockedBanner";
import { Step } from "components/dialog/StepModal";
import { Label } from "components/form/Form";
import Toaster from "components/toaster/Toaster";
import { FacilitatorContext } from "contexts/FacilitatorContext";
import { AccountSetupContext } from "../../AccountSetupContext";
import useAccountModalCalls from "../../useAccountModalCalls";
import styles from "../../AccountSetup.module.scss";

type Capability = components["schemas"]["Capability"];

export const TransactionDetails = () => {
  const {
    underwriting,
    dispatch,
    account,
    errorMessages,
    isErrored,
    resetInput,
    capabilityRequirements,
    setInvalidInputs,
    invalidInputs,
    savedAccountData
  } = useContext(AccountSetupContext);

  const { facilitatorID } = useContext(FacilitatorContext);
  const { accountLocks, underwritingLocks, underwritingLocked } = useAccountLocking(
    savedAccountData.current,
    facilitatorID,
    // TODO: Updating all instances dependant on AccountSetupContext to use the new Capability type was a bit much, going to cast for now
    capabilityRequirements as Capability[]
  );
  const { accountRequirements } = useAccountRequirements(capabilityRequirements);
  const { submitTransactionDetails, loading } = useAccountModalCalls();

  const [descriptionIsDirty, setDescriptionIsDirty] = useState(false);

  useEffect(() => {
    if (invalidInputs.includes("description")) {
      setDescriptionIsDirty(false);
    }
  }, [invalidInputs]);

  const handleSubmit = () => {
    submitTransactionDetails();
  };

  const validateInput = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const hasValues =
      !!underwriting.averageMonthlyTransactionVolume ||
      !!underwriting.maxTransactionSize ||
      !!underwriting.averageTransactionSize;
    if (hasValues && e.target.value === "$0") {
      setInvalidInputs((prev) => [...prev, e.target.name]);
    }
    if (!hasValues) {
      setInvalidInputs((prev) => prev.filter((err) => err === "description"));
    }
  };

  const getWarningMessage = (name: string, isMissing: boolean) => {
    if (invalidInputs.includes(name)) {
      return "Please enter all transaction values";
    }
    if (isMissing) {
      return "This field is missing";
    }
  };

  const getErrorMessage = (name: string) => {
    if (isErrored(name)) {
      return "This field is invalid";
    }
  };

  const getDescriptionWarning = () => {
    if (accountRequirements.profile?.business?.description) {
      return "Description is missing";
    }
  };

  const getDescriptionError = () => {
    if (isErrored("description")) {
      return "Description is invalid";
    }
    if (invalidInputs.includes("description") && account.profile?.business?.description) {
      return account.profile?.business?.description?.length < 10 ||
        account.profile?.business?.description?.length > 100
        ? "Description must be between 10 and 100 characters"
        : "Description is invalid";
    }
  };

  return (
    <Step onSubmit={handleSubmit} loading={loading}>
      {underwritingLocked && <UnderwritingLockedBanner />}
      {errorMessages && <Toaster toastInput={errorMessages} />}
      <div>
        <FormGroup
          className={styles.formGroup}
          errorMessage={getErrorMessage("averageMonthlyTransactionVolume")}
          warningMessage={getWarningMessage(
            "averageMonthlyTransactionVolume",
            !!accountRequirements.underwriting.averageMonthlyTransactionVolume
          )}
        >
          <Label label="Average monthly volume ($)" />
          <DollarCurrencyAmountInput
            disabled={!!underwritingLocks.averageMonthlyTransactionVolume}
            error={isErrored("averageMonthlyTransactionVolume")}
            isLocked={!!underwritingLocks.averageMonthlyTransactionVolume}
            name="averageMonthlyTransactionVolume"
            value={underwriting.averageMonthlyTransactionVolume || 0}
            isWarning={
              invalidInputs.includes("averageMonthlyTransactionVolume") ||
              !!accountRequirements.underwriting.averageMonthlyTransactionVolume
            }
            onBlur={validateInput}
            onValueChange={(val) => {
              resetInput("averageMonthlyTransactionVolume");
              dispatch({
                type: "underwriting",
                value: { averageMonthlyTransactionVolume: val }
              });
            }}
          />
        </FormGroup>
        <FormGroup
          className={styles.formGroup}
          errorMessage={getErrorMessage("maxTransactionSize")}
          warningMessage={getWarningMessage(
            "maxTransactionSize",
            !!accountRequirements.underwriting.maxTransactionSize
          )}
        >
          <Label label="Maximum transaction amount ($)" />
          <DollarCurrencyAmountInput
            disabled={!!underwritingLocks.maxTransactionSize}
            error={isErrored("maxTransactionSize")}
            isLocked={!!underwritingLocks.maxTransactionSize}
            name="maxTransactionSize"
            value={underwriting.maxTransactionSize || 0}
            isWarning={
              invalidInputs.includes("maxTransactionSize") ||
              !!accountRequirements.underwriting.maxTransactionSize
            }
            onBlur={validateInput}
            onValueChange={(val) => {
              resetInput("maxTransactionSize");
              dispatch({
                type: "underwriting",
                value: { maxTransactionSize: val }
              });
            }}
          />
        </FormGroup>
        <FormGroup
          className={styles.formGroup}
          errorMessage={getErrorMessage("averageTransactionSize")}
          warningMessage={getWarningMessage(
            "averageTransactionSize",
            !!accountRequirements.underwriting.averageTransactionSize
          )}
        >
          <Label label="Average transaction amount ($)" />
          <DollarCurrencyAmountInput
            disabled={!!underwritingLocks.averageTransactionSize}
            error={isErrored("averageTransactionSize")}
            isLocked={!!underwritingLocks.averageTransactionSize}
            name="averageTransactionSize"
            value={underwriting.averageTransactionSize || 0}
            isWarning={
              invalidInputs.includes("averageTransactionSize") ||
              !!accountRequirements.underwriting.averageTransactionSize
            }
            onBlur={validateInput}
            onValueChange={(val) => {
              resetInput("averageTransactionSize");
              dispatch({
                type: "underwriting",
                value: {
                  averageTransactionSize: val
                }
              });
            }}
          />
        </FormGroup>
        <FormGroup
          className={styles.formGroup}
          errorMessage={getDescriptionError()}
          warningMessage={getDescriptionWarning()}
        >
          <Label label="Business description" />
          <Textarea
            disabled={!!accountLocks?.profile?.business?.description}
            isErroring={isErrored("description") || invalidInputs.includes("description")}
            isLocked={!!accountLocks?.profile?.business?.description}
            isWarning={
              (!!accountRequirements.profile?.business?.description || isErrored("description")) &&
              !descriptionIsDirty
            }
            maxLength={100}
            minLength={10}
            name="description"
            value={account.profile?.business?.description || ""}
            onBlur={(e: FocusEvent<HTMLTextAreaElement>) => {
              const { name, value } = e.target;
              if (value.length > 0 && (value.length < 10 || value.length > 100)) {
                setInvalidInputs((prev) => [...prev, name]);
              }
            }}
            onChange={(e) => {
              setDescriptionIsDirty(true);
              resetInput("description");
              dispatch({
                type: "business",
                value: { description: e.target.value }
              });
            }}
          />
        </FormGroup>
      </div>
    </Step>
  );
};
