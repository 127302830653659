import { useContext } from "react";
import { Button, Modal } from "@moovfinancial/cargo";
import { Text } from "cleanComponents/Typography/Text";
import { OnboardingContext } from "contexts/OnboardingContext";
import type { Representative } from "./AddOwner";
import styles from "./RemoveOwnerConfirmation.module.scss";

interface RemoveOwnerConfirmationProps {
  onCancel?: () => void;
  onRemove?: () => void;
  representative: Representative;
}

export const RemoveOwnerConfirmation = ({
  onCancel,
  onRemove,
  representative
}: RemoveOwnerConfirmationProps) => {
  const { removeRepresentative } = useContext(OnboardingContext);

  const onConfirm = () => {
    const remove = async () => {
      if (representative.representativeID) {
        await removeRepresentative(representative.representativeID);
      }
      onRemove?.();
    };
    void remove();
  };

  return (
    <Modal onClose={onCancel ? onCancel : () => {}} isOpen>
      <Modal.Header title="Delete owner profile" />
      <Modal.Body>
        <Text textStyle="paragraph-m-regular">
          Are you sure you want to delete the profile for{" "}
          <Text as="span" textStyle="body-m-semibold">
            {`${representative.name?.firstName} ${representative.name?.lastName}`}
          </Text>
          ? This action cannot be undone, and all data will be lost.
        </Text>
      </Modal.Body>
      <Modal.Footer className={styles.confirmationActions}>
        <Button onClick={onCancel} buttonType="secondary">
          Cancel
        </Button>
        <Button onClick={onConfirm} buttonType="primary">
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
