import { HttpResponse, http } from "msw";
import { CancellationStatus } from "api/v2";
import { generateMockCancellation } from "tests/mocks/data/cancellations.mock";

export const getPostCancellationHandler = (status: CancellationStatus) =>
  http.post("*/api/accounts/:accountID/transfers/:transferId/cancellations", () =>
    HttpResponse.json(generateMockCancellation({ status }))
  );

export const cancellationHandlers = [getPostCancellationHandler("pending")];
