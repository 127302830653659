import { useContext } from "react";
import { clone } from "remeda";
import { components } from "@moovfinancial/common/types/__generated-types__/moov-api";
import { type SparsePath, setSparsePath } from "@moovfinancial/common/utils/setSparsePath";
import { BooleanAccountUnderwritingMap, Capability } from "api/v2";
import { BooleanAccountMap } from "api/v2/accounts.model";
import { CapabilitiesContext } from "contexts/CapabilitiesContext";
import { requirementToAccountFieldMap } from "helpers/capabilities";

// Note: RequirementID is a shared type between requirement errors and currently due values
type RequirementID = components["schemas"]["RequirementID"];

/** Returns the current requirements for an Account, based on requested capabilities.
 * Requirements follow the Account object structure. Currently due or errored fields are set to `true`. */
type AccountRequirements = BooleanAccountMap & {
  underwriting: BooleanAccountUnderwritingMap;
};

function useAccountRequirements(capabilitiesProp?: Capability[]): {
  accountRequirements: AccountRequirements;
} {
  const { capabilities: capabilitiesFromContext } = useContext(CapabilitiesContext);
  const capabilities = capabilitiesProp || capabilitiesFromContext;
  let accountRequirements = defaultAccountRequirements;
  if (!capabilities?.length) return { accountRequirements };
  accountRequirements = requireCurrentlyDueFields(accountRequirements, capabilities);
  accountRequirements = requireErroredFields(accountRequirements, capabilities);
  return { accountRequirements };
}

const formattedBirthDateRequirements = {
  day: true,
  month: true,
  year: true
};

function requireErroredFields(
  accountReqs: AccountRequirements,
  capabilities: Capability[]
): AccountRequirements {
  const newAccountReqs: AccountRequirements = clone(accountReqs);
  const accountErrors: string[] = [];
  capabilities.forEach((capability) => {
    capability.requirements.errors?.forEach((error) => {
      const errorPath = requirementToAccountFieldMap[error.requirement as RequirementID];
      if (errorPath) accountErrors.push(errorPath);
    });
  });
  accountErrors.forEach((errorPath) => {
    if (errorPath === "profile.individual.birthDate") {
      return setSparsePath(
        newAccountReqs,
        ["profile", "individual", "birthDate"] as const,
        formattedBirthDateRequirements
      );
    }
    setSparsePath(newAccountReqs, errorPath.split(".") as SparsePath<AccountRequirements>, true);
  });
  return newAccountReqs;
}

function requireCurrentlyDueFields(
  accountReqs: AccountRequirements,
  capabilities: Capability[]
): AccountRequirements {
  const newAccountReqs: AccountRequirements = clone(accountReqs);
  const accountErrors: string[] = [];
  capabilities.forEach((capability) => {
    capability.requirements.currentlyDue?.forEach((due) => {
      const errorPath = due && requirementToAccountFieldMap[due as RequirementID];
      if (errorPath) accountErrors.push(errorPath);
    });
  });
  accountErrors.forEach((errorPath) => {
    if (errorPath === "profile.individual.birthDate") {
      return setSparsePath(
        newAccountReqs,
        ["profile", "individual", "birthDate"] as const,
        formattedBirthDateRequirements
      );
    }
    setSparsePath(newAccountReqs, errorPath.split(".") as SparsePath<AccountRequirements>, true);
  });
  return newAccountReqs;
}

const defaultAccountRequirements: AccountRequirements = {
  accountID: false,
  accountType: false,
  displayName: false,
  profile: {
    individual: {
      name: {
        firstName: false,
        middleName: false,
        lastName: false,
        suffix: false
      },
      phone: {
        number: false
      },
      email: false,
      address: {
        addressLine1: false,
        addressLine2: false,
        city: false,
        stateOrProvince: false,
        postalCode: false,
        country: false
      },
      birthDate: {
        day: false,
        month: false,
        year: false
      },
      governmentID: {
        ssn: {
          lastFour: false,
          full: false
        },
        itin: {
          lastFour: false,
          full: false
        }
      }
    },
    business: {
      legalBusinessName: false,
      doingBusinessAs: false,
      website: false,
      description: false,
      businessType: false,
      taxID: {
        ein: {
          number: false
        }
      },
      address: {
        addressLine1: false,
        addressLine2: false,
        city: false,
        stateOrProvince: false,
        postalCode: false,
        country: false
      },
      phone: {
        number: false
      },
      email: false,
      representatives: [],
      industryCodes: {
        naics: false,
        sic: false,
        mcc: false
      }
    }
  },
  metadata: {},
  termsOfService: {
    token: false,
    acceptedDate: false,
    acceptedIP: false
  },
  foreignID: false,
  underwriting: {
    averageTransactionSize: false,
    maxTransactionSize: false,
    averageMonthlyTransactionVolume: false
  }
};

export default useAccountRequirements;
