import { Chance } from "chance";
import { DecryptedRepresentativeData, Representative } from "api/v2";
import { generateMockBirhtDate, generateMockGovernmentID } from "./accounts.mock";

const chance = new Chance();

export const generateMockRepresentative = (
  options: {
    governmentIDProvided?: boolean;
    birthDateProvided?: boolean;
    isOwner?: boolean;
    isController?: boolean;
  } = {}
): Representative => {
  const { governmentIDProvided, birthDateProvided, isController, isOwner } = options;

  return {
    representativeID: chance.guid(),
    name: {
      firstName: chance.first(),
      middleName: chance.first(),
      lastName: chance.last(),
      suffix: chance.suffix()
    },
    phone: {
      countryCode: chance.string({ alpha: false, numeric: true, length: 2 }),
      number: chance.phone({ formatted: false })
    },
    email: chance.email(),
    address: {
      addressLine1: chance.address(),
      addressLine2: `#${chance.integer()}`,
      city: chance.city(),
      stateOrProvince: chance.state(),
      postalCode: chance.zip(),
      country: chance.country()
    },
    birthDateProvided: birthDateProvided ?? chance.bool(),
    governmentIDProvided: governmentIDProvided ?? chance.bool(),
    responsibilities: {
      isController: isController ?? chance.bool(),
      isOwner: isOwner ?? chance.bool(),
      ownershipPercentage: isOwner || chance.bool() ? chance.natural({ min: 25, max: 100 }) : 0,
      jobTitle: isController || chance.bool() ? chance.profession() : ""
    },
    createdOn: chance.date().toISOString(),
    updatedOn: chance.date().toISOString()
  };
};

export const generateDecryptedRepresentative = (): DecryptedRepresentativeData => ({
  governmentID: generateMockGovernmentID(),
  birthDate: generateMockBirhtDate()
});

export const mockRepresentative: Representative = generateMockRepresentative();
