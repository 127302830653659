import React from "react";
import { FormGroup, Select } from "@moovfinancial/cargo";
import { StatesAndTerritories } from "@moovfinancial/common/constants/state";
import { Label } from "components/form/Form";

interface StateSelectProps extends React.AllHTMLAttributes<HTMLSelectElement> {
  value?: string;
  isLocked?: boolean;
  warn?: boolean;
}

export default function StateSelect({
  className,
  onChange,
  value,
  isLocked,
  name,
  ...rest
}: StateSelectProps) {
  return (
    <FormGroup>
      <Label htmlFor={name} label="State" />
      <Select
        name={name}
        value={value}
        placeholder="Select"
        autoComplete="off"
        className={className}
        isLocked={isLocked}
        onChange={onChange}
        {...rest}
      >
        <StatesAndTerritories />
      </Select>
    </FormGroup>
  );
}
