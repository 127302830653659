import { components } from "@moovfinancial/common/types/__generated-types__/moov-api";

type Card = components["schemas"]["Card"];

/**
 * @deprecated Should use dynamic mocks in the future;
 */
export const mockCard: Card = {
  issuer: "VISA",
  issuerCountry: "US",
  issuerURL: "https://www.visa.com",
  issuerPhone: "+18001234567",
  domesticPushToCard: "standard",
  domesticPullFromCard: "supported",
  cardID: "ec7e1848-dc80-4ab0-827-dd7fc0737b43",
  fingerprint: "9948962d92a1ce40c9f918cd9ece3a22bde62fb325a2f1fe2e833969de672ba3",
  brand: "Visa",
  cardType: "debit",
  lastFourCardNumber: "1234",
  bin: "411111",
  expiration: {
    month: "01",
    year: "21"
  },
  holderName: "Jules Jackson",
  billingAddress: {
    addressLine1: "123 Main Street",
    addressLine2: "Apt 302",
    city: "Boulder",
    stateOrProvince: "CO",
    postalCode: "80301",
    country: "US"
  },
  cardVerification: {
    cvv: "match",
    addressLine1: "match",
    postalCode: "match",
    accountName: {
      firstName: "match",
      lastName: "match",
      middleName: "match",
      fullName: "match"
    }
  }
};

/**
 * @deprecated Should use dynamic mocks in the future;
 */
export const mockCards: Card[] = [
  {
    issuer: "VISA",
    issuerCountry: "US",
    issuerURL: "https://www.visa.com",
    issuerPhone: "+18001234567",
    domesticPushToCard: "standard",
    domesticPullFromCard: "supported",
    cardID: "ec7e1848-dc80-4ab0-827-dd7fc0737b43",
    fingerprint: "9948962d92a1ce40c9f918cd9ece3a22bde62fb325a2f1fe2e833969de672ba3",
    brand: "Visa",
    cardType: "debit",
    lastFourCardNumber: "1234",
    bin: "411111",
    expiration: {
      month: "01",
      year: "21"
    },
    holderName: "Jules Jackson",
    billingAddress: {
      addressLine1: "123 Main Street",
      addressLine2: "Apt 302",
      city: "Boulder",
      stateOrProvince: "CO",
      postalCode: "80301",
      country: "US"
    },
    cardVerification: {
      cvv: "match",
      addressLine1: "match",
      postalCode: "match",
      accountName: {
        firstName: "match",
        lastName: "match",
        middleName: "match",
        fullName: "match"
      }
    }
  },
  {
    domesticPullFromCard: "supported",
    domesticPushToCard: "standard",
    issuer: "AMEX",
    issuerCountry: "US",
    issuerURL: "https://www.americanexpress.com",
    issuerPhone: "+18001234567",
    cardID: "ec7e48-dc80-4ab0-8827-dd7fc0737b43",
    fingerprint: "9948962d92a1ce40c9f918cd9ece3a22bde62fb325a2f1fe2e833969de672ba3",
    brand: "American Express",
    cardType: "credit",
    lastFourCardNumber: "1234",
    bin: "123456",
    expiration: {
      month: "01",
      year: "21"
    },
    holderName: "Jules Jackson",
    billingAddress: {
      addressLine1: "123 Main Street",
      addressLine2: "Apt 302",
      city: "Boulder",
      stateOrProvince: "CO",
      postalCode: "80301",
      country: "US"
    },
    cardVerification: {
      cvv: "match",
      addressLine1: "match",
      postalCode: "match",
      accountName: {
        firstName: "match",
        lastName: "match",
        middleName: "match",
        fullName: "match"
      }
    }
  },
  {
    domesticPullFromCard: "supported",
    domesticPushToCard: "standard",
    issuer: "MASTERCARD",
    issuerCountry: "US",
    issuerURL: "https://www.mastercard.com",
    issuerPhone: "+18001234567",
    cardID: "ec7e1848-dc80-4ab0-8827-7fc0737b43",
    fingerprint: "9948962d92a1ce40c9f918cd9ece3a22bde62fb325a2f1fe2e833969de672ba3",
    brand: "Mastercard",
    cardType: "debit",
    lastFourCardNumber: "1234",
    bin: "123456",
    expiration: {
      month: "01",
      year: "21"
    },
    holderName: "Jules Jackson",
    billingAddress: {
      addressLine1: "123 Main Street",
      addressLine2: "Apt 302",
      city: "Boulder",
      stateOrProvince: "CO",
      postalCode: "80301",
      country: "US"
    },
    cardVerification: {
      cvv: "match",
      addressLine1: "match",
      postalCode: "match",
      accountName: {
        firstName: "match",
        lastName: "match",
        middleName: "match",
        fullName: "match"
      }
    }
  }
];
