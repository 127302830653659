import { Chance } from "chance";
import { toast } from "react-toastify";
import type { DeepPartial } from "@moovfinancial/common/types/DeepTypes";
import { generateGUID } from "@moovfinancial/common/utils/generateGuid";
import { generateNames } from "@moovfinancial/common/utils/randomData";
import * as dashboardAPI from "api/Dashboard";
import { FacilitatorAccounts } from "api/Dashboard.model";
import TransfersAPI from "api/Transfers";
import { User } from "api/User.model";
import { http } from "api/http";
import {
  AccountUnderwriting,
  BankAccount,
  BankAccountHolderType,
  CapabilityName,
  Card,
  MoovAPIClient
} from "api/v2";
import { Account, BusinessType, CreateAccount, Representative } from "api/v2/accounts.model";
import { Transfer, TransferOptions } from "api/v2/transfers.model";
import { retryRequest } from "helpers/retryRequest";

const connectedAccountCapabilities: CapabilityName[] = ["transfers", "wallet", "send-funds"];

const chance = new Chance();

export async function createFacilitatorAccounts(
  moov: MoovAPIClient,
  user: User,
  businessType: BusinessType,
  legalBusinessName: string,
  description: string
): Promise<FacilitatorAccounts> {
  let facilitatorAccounts: FacilitatorAccounts;
  try {
    const [result, error] = await moov.accounts.getTOSToken();
    if (result?.token) {
      /* eslint-disable @typescript-eslint/no-unsafe-assignment */
      /* eslint-disable @typescript-eslint/no-unsafe-member-access */
      facilitatorAccounts = await dashboardAPI.createAccounts({
        userID: user.userID,
        email: user.email ?? "",
        firstName: user.givenName ?? "",
        lastName: user.familyName ?? "",
        businessType,
        legalBusinessName,
        description,
        termsOfServiceToken: result.token
      });
      /* eslint-enable @typescript-eslint/no-unsafe-assignment */
      /* eslint-enable @typescript-eslint/no-unsafe-member-access */
    } else {
      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw error;
    }
  } catch (err) {
    toast("Failed to create facilitator accounts");
    throw err;
  }

  // TODO: Move everything below to the DashboardBFF service

  try {
    const data = await generateSandboxData(moov);

    await retryRequest(() =>
      moov.accounts.representatives.create(
        facilitatorAccounts.sandbox.accountID,
        facilitatorAccounts.sandbox.accountID,
        data.rep as Representative
      )
    );
    facilitatorAccounts.sandbox = await retryRequest(async () => {
      const [result, error] = await moov.accounts.patch(
        facilitatorAccounts.sandbox.accountID,
        facilitatorAccounts.sandbox.accountID,
        { profile: { business: { ownersProvided: true } } }
      );
      // eslint-disable-next-line
      if (error || !result) throw error;
      return result;
    });

    await addSandboxData(moov, data, facilitatorAccounts.sandbox);
  } catch (_err) {
    toast("Failed to add sandbox data to sandbox account");
    // Not fatal, continue
  }

  return facilitatorAccounts;
}

/** Returns an object with fake business, individual, and representative data */
export async function generateSandboxData(moov: MoovAPIClient): Promise<SandboxDataObject> {
  const [result] = await moov.accounts.getTOSToken();
  const token = result?.token;
  const { name, businessName, repName } = generateNames();
  return {
    business: {
      accountType: "business",
      profile: {
        business: {
          businessType: "llc",
          ownersProvided: true,
          legalBusinessName: businessName,
          email: `${repName.firstName.toLowerCase()}.${repName.lastName.toLowerCase()}@example.com`,
          website: "https://example.com",
          description: `Test account for ${businessName}`,
          address: {
            addressLine1: "123 Money Street",
            city: "Mooville",
            stateOrProvince: "IA",
            country: "US",
            postalCode: "74145"
          },
          phone: { number: "555-123-4567", countryCode: "1" },
          taxID: {
            ein: {
              number: "12-3456789"
            }
          },
          industryCodes: {
            mcc: "7623",
            naics: "811412",
            sic: "7623"
          }
        }
      },
      termsOfService: {
        token
      }
    },
    individual: {
      accountType: "individual",
      profile: {
        individual: {
          email: `${name.firstName.toLowerCase()}.${name.lastName.toLowerCase()}@example.com`,
          name,
          address: {
            addressLine1: "123 Money Street",
            city: "Mooville",
            stateOrProvince: "IA",
            country: "US",
            postalCode: "74145"
          },
          phone: { number: "555-123-4567", countryCode: "1" },
          governmentID: {
            ssn: {
              full: "123456789"
            }
          },
          birthDate: {
            day: 1,
            month: 1,
            year: 1984
          }
        }
      },
      termsOfService: {
        token
      }
    },
    underwriting: {
      averageTransactionSize: 100,
      maxTransactionSize: 10000,
      averageMonthlyTransactionVolume: 100000
    },
    rep: {
      name: repName,
      phone: { number: "555-123-4567", countryCode: "1" },
      email: `${repName.firstName.toLowerCase()}${repName.lastName.toLowerCase()}@example.com`,
      governmentID: {
        ssn: {
          full: "123-45-6789"
        }
      },
      address: {
        addressLine1: "123 Money Street",
        city: "Mooville",
        stateOrProvince: "IA",
        country: "US",
        postalCode: "74145"
      },
      responsibilities: {
        isController: true,
        isOwner: true,
        ownershipPercentage: 26,
        jobTitle: "President"
      },
      birthDate: {
        day: 1,
        month: 1,
        year: 1984
      }
    }
  };
}

export const sandboxPrefilledRep = () => ({
  name: {
    firstName: chance.first(),
    lastName: chance.last()
  },
  phone: { number: chance.phone({ formatted: false }), countryCode: "1" },
  email: chance.email(),
  governmentID: {
    ssn: {
      full: "123456789"
    }
  },
  address: {
    addressLine1: chance.address(),
    city: chance.city(),
    stateOrProvince: chance.state(),
    country: "US",
    postalCode: chance.zip()
  },
  responsibilities: {
    isController: true,
    isOwner: true,
    ownershipPercentage: 26,
    jobTitle: "President"
  },
  birthDate: {
    day: chance.integer({ min: 1, max: 28 }),
    month: chance.integer({ min: 1, max: 12 }),
    year: chance.integer({ min: 1950, max: 1999 })
  }
});

/** Populate a sandbox facilitator with fake connected accounts and transfers */
async function addSandboxData(
  moov: MoovAPIClient,
  data: SandboxDataObject,
  facilitatorAccount: Account
) {
  const businessRequest: Promise<Account> = retryRequest(async () => {
    const [result, error] = await moov.accounts.create(facilitatorAccount.accountID, data.business);
    // eslint-disable-next-line @typescript-eslint/only-throw-error
    if (error || !result) throw error;
    return result;
  });
  const individualRequest: Promise<Account> = retryRequest(async () => {
    const [result, error] = await moov.accounts.create(
      facilitatorAccount.accountID,
      data.individual
    );
    // eslint-disable-next-line @typescript-eslint/only-throw-error
    if (error || !result) throw error;
    return result;
  });

  const [business, individual] = await Promise.all([businessRequest, individualRequest]);

  const businessUnderwritingRequest = retryRequest(() =>
    http(`/accounts/${business.accountID}/underwriting`, {
      method: "PUT",
      json: data.underwriting,
      xAccountID: facilitatorAccount.accountID
    })
  );

  const facilitatorUnderwritingRequest = retryRequest(() =>
    http(`/accounts/${facilitatorAccount.accountID}/underwriting`, {
      method: "PUT",
      json: data.underwriting,
      xAccountID: facilitatorAccount.accountID
    })
  );

  const repRequest = retryRequest(() =>
    moov.accounts.representatives.create(
      business.accountID,
      facilitatorAccount.accountID,
      data.rep as Representative
    )
  );

  await Promise.all([facilitatorUnderwritingRequest, businessUnderwritingRequest, repRequest]);

  await moov.accounts.patch(facilitatorAccount.accountID, business.accountID, {
    profile: { business: { ownersProvided: true } }
  });

  const busCapabilities = retryRequest(() =>
    moov.capabilities.request(facilitatorAccount.accountID, business.accountID, [
      ...connectedAccountCapabilities,
      "collect-funds"
    ])
  );
  const indCapabilities = retryRequest(() =>
    moov.capabilities.request(
      facilitatorAccount.accountID,
      individual.accountID,
      connectedAccountCapabilities
    )
  );

  const bankAccounts = createBankAccounts(moov, [business, individual], facilitatorAccount);
  const cards = createCards(moov, [individual], facilitatorAccount);

  await Promise.all([busCapabilities, indCapabilities, bankAccounts, cards]);

  await createTestTransfers(business.accountID, individual.accountID, facilitatorAccount.accountID);
}

export interface SandboxDataObject {
  business: DeepPartial<CreateAccount>;
  individual: DeepPartial<CreateAccount>;
  underwriting: AccountUnderwriting;
  rep: DeepPartial<Representative>;
}

export function createCards(
  moov: MoovAPIClient,
  accounts: Account[],
  facilitatorAccount: Account
): Promise<Card[]> {
  return Promise.all(
    accounts.map((account) => {
      return retryRequest(() => {
        return moov.paymentMethods.cards
          .create(facilitatorAccount.accountID, account.accountID, {
            cardCvv: "999",
            cardNumber: "4111111111111111",
            holderName: account.displayName,
            billingAddress: (account.profile.business?.address ||
              account.profile.individual?.address)!,
            expiration: {
              month: "01",
              year: "29"
            }
          })
          .then(([card, err]) => {
            if (err) throw new Error(err.type);
            return card;
          });
      });
    })
  ) as Promise<Card[]>;
}

export async function createBankAccounts(
  moov: MoovAPIClient,
  accounts: Account[],
  facilitatorAccount: Account
): Promise<BankAccount[]> {
  return Promise.all(
    [...accounts, facilitatorAccount].map(async (account) => {
      return retryRequest(() =>
        moov.paymentMethods.bankAccounts.create(facilitatorAccount.accountID, account.accountID, {
          accountNumber: Math.floor(Math.random() * Math.pow(10, 9)).toString(),
          routingNumber: "273976369",
          bankAccountType: "checking",
          holderName: account.displayName,
          holderType: account.accountType as BankAccountHolderType
        })
      )
        .then(([bankAcct, error]) => {
          // eslint-disable-next-line @typescript-eslint/only-throw-error
          if (error || !bankAcct) throw error;
          return retryRequest(() =>
            moov.paymentMethods.bankAccounts
              .initiateMicroDeposits(
                facilitatorAccount.accountID,
                account.accountID,
                bankAcct?.bankAccountID || ""
              )
              .then(() => bankAcct)
          );
        })
        .then(async (bankAcct) => {
          await retryRequest(() =>
            moov.paymentMethods.bankAccounts.completeMicroDeposits(
              facilitatorAccount.accountID,
              account.accountID,
              bankAcct?.bankAccountID || "",
              [0, 0]
            )
          );
          return bankAcct;
        });
    })
  );
}

export function createTestTransfers(
  businessID: string,
  individualID: string,
  facilitatorAccountID: string
): Promise<(Transfer | undefined)[]> {
  const transfer1 = createTestTransferHandler(
    businessID,
    individualID,
    facilitatorAccountID,
    "ach-debit-fund",
    "ach-credit-same-day"
  );

  const transfer2 = createTestTransferHandler(
    individualID,
    businessID,
    facilitatorAccountID,
    "ach-debit-collect",
    "ach-credit-same-day"
  );

  const transfer3 = createTestTransferHandler(
    facilitatorAccountID,
    individualID,
    facilitatorAccountID,
    "ach-debit-fund",
    "moov-wallet"
  );

  return Promise.all([transfer1, transfer2, transfer3]);
}

function createTestTransferHandler(
  sourceID: string,
  destinationID: string,
  facilitatorAccountID: string,
  sourceMethod: string,
  destinationMethod: string
) {
  return getTransferOptions(
    sourceID,
    destinationID,
    facilitatorAccountID,
    sourceMethod,
    destinationMethod
  ).then((transferOptions) => {
    if (transferOptions.sourceOptions && transferOptions.destinationOptions) {
      return createTransfer(
        transferOptions.sourceOptions[0].paymentMethodID,
        transferOptions.destinationOptions[0].paymentMethodID,
        facilitatorAccountID
      );
    }
  });
}

function createTransfer(
  sourceID: string,
  destinationID: string,
  facilitatorAccountID: string
): Promise<Transfer> {
  return TransfersAPI.create({
    accountID: facilitatorAccountID,
    transfer: {
      amount: {
        value: Math.floor(Math.random() * Math.pow(10, 6)),
        currency: "USD"
      },
      source: {
        paymentMethodID: sourceID
      },
      destination: {
        paymentMethodID: destinationID
      },
      description: "Sandbox test transfer"
    },
    idempotencyKey: generateGUID()
  });
}

function getTransferOptions(
  sourceID: string,
  destinationID: string,
  facilitatorAccountID: string,
  sourceMethod: string,
  destinationMethod: string
): Promise<TransferOptions> {
  return retryRequest(() =>
    TransfersAPI.options({
      accountID: facilitatorAccountID,
      transfer: {
        amount: {
          value: 999999,
          currency: "USD"
        },
        source: {
          accountID: sourceID
        },
        destination: {
          accountID: destinationID
        }
      }
    }).then((resp) => {
      if (resp.sourceOptions && resp.destinationOptions) {
        const source = resp.sourceOptions.find(
          (option) => option.paymentMethodType === sourceMethod
        );
        const destination = resp.destinationOptions.find(
          (option) => option.paymentMethodType === destinationMethod
        );
        if (source && destination) {
          return {
            ...resp,
            sourceOptions: [source],
            destinationOptions: [destination]
          };
        }
      }
      // Throwing an error to trigger a retry if payment methods are not available yet
      throw new Error("No available transfer options");
    })
  );
}
