import { useContext, useEffect } from "react";
import { Outlet } from "react-router";
import { Loading } from "@moovfinancial/cargo";
import { SessionContext } from "contexts/SessionContext";
import SessionReminders from "layout/SessionReminders";

export const NeedsSession = () => {
  const { fetchSessionOrRedirectToSignin, session } = useContext(SessionContext);

  useEffect(() => {
    const checkSession = async () => await fetchSessionOrRedirectToSignin();
    if (!session) void checkSession();
  }, [session]);

  return session ? (
    <>
      <Outlet />
      {/* Any time we have a session, we need session reminders */}
      <SessionReminders />
    </>
  ) : (
    // TODO: When React 18+ specs are more solidified, we should be able to use `use`
    // (https://react.dev/reference/react/use) // to signal to `Suspense` that we're stuff loading
    //and should show the loading spinner
    <Loading centered />
  );
};
