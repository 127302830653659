import { FormEventHandler, useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLinkClickHandler, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { FormGroup } from "@moovfinancial/cargo";
import type { DeepPartial } from "@moovfinancial/common/types/DeepTypes";
import { RadioGroup, RadioInput } from "components/form/RadioInput";
import { OnboardingContext, type Underwriting } from "contexts/OnboardingContext";
import { OnboardingStepsContext } from "contexts/OnboardingStepsContext";
import { FooterButtons } from "../FooterButtons";
import { OnboardingErrors, handleError, parseErrors } from "../helpers/errors";
import styles from "./Fulfillment.module.scss";

type ReturnPolicy = NonNullable<Underwriting["fulfillment"]>["returnPolicy"];

export interface FulfillmentFormState {
  hasPhysicalGoods?: boolean;
  isShippingProduct?: boolean;
  returnPolicy?: ReturnPolicy;
  shipmentDurationDays?: number;
}

export const mapUnderwritingToFormState = (
  underwriting: DeepPartial<Underwriting> | undefined
): FulfillmentFormState => ({
  hasPhysicalGoods: underwriting?.fulfillment?.hasPhysicalGoods,
  isShippingProduct: underwriting?.fulfillment?.isShippingProduct,
  returnPolicy: underwriting?.fulfillment?.returnPolicy,
  shipmentDurationDays: underwriting?.fulfillment?.shipmentDurationDays
});

const mapFormStateToUnderwriting = (
  underwriting: DeepPartial<Underwriting>,
  formState: FulfillmentFormState
): DeepPartial<Underwriting> => ({
  ...underwriting,
  fulfillment: {
    hasPhysicalGoods: formState.hasPhysicalGoods,
    isShippingProduct: formState.isShippingProduct,
    returnPolicy: formState.returnPolicy,
    shipmentDurationDays: formState.shipmentDurationDays
  }
});

export function Fulfillment() {
  const formRef = useRef<HTMLFormElement>(null);
  const { underwriting, putUnderwriting } = useContext(OnboardingContext);
  const navigate = useNavigate();
  const { getNextStepUrl, getPreviousStepUrl, fulfillmentStep } =
    useContext(OnboardingStepsContext);
  const { formState, setFormState } = fulfillmentStep;
  const handleBackClick = useLinkClickHandler<HTMLButtonElement>(getPreviousStepUrl());

  const [errors, setErrors] = useState<OnboardingErrors<Underwriting>>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // On unmount, set the form state in OnboardingStepsContext to the mapped underwriting object as it's the source of truth
    return () => {
      setFormState(mapUnderwritingToFormState(underwriting));
    };
  }, [underwriting, setFormState]);

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const { data, error } = await putUnderwriting(
      mapFormStateToUnderwriting(underwriting, formState) as Underwriting
    );
    setIsLoading(false);

    if (error) {
      // @ts-expect-error - 🤔 not sure what's going on here, but I rather not break it in the PR
      setErrors(parseErrors<Underwriting>(error));
      toast("Unable to update account. Please try again.");
      return;
    }

    if (data) {
      void navigate(getNextStepUrl(), {
        state: { skipUnsavedChangesCheck: true }
      });
    }
  };

  return (
    <div className={styles.content}>
      <Helmet>
        <title>Fulfillment</title>
      </Helmet>
      <h2 className={styles.header}>Fulfillment</h2>
      <div className={styles.row}>
        <p className={styles.pageInfoText}>
          Tell us how your customers get their goods or service.
        </p>
      </div>
      <form onSubmit={handleSubmit} ref={formRef}>
        <FormGroup errorMessage={handleError(errors.fulfillment?.hasPhysicalGoods)} noGap={false}>
          <label className={styles.fieldGroupHeading}>Do you sell physical goods?</label>
          <RadioGroup
            className={styles.input}
            initialSelectedValue={formState.hasPhysicalGoods ? "true" : "false"}
            name="hasPhysicalGoods"
            onSelect={(value: string) =>
              setFormState((prev) => ({ ...prev, hasPhysicalGoods: value === "true" }))
            }
          >
            <RadioInput className={styles.radioInput} value="true">
              <span className={styles.label}>Yes</span>
            </RadioInput>
            <RadioInput className={styles.radioInput} value="false">
              <span className={styles.label}>No</span>
            </RadioInput>
          </RadioGroup>
        </FormGroup>
        {formState.hasPhysicalGoods && (
          <>
            <FormGroup
              errorMessage={handleError(errors.fulfillment?.isShippingProduct)}
              noGap={false}
            >
              <label className={styles.fieldGroupHeading}>Do you ship the product yourself?</label>
              <RadioGroup
                className={styles.input}
                initialSelectedValue={formState.isShippingProduct ? "true" : "false"}
                name="isShippingProduct"
                onSelect={(value: string) =>
                  setFormState((prev) => ({ ...prev, isShippingProduct: value === "true" }))
                }
              >
                <RadioInput className={styles.radioInput} value="true">
                  <span className={styles.label}>Yes</span>
                </RadioInput>
                <RadioInput className={styles.radioInput} value="false">
                  <span className={styles.label}>No</span>
                </RadioInput>
              </RadioGroup>
            </FormGroup>
            <FormGroup errorMessage={handleError(errors.fulfillment?.returnPolicy)} noGap={false}>
              <label className={styles.fieldGroupHeading}>What is your return policy?</label>
              <RadioGroup
                className={styles.input}
                initialSelectedValue={formState.returnPolicy}
                name="returnPolicy"
                onSelect={(value: string) =>
                  setFormState((prev) => ({
                    ...prev,
                    returnPolicy: value as ReturnPolicy
                  }))
                }
              >
                <RadioInput className={styles.radioInput} value="none">
                  <span className={styles.label}>None</span>
                </RadioInput>
                <RadioInput className={styles.radioInput} value="exchangeOnly">
                  <span className={styles.label}>Exchange only</span>
                </RadioInput>
                <RadioInput className={styles.radioInput} value="withinThirtyDays">
                  <span className={styles.label}>Within 30 days</span>
                </RadioInput>
              </RadioGroup>
            </FormGroup>
          </>
        )}
        <FormGroup
          errorMessage={handleError(errors.fulfillment?.shipmentDurationDays)}
          noGap={false}
        >
          <label className={styles.fieldGroupHeading}>
            How long after payment do customers receive their goods/services?
          </label>
          <RadioGroup
            className={styles.input}
            initialSelectedValue={`${formState.shipmentDurationDays}`}
            name="shipmentDurationDays"
            onSelect={(value: string) =>
              setFormState((prev) => ({ ...prev, shipmentDurationDays: Number(value) }))
            }
          >
            <RadioInput className={styles.radioInput} value="1">
              <span className={styles.label}>Within a day</span>
            </RadioInput>
            <RadioInput className={styles.radioInput} value="14">
              <span className={styles.label}>Within 2 weeks</span>
            </RadioInput>
            <RadioInput className={styles.radioInput} value="30">
              <span className={styles.label}>Within a month</span>
            </RadioInput>
            <RadioInput className={styles.radioInput} value="31">
              <span className={styles.label}>More than a month</span>
            </RadioInput>
          </RadioGroup>
        </FormGroup>
        <FooterButtons onBack={handleBackClick} onContinue={handleSubmit} isLoading={isLoading} />
      </form>
    </div>
  );
}
