import { produce } from "immer";
import { components } from "@moovfinancial/common/types/__generated-types__/moov-api";
import { generateGUID } from "@moovfinancial/common/utils/generateGuid";
import { Account } from "api/v2/accounts.model";

type PaymentMethod = components["schemas"]["PaymentMethod"];
type PaymentMethodType = components["schemas"]["PaymentMethodType"];
type Wallet = components["schemas"]["Wallet"];

type WalletType =
  | PaymentMethodType
  | "ach-credit-same-day"
  | "ach-credit-standard"
  | "ach-debit-fund"
  | "pull-from-card"
  | "rtp-credit"
  | "";

export interface TransferState {
  actions: typeof actions;
  activeAccount: Account | null;
  amount: number;
  description?: string;
  destinationAccount: Account | null;
  destinationPaymentMethod: PaymentMethod | null;
  destinationPaymentMethods: PaymentMethod[];
  detailType: string;
  error: string | null;
  idempotencyKey: string;
  sourceAccount: Account | null;
  sourcePaymentMethod: PaymentMethod | null;
  sourcePaymentMethods: PaymentMethod[];
  step: number;
  title: string;
  transferType: "payout" | "collection" | "transfer" | "";
  wallet?: Wallet;
  walletType: WalletType;
}

export type TransferAction =
  | { type: "stepforward" | "stepback" | "updateIdempotencyKey" }
  | { type: "setStep"; value: number }
  | { type: "amount"; value: number }
  | { type: "transferType"; value: "payout" | "collection" | "transfer" }
  | { type: "detailType" | "title" | "description" | "error"; value: string }
  | { type: "sourceAccount" | "destinationAccount" | "activeAccount"; value: Account | null }
  | { type: "sourcePaymentMethods" | "destinationPaymentMethods"; value: PaymentMethod[] }
  | { type: "sourcePaymentMethod" | "destinationPaymentMethod"; value: PaymentMethod | null }
  | { type: "wallet"; value: Wallet }
  | { type: "walletType"; value: WalletType };

export const actions = {
  stepforward: (): TransferAction => ({ type: "stepforward" }),
  stepback: (): TransferAction => ({ type: "stepback" }),
  setStep: (step: number): TransferAction => ({ type: "setStep", value: step }),
  amount: (amount: number): TransferAction => ({
    type: "amount",
    value: amount
  }),
  transferType: (transferType: "payout" | "collection" | "transfer"): TransferAction => ({
    type: "transferType",
    value: transferType
  }),
  detailType: (detailType: string): TransferAction => ({
    type: "detailType",
    value: detailType
  }),
  title: (title: string): TransferAction => ({
    type: "title",
    value: title
  }),
  sourceAccount: (sourceAccount: Account | null): TransferAction => ({
    type: "sourceAccount",
    value: sourceAccount
  }),
  destinationAccount: (destinationAccount: Account | null): TransferAction => ({
    type: "destinationAccount",
    value: destinationAccount
  }),
  sourcePaymentMethods: (sourcePaymentMethods: PaymentMethod[]): TransferAction => ({
    type: "sourcePaymentMethods",
    value: sourcePaymentMethods
  }),
  destinationPaymentMethods: (destinationPaymentMethods: PaymentMethod[]): TransferAction => ({
    type: "destinationPaymentMethods",
    value: destinationPaymentMethods
  }),
  sourcePaymentMethod: (sourcePaymentMethod: PaymentMethod): TransferAction => ({
    type: "sourcePaymentMethod",
    value: sourcePaymentMethod
  }),
  destinationPaymentMethod: (destinationPaymentMethod: PaymentMethod): TransferAction => ({
    type: "destinationPaymentMethod",
    value: destinationPaymentMethod
  }),
  description: (description: string): TransferAction => ({
    type: "description",
    value: description
  }),
  wallet: (wallet: Wallet): TransferAction => ({
    type: "wallet",
    value: wallet
  }),
  walletType: (walletType: WalletType): TransferAction => ({
    type: "walletType",
    value: walletType
  }),
  activeAccount: (activeAccount: Account): TransferAction => ({
    type: "activeAccount",
    value: activeAccount
  }),
  updateIdempotencyKey: (): TransferAction => ({
    type: "updateIdempotencyKey"
  }),
  error: (error: string): TransferAction => ({ type: "error", value: error })
};

export const transferInitialState: TransferState = {
  actions: actions,
  activeAccount: null,
  amount: 0,
  description: "",
  destinationAccount: null,
  destinationPaymentMethod: null,
  destinationPaymentMethods: [],
  detailType: "",
  error: null,
  idempotencyKey: "",
  sourceAccount: null,
  sourcePaymentMethod: null,
  sourcePaymentMethods: [],
  step: 0,
  title: "",
  transferType: "",
  wallet: undefined,
  walletType: "moov-wallet"
};

export const transferReducer = produce((state: TransferState, action: TransferAction) => {
  switch (action.type) {
    case "stepforward":
      state.step++;
      return state;
    case "stepback":
      state.step--;
      return state;
    case "setStep":
      state.step = action.value;
      return state;
    case "title":
      state.title = action.value;
      return state;
    case "amount":
      state.amount = action.value;
      return state;
    case "transferType":
      state.transferType = action.value;
      return state;
    case "detailType":
      state.detailType = action.value;
      return state;
    case "sourceAccount":
      state.sourceAccount = action.value;
      return state;
    case "destinationAccount":
      state.destinationAccount = action.value;
      return state;
    case "sourcePaymentMethods":
      state.sourcePaymentMethods = action.value;
      return state;
    case "destinationPaymentMethods":
      state.destinationPaymentMethods = action.value;
      return state;
    case "sourcePaymentMethod":
      state.sourcePaymentMethod = action.value;
      return state;
    case "destinationPaymentMethod":
      state.destinationPaymentMethod = action.value;
      return state;
    case "description":
      state.description = action.value;
      return state;
    case "wallet":
      state.wallet = action.value;
      return state;
    case "walletType":
      state.walletType = action.value;
      return state;
    case "activeAccount":
      state.activeAccount = action.value;
      return state;
    case "error":
      state.error = action.value;
      return state;
    case "updateIdempotencyKey":
      state.idempotencyKey = generateGUID();
      return state;
  }
});
