import { useContext, useEffect, useState } from "react";
import { NavigateFunction, useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Button, Loading, Modal, StatementExampleTable, TextInput } from "@moovfinancial/cargo";
import { useOpenApi } from "@moovfinancial/common/hooks/useOpenApi";
import { components } from "@moovfinancial/common/types/__generated-types__/moov-api";
import Toaster, { ToastInput } from "components/toaster/Toaster";
import { FacilitatorContext } from "contexts/FacilitatorContext";
import { OnboardingContext } from "contexts/OnboardingContext";
import { OnboardingStepsContext } from "contexts/OnboardingStepsContext";
import styles from "./VerificationModal.module.scss";

type BankAccount = components["schemas"]["BankAccount"];
type BankAccountVerification = components["schemas"]["BankAccountVerification"];
const NAVIGATE_CONFIG = { state: { skipUnsavedChangesCheck: true } };

interface BankAccountVerifyProps {
  bankAccount: BankAccount;
  handleBackClick: () => void;
}

const InstantInstructions = () => {
  return (
    <>
      <p>
        You should see a <b>$0.01 deposit</b> with a 4-digit verification code in your bank account.
        Look for <b>MV</b> followed by 4 numbers, usually found in the transaction description.
      </p>
      <StatementExampleTable type="instant" />
    </>
  );
};

const ACHInstructions = () => {
  return (
    <>
      <p>
        We sent a <b>$0.01</b> deposit with a verification code to your bank account, which can take
        up to 1-2 days to appear. Look for <b>MV</b> followed by a 4-digit number, usually found in
        the transaction description of the deposit.
      </p>
      <StatementExampleTable type="ACH" />
    </>
  );
};

const New = ({ navigate }: { navigate: NavigateFunction }) => {
  return (
    <>
      <Modal.Header title="Come back to verify this account in 1 business day" />
      <Modal.Body>
        <ACHInstructions />
        <p className={styles.footnote}>
          Deposits sent before 4:15pm ET will appear in the account same day. Deposits sent after
          4:15pm ET will appear the following business day.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          buttonSize="M"
          buttonStyle="fill"
          buttonType="primary"
          fullWidth
          onClick={() => navigate("../review", NAVIGATE_CONFIG)}
        >
          Continue
        </Button>
      </Modal.Footer>
    </>
  );
};

const SentCredit = ({
  handleComplete,
  isLoading,
  toastMessage,
  verification
}: {
  handleComplete: (verificationCode: string) => Promise<void>;
  isLoading: boolean;
  toastMessage?: ToastInput;
  verification: BankAccountVerification;
}) => {
  const [verificationCode, setVerificationCode] = useState("");
  return (
    <>
      <Modal.Header
        title={
          verification?.verificationMethod === "instant"
            ? "Check your bank account now"
            : "Enter the 4-digit code to verify this bank account"
        }
      />
      <Modal.Body>
        {toastMessage && <Toaster toastInput={toastMessage} />}

        {verification?.verificationMethod === "instant" ? (
          <InstantInstructions />
        ) : (
          <ACHInstructions />
        )}
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await handleComplete(verificationCode);
          }}
        >
          <TextInput
            Decoration={<div className={styles.mvDecoration}>MV</div>}
            // theme.labelElement is not properly inherited
            label={<span className={styles.labelElement}>Enter the code</span>}
            type="text"
            inputMode="numeric"
            placeholder="••••"
            // Note: On staging, use the magic number "0001" to bypass the code verification
            value={verificationCode}
            onChange={(event) => setVerificationCode(event.target.value)}
            theme={{
              outerWrapper: styles.outerWrapper,
              inputElement: styles.inputElement
            }}
            maxLength={4}
            pattern="[0-9]{4}"
            autoComplete="false"
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          buttonSize="M"
          buttonStyle="fill"
          buttonType="primary"
          fullWidth
          onClick={() => handleComplete(verificationCode)}
          disabled={isLoading}
        >
          Verify now
        </Button>
      </Modal.Footer>
    </>
  );
};

const Errors = ({
  bankAccount,
  handleAddNewBankAccountClick,
  isLoading,
  resetVerification
}: {
  bankAccount: BankAccount;
  handleAddNewBankAccountClick: () => void;
  isLoading: boolean;
  resetVerification: () => void;
}) => {
  return (
    <>
      <Modal.Header
        title={
          bankAccount.status === "verificationFailed"
            ? "Sorry, there are no remaining attempts"
            : "We couldn't verify this bank account"
        }
      />
      {bankAccount.status === "verificationFailed" ? (
        <>
          <Modal.Body>
            <p>
              You’ve entered an incorrect verification code too many times, and there are no
              remaining attempts. To continue verifying your bank account, please restart the
              process, which will send a new deposit with a new verification code.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              buttonSize="M"
              buttonStyle="fill"
              buttonType="primary"
              fullWidth
              onClick={resetVerification}
              disabled={isLoading}
            >
              Restart verification
            </Button>
          </Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Body>
            <p>
              You’ve exceeded the number of verification attempts for this bank account. Please try
              again by adding a different bank account.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="button"
              buttonSize="M"
              buttonStyle="fill"
              buttonType="primary"
              fullWidth
              onClick={handleAddNewBankAccountClick}
            >
              Add a new bank account
            </Button>
          </Modal.Footer>
        </>
      )}
    </>
  );
};

export function VerificationModal({ bankAccount, handleBackClick }: BankAccountVerifyProps) {
  const { facilitatorID } = useContext(FacilitatorContext);
  const { refreshBankAccounts } = useContext(OnboardingContext);
  const { getNextStepUrl } = useContext(OnboardingStepsContext);
  const [verification, setVerification] = useState<BankAccountVerification>();
  const [toastMessage, setToastMessage] = useState<ToastInput>();
  const [isLoading, setIsLoading] = useState(true);
  const { openApi } = useOpenApi();

  const currentLocation = useLocation();
  const navigate = useNavigate();

  const sendMicroDeposits = async () => {
    if (!bankAccount.bankAccountID) return { data: undefined, error: undefined };

    const { data, error } = await openApi.POST(
      "/accounts/{accountID}/bank-accounts/{bankAccountID}/verify",
      {
        headers: {
          "X-Account-ID": facilitatorID,
          "X-Wait-For": "rail-response"
        },
        params: {
          path: {
            accountID: facilitatorID,
            bankAccountID: bankAccount.bankAccountID
          }
        }
      }
    );
    return { data, error };
  };

  const getVerificationStatus = async () => {
    if (!bankAccount.bankAccountID) return;

    const { data, error } = await openApi.GET(
      "/accounts/{accountID}/bank-accounts/{bankAccountID}/verify",
      {
        headers: {
          "X-Account-ID": facilitatorID
        },
        params: {
          path: {
            accountID: facilitatorID,
            bankAccountID: bankAccount.bankAccountID
          }
        }
      }
    );

    if (data) {
      setVerification(data);
      setIsLoading(false);
    }
    if (error) {
      // if resource is not found then initiate
      const { data: verificationData } = await sendMicroDeposits();
      if (verificationData) {
        setVerification(verificationData);
      }
      setIsLoading(false);
    }
  };

  const resetVerification = async () => {
    setIsLoading(true);
    const { data } = await sendMicroDeposits();
    if (data) {
      setVerification(data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    void getVerificationStatus();
  }, []);

  const handleAddNewBankAccountClick = async () => {
    /* If we're already on the add page, route back to the bank accounts page
     * to give the user the option of verifying an existing bank account or
     * starting the add flow over */
    if (currentLocation.pathname.endsWith("bank-account/add")) {
      await navigate("../", NAVIGATE_CONFIG);
    } else {
      await navigate("add", NAVIGATE_CONFIG);
    }
  };

  const handleComplete = async (verificationCode: string) => {
    if (!verificationCode || !bankAccount.bankAccountID) return;
    setToastMessage(undefined);

    const { data, error } = await openApi.PUT(
      "/accounts/{accountID}/bank-accounts/{bankAccountID}/verify",
      {
        headers: {
          "X-Account-ID": facilitatorID
        },
        params: {
          path: {
            accountID: facilitatorID,
            bankAccountID: bankAccount.bankAccountID
          }
        },
        body: {
          code: verificationCode
        }
      }
    );

    if (data?.status === "successful") {
      toast("Bank account successfully linked & verified");
      refreshBankAccounts();
      void navigate(getNextStepUrl(), NAVIGATE_CONFIG);
    }

    if (error) {
      setToastMessage({
        message:
          "The code you entered is incorrect, and there are limited attempts left. Look for a $0.01 deposit with MV in the transaction description, memo, or name.",
        status: "error",
        duration: "persist"
      });
    }
  };

  return (
    <Modal isOpen={true} onClose={handleBackClick}>
      {!verification && isLoading && (
        <Modal.Body>
          <Loading centered />
        </Modal.Body>
      )}
      {verification?.status === "new" && <New navigate={navigate} />}
      {verification?.status === "sent-credit" && (
        <SentCredit
          handleComplete={handleComplete}
          isLoading={isLoading}
          toastMessage={toastMessage}
          verification={verification}
        />
      )}
      {verification?.status !== "new" && verification?.status !== "sent-credit" && (
        <Errors
          bankAccount={bankAccount}
          handleAddNewBankAccountClick={handleAddNewBankAccountClick}
          isLoading={isLoading}
          resetVerification={resetVerification}
        />
      )}
    </Modal>
  );
}
