import type { components } from "@moovfinancial/common/types/__generated-types__/moov-api";
import { formatDollars } from "@moovfinancial/common/utils/format/formatCurrency";
import { formatPercentInput } from "@moovfinancial/common/utils/format/formatPercent";
import styles from "./FeeRow.module.scss";

type BillableFee = components["schemas"]["BillableFee"];
type CardAcquiringModel = components["schemas"]["CardAcquiringModel"];

export function FeeRow({
  fee,
  cardAcquiringModel
}: {
  fee: BillableFee;
  cardAcquiringModel: CardAcquiringModel;
}) {
  const { minPerTransaction, maxPerTransaction, variableRate, fixedAmount } =
    fee.feeProperties || {};

  /* For certain fees, append "+ interchange & network fees" or "+ network fees" to the rate display. */
  const renderMaybeInterchangeAndNetworkFees = () => {
    if (cardAcquiringModel !== "cost-plus") return undefined;
    if (fee.billableEvent !== "card-settled-volume" && fee.billableEvent !== "card-auth-volume")
      return undefined;
    return <div className={styles.priceDetails}>+ interchange & network fees</div>;
  };

  /* If `feeCategory` is "monthly-platform", append " per month" to the rate display. */
  const renderMaybePerMonth = () => {
    if (fee.feeCategory !== "monthly-platform") return undefined;
    return <div className={styles.priceDetails}>Per month</div>;
  };

  /* Shows the min & max dollar amounts of the charge, if set */
  const renderMaybeMinMax = () => {
    if (!minPerTransaction && !maxPerTransaction) return undefined;
    return (
      <div className={styles.priceDetails}>
        {minPerTransaction && `Min: ${formatDollars(minPerTransaction.valueDecimal)}`}
        {minPerTransaction && maxPerTransaction && ", "}
        {maxPerTransaction && `Max: ${formatDollars(maxPerTransaction.valueDecimal)}`}
      </div>
    );
  };

  return (
    <div className={styles.row} key={fee.billableFeeID}>
      <div className={styles.name}>{fee.feeName}</div>
      <div className={styles.right}>
        <div className={styles.price}>
          {variableRate && formatPercentInput(variableRate)}
          {variableRate && fixedAmount && " + "}
          {fixedAmount && formatDollars(fixedAmount.valueDecimal)}
        </div>
        {renderMaybePerMonth()}
        {renderMaybeInterchangeAndNetworkFees()}
        {renderMaybeMinMax()}
      </div>
    </div>
  );
}
