import clsx from "clsx";
import { format } from "date-fns";
import { ReactElement, useState } from "react";
import { capitalize } from "remeda";
import { components } from "@moovfinancial/common/types/__generated-types__/moov-api";
import { Button } from "../../../components/Actions/Button";
import { ButtonCopy } from "../../../components/Actions/ButtonCopy";
import { Badge, BadgeColor } from "../../../components/Foundations/Badge";
import {
  IconCheckmarkCircleOutlined,
  IconChevronDown,
  IconChevronUp,
  IconRemoveCircleOutlined
} from "../../../components/Icons";
import { Icon } from "../../../components/Icons/Icon";
import { Card } from "../../../components/Layout/Card";
import styles from "./PricingMetadataCard.module.scss";

type FeePlan = components["schemas"]["FeePlan"];
type FeePlanAgreement = components["schemas"]["FeePlanAgreement"];
type AgreementStatus = FeePlanAgreement["status"];
type PartnerPricing = components["schemas"]["PartnerPricing"];
type PartnerPricingAgreement = components["schemas"]["PartnerPricingAgreement"];
const isAgreement = (
  pricing: FeePlan | FeePlanAgreement | PartnerPricing | PartnerPricingAgreement
): pricing is FeePlanAgreement | PartnerPricingAgreement => {
  return "status" in pricing && "acceptedOn" in pricing;
};
const isPartnerPricing = (
  pricing: FeePlan | FeePlanAgreement | PartnerPricing | PartnerPricingAgreement
): pricing is PartnerPricing | PartnerPricingAgreement => {
  return "revenueShare" in pricing;
};

const statusToBadge: Record<AgreementStatus, { icon: ReactElement; color: BadgeColor }> = {
  active: { icon: <Icon iconComponent={IconCheckmarkCircleOutlined} />, color: "blue" },
  terminated: { icon: <Icon iconComponent={IconRemoveCircleOutlined} />, color: "gray" }
};

interface PricingMetadataCardProps {
  pricing: FeePlan | FeePlanAgreement | PartnerPricing | PartnerPricingAgreement;
  className?: string;
}

export const PricingMetadataCard = ({ pricing, className }: PricingMetadataCardProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Card className={clsx(styles.card, className)}>
      <div className={styles.topSection}>
        <div>
          <p className={styles.cardAcquiringModel}>
            {pricing.cardAcquiringModel.replaceAll("-", " ").toUpperCase()}
          </p>
          <div className={styles.feePlanNameContainer}>
            <h2 className={styles.feePlanName}>{capitalize(pricing.name)}</h2>
            {isAgreement(pricing) && (
              <Badge
                Icon={statusToBadge[pricing.status].icon}
                color={statusToBadge[pricing.status].color}
                data-testid={`agreement-status-badge-${pricing.status}`}
                label="Agreement status"
                noLabel
              />
            )}
          </div>
        </div>
        <div className={styles.planIdContainer}>
          Plan ID{" "}
          <ButtonCopy
            className={styles.buttonCopy}
            copyContent={pricing.planID}
            truncate="middle"
            iconPlacement="right"
          >
            {pricing.planID.toUpperCase()}
          </ButtonCopy>
        </div>
      </div>
      <hr className={styles.hr} />
      <div>
        {isExpanded && (
          <>
            {pricing.description && (
              <div className={styles.row}>
                <div className={styles.name}>Description</div>
                <div className={styles.right}>{pricing.description}</div>
              </div>
            )}
            {isAgreement(pricing) && (
              <div className={styles.row}>
                <div className={styles.name}>Accepted on</div>
                <div className={styles.right}>{format(pricing.acceptedOn, "MMMM d, yyyy")}</div>
              </div>
            )}
            {isPartnerPricing(pricing) && (
              <div className={styles.row}>
                <div className={styles.name}>Partner revenue share</div>
                <div className={styles.right}>{pricing.revenueShare}%</div>
              </div>
            )}
          </>
        )}
        <div className={styles.viewButtonContainer}>
          {isExpanded ? (
            <Button
              buttonType="secondary"
              buttonStyle="text"
              buttonSize="XS"
              onClick={() => setIsExpanded(false)}
            >
              View less
              <IconChevronUp />
            </Button>
          ) : (
            <Button
              buttonType="secondary"
              buttonStyle="text"
              buttonSize="XS"
              onClick={() => setIsExpanded(true)}
            >
              View more
              <IconChevronDown />
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
};
