import { createContext, useState } from "react";
import { components } from "@moovfinancial/common/types/__generated-types__/moov-api";

type Invite = components["schemas"]["OnboardingInvite"];

interface ContextProps {
  children: React.ReactNode;
}

interface ContextTypes {
  invite?: Invite;
  setInvite: (invite: Invite) => void;
}

export const OnboardingInviteContext = createContext<ContextTypes>({
  invite: undefined,
  setInvite: () => {}
});

export function OnboardingInviteContextProvider({ children }: ContextProps) {
  const [invite, setInvite] = useState<Invite>();

  return (
    <OnboardingInviteContext.Provider
      value={{
        invite,
        setInvite
      }}
    >
      {children}
    </OnboardingInviteContext.Provider>
  );
}
