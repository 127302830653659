import { useContext, useEffect, useMemo, useState } from "react";
import { useOpenApi } from "@moovfinancial/common/hooks/useOpenApi";
import type { DeepPartial } from "@moovfinancial/common/types/DeepTypes";
import { components } from "@moovfinancial/common/types/__generated-types__/moov-api";
import { Action, MoovAdminResource, Resource } from "api/Role.model";
import {
  Account,
  AccountUnderwritingStatus,
  BooleanAccountMap,
  BooleanAccountUnderwritingMap
} from "api/v2";
import { AccountContext } from "contexts/AccountContext";
import { CapabilitiesContext } from "contexts/CapabilitiesContext";
import { unlockCurrentlyDueFields, unlockErroredFields } from "./useAccountLocking.utils";
import { useAuthenticatedAdminRoute } from "./useAuthenticatedAdminRoute";
import useRbac from "./useRbac";

type Capability = components["schemas"]["Capability"];

const LOCKED_UNDERWRITING_STATUSES = new Set(["approved", "rejected", "pendingReview"]);

function useAccountLocking(
  accountFromProps?: DeepPartial<Account> | null,
  facilitatorID?: string,
  capabilitiesFromProps?: Capability[]
): {
  accountLocks: BooleanAccountMap;
  underwritingLocks: BooleanAccountUnderwritingMap;
  verificationLocked: boolean;
  underwritingLocked: boolean;
} {
  const { account: accountFromContext, accountStatus } = useContext(AccountContext);
  const { capabilities: capabilitiesFromContext } = useContext(CapabilitiesContext);
  const { openApi } = useOpenApi();
  const canEditLockedAccount = useRbac(Action.Write, MoovAdminResource.adminAccountPatch);
  const isReadOnly = !useRbac(Action.Write, Resource.Accounts) && !canEditLockedAccount;
  const isAdminRoute = useAuthenticatedAdminRoute();

  const [underwritingStatus, setUnderwritingStatus] = useState<AccountUnderwritingStatus>(
    accountStatus?.underwriting ?? "notRequested"
  );

  const account = accountFromProps || accountFromContext;
  const capabilities = capabilitiesFromProps || (capabilitiesFromContext as Capability[]);

  useEffect(() => {
    const fetchUnderwritingStatus = async () => {
      if (!account?.accountID || !facilitatorID) return;

      try {
        const { data } = await openApi.GET("/accounts/{accountID}/underwriting", {
          params: {
            path: {
              accountID: account.accountID
            }
          },
          headers: {
            "X-Account-ID": facilitatorID
          }
        });

        setUnderwritingStatus(data?.status || "notRequested");
      } catch {
        setUnderwritingStatus("notRequested");
      }
    };

    void fetchUnderwritingStatus();
  }, [facilitatorID, account]);

  const underwritingLocked = LOCKED_UNDERWRITING_STATUSES.has(underwritingStatus) || isReadOnly;

  const verificationLocked = useMemo(() => {
    // The account should not be locked for admins with edit ability
    if (isAdminRoute && canEditLockedAccount) return false;

    // The account should be locked when all capabilities (aside from transfers) are satisfied, unlocked if any capability has currently due requirements
    // See conversation here: https://moovfinancial.slack.com/archives/C059JFG2Y0H/p1737741053946209
    const allCapabilityRequirementsMet = capabilities.every((capability) => {
      if (capability.capability === "transfers") return true;

      return (
        !capability.requirements?.currentlyDue ||
        capability.requirements?.currentlyDue?.length === 0
      );
    });

    return allCapabilityRequirementsMet || isReadOnly;
  }, [canEditLockedAccount, isAdminRoute, capabilities, isReadOnly]);

  if (account === null || (!verificationLocked && !underwritingLocked)) {
    return {
      accountLocks: {},
      underwritingLocks: {},
      verificationLocked: false,
      underwritingLocked: false
    };
  }

  let accountLocks = {} as BooleanAccountMap;
  let underwritingLocks = {} as BooleanAccountUnderwritingMap;

  if (verificationLocked) accountLocks = defaultAccountLocks(account, isReadOnly);
  if (underwritingLocked) underwritingLocks = defaultUnderwritingAccountLocks;
  if ((verificationLocked || underwritingLocked) && !isReadOnly) {
    let locks = unlockErroredFields(accountLocks, underwritingLocks, capabilities);
    locks = unlockCurrentlyDueFields(
      locks.newAccountLocks,
      locks.newUnderwritingLocks,
      capabilities
    );
    accountLocks = locks.newAccountLocks;
    underwritingLocks = locks.newUnderwritingLocks;
  }

  return {
    accountLocks,
    underwritingLocks,
    verificationLocked,
    underwritingLocked
  };
}

export const defaultUnderwritingAccountLocks: BooleanAccountUnderwritingMap = {
  maxTransactionSize: true,
  averageMonthlyTransactionVolume: true,
  averageTransactionSize: true
};

export const defaultAccountLocks = (
  account: DeepPartial<Account>,
  isReadOnly: boolean
): BooleanAccountMap => {
  if (isReadOnly) {
    return {
      accountID: true,
      accountType: true,
      displayName: true,
      profile: {
        individual: {
          name: {
            firstName: true,
            middleName: true,
            lastName: true,
            suffix: true
          },
          phone: {
            number: true
          },
          email: true,
          address: {
            addressLine1: true,
            addressLine2: true,
            city: true,
            stateOrProvince: true,
            postalCode: true,
            country: true
          },
          birthDate: {
            day: true,
            month: true,
            year: true
          },
          governmentID: {
            ssn: {
              lastFour: true,
              full: true
            },
            itin: {
              lastFour: true,
              full: true
            }
          }
        },
        business: {
          legalBusinessName: true,
          doingBusinessAs: true,
          website: true,
          description: true,
          businessType: true,
          taxID: {
            ein: {
              number: true
            }
          },
          address: {
            addressLine1: true,
            addressLine2: true,
            city: true,
            stateOrProvince: true,
            postalCode: true,
            country: true
          },
          phone: {
            number: true
          },
          email: true,
          representatives: [],
          industryCodes: {
            naics: true,
            sic: true,
            mcc: true
          }
        }
      },
      metadata: {},
      termsOfService: {
        acceptedDate: true,
        acceptedIP: true,
        token: true
      },
      foreignID: true
    };
  }
  return {
    accountID: !!account.accountID,
    accountType: !!account.accountType,
    displayName: !!account.displayName,
    profile: {
      individual: {
        name: {
          firstName: !!account.profile?.individual?.name,
          middleName: !!account.profile?.individual?.name,
          lastName: !!account.profile?.individual?.name,
          suffix: !!account.profile?.individual?.name
        },
        phone: {
          number: !!account.profile?.individual?.phone
        },
        email: !!account.profile?.individual?.email,
        address: {
          addressLine1: !!account.profile?.individual?.address,
          addressLine2: !!account.profile?.individual?.address,
          city: !!account.profile?.individual?.address,
          stateOrProvince: !!account.profile?.individual?.address,
          postalCode: !!account.profile?.individual?.address,
          country: !!account.profile?.individual?.address
        },
        birthDate: {
          day: !!account.profile?.individual?.birthDateProvided,
          month: !!account.profile?.individual?.birthDateProvided,
          year: !!account.profile?.individual?.birthDateProvided
        },
        governmentID: {
          ssn: {
            lastFour: !!account.profile?.individual?.governmentIDProvided,
            full: !!account.profile?.individual?.governmentIDProvided
          },
          itin: {
            lastFour: !!account.profile?.individual?.governmentIDProvided,
            full: !!account.profile?.individual?.governmentIDProvided
          }
        }
      },
      business: {
        legalBusinessName: !!account.profile?.business?.legalBusinessName,
        doingBusinessAs: !!account.profile?.business?.doingBusinessAs,
        website: !!account.profile?.business?.website,
        description: !!account.profile?.business?.description,
        businessType: !!account.profile?.business?.businessType,
        taxID: {
          ein: {
            number: !!account.profile?.business?.taxIDProvided
          }
        },
        address: {
          addressLine1: !!account.profile?.business?.address,
          addressLine2: !!account.profile?.business?.address,
          city: !!account.profile?.business?.address,
          stateOrProvince: !!account.profile?.business?.address,
          postalCode: !!account.profile?.business?.address,
          country: !!account.profile?.business?.address
        },
        phone: {
          number: !!account.profile?.business?.phone
        },
        email: !!account.profile?.business?.email,
        representatives: [],
        industryCodes: {
          naics:
            !!account.profile?.business?.industryCodes?.naics &&
            account.profile?.business?.industryCodes?.naics !== "0000",
          mcc:
            !!account.profile?.business?.industryCodes?.mcc &&
            account.profile?.business?.industryCodes?.mcc !== "0000",
          sic:
            !!account.profile?.business?.industryCodes?.sic &&
            account.profile?.business?.industryCodes?.sic !== "0000"
        }
      }
    },
    metadata: {},
    termsOfService: {
      token: false,
      acceptedDate: false,
      acceptedIP: false
    },
    foreignID: false
  };
};

export default useAccountLocking;
