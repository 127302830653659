import { Chance } from "chance";
import { mergeDeep } from "remeda";
import type { DeepPartial } from "@moovfinancial/common/types/DeepTypes";
import type { components } from "@moovfinancial/common/types/__generated-types__/moov-api";
import deepMerge from "@moovfinancial/common/utils/deepMerge";
import { ApplepayCardBrands, BankAccountHolderType, BankAccountStatus, CardType } from "api/v2";
import { UIPaymentMethod } from "pages/transfers/NewTransfer/NewTransferPageTypes";

type PaymentMethod = components["schemas"]["PaymentMethod"];
type ApplePayPaymentMethod = components["schemas"]["ApplePayPaymentMethod"];
type BankAccount = components["schemas"]["BankAccount"];
type PaymentMethodType = components["schemas"]["PaymentMethodType"];
const chance = Chance();

/**
 * @deprecated Should use dynamic mocks in the future;
 */
export const mockPaymentMethod: PaymentMethod = {
  paymentMethodID: "ec7e1848-dc80-4ab0-8827-dd7fc0737b43",
  paymentMethodType: "ach-debit-collect",
  bankAccount: {
    updatedOn: "2024-01-01T00:00:00.000Z",
    bankAccountID: "ec7e1848-dc80-4ab0-8827-dd7fc0737b43",
    fingerprint: "9948962d92a1ce40c9f918cd9ece3a22bde62fb325a2f1fe2e833969de672ba3",
    status: "new",
    holderName: "Jules Jackson",
    holderType: "individual",
    bankAccountType: "checking",
    routingNumber: "string",
    bankName: "CAPITAL ONE",
    lastFourAccountNumber: "7000"
  }
};

const availablePaymentMethodTypes: PaymentMethodType[] = [
  "ach-debit-fund",
  "ach-debit-collect",
  "ach-credit-standard",
  "ach-credit-same-day",
  "rtp-credit",
  "moov-wallet",
  "card-payment",
  "push-to-card",
  "apple-pay"
];

const availableCardBrands: ApplepayCardBrands[] = [
  "American Express",
  "Visa",
  "Mastercard",
  "Discover"
];

const availableCardTypes: CardType[] = ["credit", "debit", "prepaid", "unknown"];

export const makeBankAccountPaymentMethod = (paymentMethod: DeepPartial<PaymentMethod> = {}) =>
  deepMerge(
    {
      paymentMethodID: chance.guid(),
      paymentMethodType: "ach-debit-collect" as PaymentMethodType,
      bankAccount: {
        updatedOn: "2024-01-01T00:00:00.000Z",
        bankAccountID: chance.guid(),
        accountNumber: chance.string({ length: 10, numeric: true }),
        fingerprint: chance.string({ length: 64 }),
        status: "new" as BankAccountStatus,
        holderName: chance.name(),
        holderType: "individual" as BankAccountHolderType,
        bankAccountType: "checking",
        routingNumber: chance.string({ length: 9, numeric: true }),
        bankName: chance.name(),
        lastFourAccountNumber: chance.string({ length: 4, numeric: true })
      } as BankAccount
    },
    paymentMethod
  );

export const makeMoovWalletPaymentMethod = (
  paymentMethod: DeepPartial<PaymentMethod> = {}
): PaymentMethod =>
  deepMerge(
    {
      paymentMethodID: chance.guid(),
      paymentMethodType: "moov-wallet",
      wallet: {
        walletID: chance.guid(),
        availableBalance: {
          value: chance.integer({ min: 0, max: 100000 }),
          currency: "USD"
        }
      }
    },
    paymentMethod
  ) as PaymentMethod;

export const makeCardPaymentMethod = (
  paymentMethod: DeepPartial<PaymentMethod> = {}
): PaymentMethod =>
  mergeDeep(
    {
      paymentMethodID: chance.guid(),
      paymentMethodType: "card-payment",
      card: {
        cardID: chance.guid(),
        brand: chance.pickone(availableCardBrands),
        cardType: chance.pickone(availableCardTypes),
        lastFourCardNumber: chance.string({ length: 4, numeric: true }),
        fingerprint: chance.guid(),
        holderName: chance.name(),
        billingAddress: {
          addressLine1: chance.street(),
          addressLine2: chance.string(),
          city: chance.string(),
          stateOrProvince: chance.string(),
          postalCode: chance.postcode(),
          country: chance.country()
        },
        cardVerification: {
          addressLine1: "match",
          postalCode: "match",
          cvv: "match"
        },
        bin: chance.string({ length: 6, numeric: true }),
        expiration: {
          month: chance.integer({ min: 1, max: 12 }).toString(),
          year: chance.year().toString()
        }
      }
    },
    paymentMethod
  ) as PaymentMethod;

export const makeApplepayPaymentMethod = (
  paymentMethod: DeepPartial<PaymentMethod> = {}
): PaymentMethod =>
  deepMerge(
    {
      paymentMethodID: chance.guid(),
      paymentMethodType: "apple-pay",
      applePay: {
        cardType: chance.pickone(availableCardTypes),
        cardDisplayName: chance.word(),
        brand: chance.pickone(availableCardBrands),
        expiration: {
          month: chance.integer({ min: 1, max: 12 }).toString(),
          year: chance.year().toString()
        }
      } as ApplePayPaymentMethod["applePay"]
    },
    paymentMethod
  ) as PaymentMethod;

export const makePaymentMethodWithType = (type: PaymentMethodType) => {
  switch (type) {
    case "ach-debit-fund":
    case "ach-debit-collect":
    case "ach-credit-standard":
    case "ach-credit-same-day":
    case "rtp-credit":
      return makeBankAccountPaymentMethod({ paymentMethodType: type });
    case "card-payment":
    case "pull-from-card":
    case "push-to-card":
    case "card-present-payment":
      return makeCardPaymentMethod({ paymentMethodType: type });
    case "apple-pay":
      return makeApplepayPaymentMethod();
    case "moov-wallet":
      return makeMoovWalletPaymentMethod();
  }
};

const getPaymentMethod = (type?: PaymentMethodType): DeepPartial<PaymentMethod> => {
  const randomType = chance.pickone(availablePaymentMethodTypes);
  return makePaymentMethodWithType(type ?? randomType);
};

const getUIPaymentMethod = (
  pmArg: DeepPartial<PaymentMethod> | DeepPartial<UIPaymentMethod> = {}
): UIPaymentMethod => {
  const baseUIPaymentMethod = isUIPaymentMethod(pmArg)
    ? pmArg
    : { paymentMethodRaw: makePaymentMethod(pmArg) };
  const paymentMethod = baseUIPaymentMethod.paymentMethodRaw;
  const enabled = chance.bool();
  const hasChildren = chance.bool();
  return deepMerge(
    {
      paymentMethodID: hasChildren ? chance.word() : chance.guid(),
      enabled,
      disabledReason: enabled ? chance.sentence() : undefined,
      name: chance.name(),
      humanReadableWaitTime: chance.sentence({ words: 3 }),
      accountType: chance.word(),
      /* eslint-disable */
      // @ts-expect-error not fixing this as it's only used in tests
      balance: paymentMethod?.wallet?.availableBalance as number,
      lastFourDigits:
        // @ts-expect-error not fixing this as it's only used in tests

        paymentMethod?.card?.lastFourCardNumber ??
        // @ts-expect-error not fixing this as it's only used in tests

        paymentMethod?.bankAccount?.lastFourAccountNumber ??
        undefined,
      children: hasChildren ? [getUIPaymentMethod()] : []
    },
    // @ts-expect-error not fixing this as it's only used in tests
    baseUIPaymentMethod
    /* eslint-enable */
  ) as UIPaymentMethod;
};

export const makePaymentMethod = (paymentMethod: DeepPartial<PaymentMethod> = {}): PaymentMethod =>
  deepMerge(getPaymentMethod(paymentMethod.paymentMethodType), paymentMethod) as PaymentMethod;

export const isUIPaymentMethod = (
  paymentMethod: DeepPartial<PaymentMethod> | DeepPartial<UIPaymentMethod>
): paymentMethod is DeepPartial<UIPaymentMethod> => {
  const uiPaymentMethod = paymentMethod as DeepPartial<UIPaymentMethod>;
  return uiPaymentMethod.paymentMethodRaw !== undefined;
};

export const makeUIPaymentMethod = (
  paymentMethod: DeepPartial<PaymentMethod> | DeepPartial<UIPaymentMethod> = {}
): UIPaymentMethod => getUIPaymentMethod(paymentMethod);
