import { isBoolean } from "remeda";
import type { DeepPartial } from "@moovfinancial/common/types/DeepTypes";
import { components } from "@moovfinancial/common/types/__generated-types__/moov-api";
import { camelCaseWordToSentence } from "@moovfinancial/common/utils/stringManipulation";
import { DetailItem } from "components/lists/DetailItem";

export type Fulfillment = components["schemas"]["FulfillmentDetails"];

export const FulfillmentDetails = ({ fulfillment }: { fulfillment?: DeepPartial<Fulfillment> }) => {
  // These values aren't strictly required currently, so we'll only show the values that have been set (note: this may change in the future)
  return (
    <>
      {isBoolean(fulfillment?.hasPhysicalGoods) && (
        <DetailItem label="Sells physical goods">
          {fulfillment.hasPhysicalGoods ? "Yes" : "No"}
        </DetailItem>
      )}
      {isBoolean(fulfillment?.isShippingProduct) && (
        <DetailItem label="Business ships product">
          {fulfillment.isShippingProduct ? "Yes" : "No"}
        </DetailItem>
      )}
      {!!fulfillment?.returnPolicy && (
        <DetailItem label="Return policy">
          {camelCaseWordToSentence(fulfillment.returnPolicy)}
        </DetailItem>
      )}
      {!!fulfillment?.shipmentDurationDays && (
        <DetailItem label="Time to delivery">
          {`${fulfillment.shipmentDurationDays} days`}
        </DetailItem>
      )}
    </>
  );
};
