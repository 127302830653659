import { FormEventHandler, useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLinkClickHandler, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { FormGroup, Icon } from "@moovfinancial/cargo";
import { IconInfoOutlined } from "@moovfinancial/cargo/icons";
import { anyResponseErrorToString } from "@moovfinancial/common/api/legacyRequest";
import Accordion from "components/accordion/Accordion";
import { DocumentUpload, FileForUploadState } from "components/form/DocumentUpload";
import { Checkbox } from "components/form/Form";
import { FileDetails, OnboardingContext } from "contexts/OnboardingContext";
import { OnboardingStepsContext } from "contexts/OnboardingStepsContext";
import { FooterButtons } from "../FooterButtons";
import { parseErrors } from "../helpers/errors";
import styles from "./ProcessingStatements.module.scss";

function isFileResponse(x: File | FileDetails | undefined | null): x is FileDetails {
  return !!x && "fileID" in x;
}
function provideFileStatus(
  file: File | FileDetails,
  status: FileForUploadState["status"],
  errorMessage?: string
): FileForUploadState;
function provideFileStatus(
  files: (File | FileDetails)[],
  status: FileForUploadState["status"],
  errorMessage?: string
): FileForUploadState[];
function provideFileStatus(
  files: File | FileDetails | (File | FileDetails)[],
  status: FileForUploadState["status"],
  errorMessage?: string
): FileForUploadState | FileForUploadState[] {
  const wasArray = Array.isArray(files);
  const fileArray = wasArray ? files : [files];
  const resultArray = fileArray.map<FileForUploadState>((f) =>
    isFileResponse(f)
      ? { errorMessage, file: new File([], f.fileName ?? "Unknown file"), status }
      : { errorMessage, file: f, status }
  );
  return wasArray ? resultArray : resultArray[0];
}

export function ProcessingStatements() {
  const { files, uploadFiles, certifiedNoStatements, setCertifiedNoStatements } =
    useContext(OnboardingContext);
  const { getNextStepUrl, getPreviousStepUrl } = useContext(OnboardingStepsContext);
  const navigate = useNavigate();
  const handleBackClick = useLinkClickHandler<HTMLButtonElement>(getPreviousStepUrl(), {
    // This page doesn't have fields that would matter in the context of unsaved changes, so we can skip the check
    state: { skipUnsavedChangesCheck: true }
  });
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const [filesWithStatus, setFilesWithStatus] = useState<FileForUploadState[]>(
    provideFileStatus(files, "success")
  );

  // TODO: Remove when the API supports a proper way to signal that there are no statements
  // Create a placeholder file when certifiedNoStatements is true
  const createPlaceholderFile = () => {
    // Create a placeholder file with minimal content to avoid 400 error
    const placeholderContent = new Uint8Array([37, 80, 68, 70, 45, 49, 46, 55]); // %PDF-1.7 header bytes
    const placeholderFile = new File([placeholderContent], "no-processing-statements.pdf", {
      type: "application/pdf"
    });
    setFilesToUpload([placeholderFile]);
    setFilesWithStatus([{ file: placeholderFile, status: "pending" }]);
  };

  // Handle the certification checkbox change
  const handleCertificationChange = (checked: boolean) => {
    setCertifiedNoStatements(checked);

    if (checked) {
      createPlaceholderFile();
    } else {
      setFilesToUpload([]);
      setFilesWithStatus(provideFileStatus(files, "success"));
    }
  };

  const handleSubmit: FormEventHandler = async (e) => {
    e.preventDefault();

    const response = await uploadFiles(filesToUpload);

    const erroredFiles = response.filter(
      (r) => r.status === "rejected" || (r.status === "fulfilled" && !r.value.response.ok)
    );
    const updatedFiles = filesToUpload.map<FileForUploadState>((file, index) => {
      const result = response[index];
      if (result.status === "fulfilled" && result.value.response.ok) {
        return provideFileStatus(file, "success");
      }
      const errorMessage =
        result.status === "rejected"
          ? JSON.stringify(result.reason)
          : // @ts-expect-error - 🤔 not sure what's going on here, but I rather not break it in the PR
            anyResponseErrorToString(parseErrors<FileDetails>(result.value.error));
      return provideFileStatus(file, "error", errorMessage || "An unknown error has occurred");
    });

    setFilesWithStatus([...provideFileStatus(files, "success"), ...updatedFiles]);

    if (erroredFiles.length > 0) {
      toast("There was an error uploading your processing statements");
      return;
    } else {
      // TODO: Do something with the certifiedNoStatements once the API supports it
      void navigate(getNextStepUrl(), {
        state: { skipUnsavedChangesCheck: true }
      });
    }
  };

  const handleAddFile = (file: File) => {
    setFilesToUpload((prev) => [...prev, file]);
    setFilesWithStatus((prev) => [...prev, { file, status: "pending" } as const]);
  };

  const handleDeleteFile = (name: string) => {
    setFilesToUpload((prev) => prev.filter((f) => f.name !== name));
    setFilesWithStatus((prev) =>
      prev.filter((f) => !(f.status === "pending" && f.file.name === name))
    );
  };

  return (
    <div className={styles.content}>
      <Helmet>
        <title>Processing statements</title>
      </Helmet>
      <h2 className={styles.header}>Processing statements</h2>
      <div className={styles.row}>
        <p className={styles.pageInfoText}>
          Provide processing statements from the past three months. If the business is seasonal,
          provide processing statements for the three busiest months.
        </p>
      </div>
      <DocumentUpload
        addFile={handleAddFile}
        onDelete={handleDeleteFile}
        selectedFiles={filesWithStatus}
      />
      {!files.length && (
        <Accordion>
          <Accordion.Section
            className={styles.accordion}
            collapsed
            title="Don't have any processing statements?"
            badge={<Icon iconComponent={IconInfoOutlined} size="XS" />}
          >
            <FormGroup className={styles.body} noGap={false}>
              <Checkbox
                checked={certifiedNoStatements}
                label="I certify that my company has never accepted card payments and has no processing
          statements to provide."
                onChange={(e) => handleCertificationChange(e.target.checked)}
              />
            </FormGroup>
          </Accordion.Section>
        </Accordion>
      )}
      <FooterButtons
        disabled={!files.length && !filesToUpload.length && !certifiedNoStatements}
        onBack={handleBackClick}
        onContinue={handleSubmit}
      />
    </div>
  );
}
