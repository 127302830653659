import { FilePurpose } from "api/v2";
import { StepID } from "pages/accounts/AccountModals/Steps/Steps.model";
import {
  FileVerification,
  FileVerificationTypes
} from "pages/documents/DocumentUploadModal/DocumentUploadModal";

export const requirementToLabel: Record<string, string> = {
  "account.tos-acceptance": "Terms of service",
  "individual.mobile": "Phone number",
  "individual.email": "Email",
  "individual.email-or-mobile": "Phone number or email",
  "individual.firstname": "First name",
  "individual.lastname": "Last name",
  "individual.address": "Address",
  "individual.ssn-last4": "Last four of SSN",
  "individual.ssn": "SSN",
  "individual.birthdate": "Date of birth",
  "business.legalname": "Business name",
  "business.description-or-website": "Website or description",
  // entity-type deprecated in favor of business-type
  "business.entity-type": "Business type",
  "business.business-type": "Business type",
  "business.dba": "Doing business as",
  "business.ein": "EIN",
  "business.address": "Address",
  "business.phone": "Phone number",
  "business.admins": "Business administrator",
  "business.controllers": "Business controller",
  "business.owners": "Business owner",
  "business.primary-regulator": "Primary regulator",
  "business.classification": "Business classification",
  "business.industry-code-mcc": "Industry code",
  "business.indicate-owners-provided": "Owners provided",
  "business.average-monthly-transaction-volume": "Average monthly transaction volume",
  "business.average-transaction-size": "Average transaction size",
  "business.max-transaction-size": "Max transaction size",
  "business.description": "Business description",
  "business.underwriting-documents-tier-one": "Underwriting documents",
  "document.individual.verification": "Individual verification documents",
  "document.business.verification": "Business verification documents",
  "document.individual.tin": "SSN/ITIN documents"
};

export const repRequirementToLabel: Record<string, string> = {
  mobile: "Phone number",
  email: "Email",
  "email-or-mobile": "Phone number or email",
  firstname: "First name",
  lastname: "Last name",
  address: "Address",
  birthdate: "Date of birth",
  ssn: "Full SSN",
  "ssn-last4": "Last 4 of SSN",
  "job-title": "Job title",
  "is-controller": "Controller status",
  "is-owner": "Owner status",
  ownership: "Ownership percentage",
  verification: "Verification documents",
  tin: "SSN/ITIN documents"
};

const RepresentativeSteps = ["list"];

export type RepresentativeSteps = "list";

interface RepRequirementLink {
  modal: "representative";
  step: RepresentativeSteps;
}

const AccountRequirementSteps: StepID[] = [
  "busContact",
  "busDetails",
  "indvContact",
  "indvDetails",
  "busTransactions",
  "busStatements"
];

type AccountRequirementSteps = (typeof AccountRequirementSteps)[number];

interface AccountRequirementLink {
  modal: "account";
  step: AccountRequirementSteps;
}

export const DocumentRequirementSteps: (FilePurpose | FileVerification)[] = [
  "identity_verification",
  "business_verification",
  "merchant_underwriting",
  "representative_verification",
  "individual_verification",
  "account_requirement",
  ...FileVerificationTypes
];

type DocumentRequirementSteps = (typeof DocumentRequirementSteps)[number];

interface DocumentRequirementLink {
  modal: "documents";
  step: DocumentRequirementSteps;
}

export type RequirementSteps =
  | RepresentativeSteps
  | AccountRequirementSteps
  | DocumentRequirementSteps;

export type RequirementLink = RepRequirementLink | AccountRequirementLink | DocumentRequirementLink;

export const requirementToLink: Record<string, RequirementLink | null> = {
  "account.tos-acceptance": null,
  "individual.mobile": { modal: "account", step: "indvContact" },
  "individual.email": { modal: "account", step: "indvContact" },
  "individual.email-or-mobile": { modal: "account", step: "indvContact" },
  "individual.firstname": { modal: "account", step: "indvDetails" },
  "individual.lastname": { modal: "account", step: "indvDetails" },
  "individual.address": { modal: "account", step: "indvContact" },
  "individual.ssn-last4": { modal: "account", step: "indvDetails" },
  "individual.ssn": { modal: "account", step: "indvDetails" },
  "individual.birthdate": { modal: "account", step: "indvDetails" },
  "business.legalname": { modal: "account", step: "busDetails" },
  "business.description-or-website": { modal: "account", step: "busContact" },
  // entity-type depcreated in favor of business-type
  "business.entity-type": { modal: "account", step: "busDetails" },
  "business.business-type": { modal: "account", step: "busDetails" },
  "business.dba": { modal: "account", step: "busDetails" },
  "business.ein": { modal: "account", step: "busDetails" },
  "business.address": { modal: "account", step: "busContact" },
  "business.phone": { modal: "account", step: "busContact" },
  "business.admins": null,
  "business.controllers": { modal: "representative", step: "list" },
  "business.owners": { modal: "representative", step: "list" },
  "business.primary-regulator": null,
  "business.classification": { modal: "account", step: "busDetails" },
  "business.industry-code-mcc": { modal: "account", step: "busDetails" },
  "business.indicate-owners-provided": { modal: "representative", step: "list" },
  "business.average-monthly-transaction-volume": {
    modal: "account",
    step: "busTransactions"
  },
  "business.average-transaction-size": { modal: "account", step: "busTransactions" },
  "business.max-transaction-size": { modal: "account", step: "busTransactions" },
  "business.description": { modal: "account", step: "busTransactions" },
  "business.underwriting-documents-tier-one": { modal: "account", step: "busStatements" },
  "document.individual.verification": { modal: "documents", step: "verification" },
  "document.business.verification": { modal: "documents", step: "business_verification" },
  "document.individual.tin": { modal: "documents", step: "tin" }
};

export const learnMoreToLink: Record<string, string> = {
  "business.underwriting-documents-tier-one": "https://docs.moov.io/guides/accounts/underwriting/",
  "document.business.verification":
    "https://docs.moov.io/guides/accounts/capabilities/troubleshooting-capabilities/#satisfying-document-requirements",
  verification:
    "https://docs.moov.io/guides/accounts/capabilities/troubleshooting-capabilities/#satisfying-document-requirements",
  tin: "https://docs.moov.io/guides/accounts/capabilities/troubleshooting-capabilities/#satisfying-document-requirements",
  "document.individual.verification":
    "https://docs.moov.io/guides/accounts/capabilities/troubleshooting-capabilities/#satisfying-document-requirements",
  "document.individual.tin":
    "https://docs.moov.io/guides/accounts/capabilities/troubleshooting-capabilities/#satisfying-document-requirements"
};

export const isPlural = [
  "business.underwriting-documents-tier-one",
  "document.individual.verification",
  "document.business.verification",
  "document.individual.tin",
  "verification",
  "tin"
];

export const isRequiredAltMessage = [
  "document.individual.verification",
  "document.business.verification",
  "document.individual.tin",
  "verification",
  "tin",
  "business_verification"
];

export const errorCodeToMessage: Record<string, string> = {
  "invalid-value": "is invalid or could not be verified. Please ensure accuracy and try again.",
  "failed-automatic-verification":
    "could not be verified. Please check for typos and ensure information matches official records.",
  "failed-other": "could not be verified. Please ensure accuracy and try again.",
  "invalid-address":
    "could not be verified with the identity. Please ensure it is your current residential address or upload documentation.",
  "address-restricted":
    "is not accepted. Businesses need physical addresses (no PO boxes/registered agents). Persons need residential addresses.",
  "tax-id-mismatch":
    "could not be verified. Please confirm accuracy or identity fields and tax ID, or upload documentation.",
  "document-id-mismatch": "is invalid.",
  "document-date-of-birth-mismatch": "is invalid.",
  "document-name-mismatch": "is invalid.",
  "document-address.mismatch": "is invalid.",
  "document-number-mismatch": "is invalid.",
  "document-incomplete": "is invalid.",
  "document-failed-risk": "is invalid.",
  "document-illegible": "is invalid.",
  "document-unsupported": "is invalid.",
  "document-not-uploaded": "is invalid.",
  "document-corrupt": "is invalid.",
  "document-expired": "is invalid.",
  "currently-due": "is required and missing.",
  "currently-due-plural": "are required and missing."
};

export const consolidatedRequirementsToLabel: Partial<Record<RequirementSteps, string>> = {
  busContact: "Business contact",
  busDetails: "Business details",
  indvContact: "Individual contact",
  indvDetails: "Individual details",
  busStatements: "Processing statements",
  busTransactions: "Transaction details",
  list: requirementToLabel["business.controllers"],
  business_verification: "Business verification documents"
};

export const isDocumentStep = (value: string): value is DocumentRequirementSteps => {
  return DocumentRequirementSteps.includes(value as DocumentRequirementSteps);
};

export const isAccountStep = (value: string): value is AccountRequirementSteps => {
  return AccountRequirementSteps.includes(value as AccountRequirementSteps);
};

export const isRequirementStep = (value: string): value is RequirementSteps => {
  return (
    DocumentRequirementSteps.includes(value as DocumentRequirementSteps) ||
    AccountRequirementSteps.includes(value as StepID) ||
    RepresentativeSteps.includes(value) ||
    value === "account.tos-acceptance"
  );
};
