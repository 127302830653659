import { Enum } from "@moovfinancial/common/types/Enum";
import { components } from "@moovfinancial/common/types/__generated-types__/moov-api";
import { CapabilityError, Capability as LegacyCapability } from "api/v2";
import {
  consolidatedRequirementsToLabel,
  errorCodeToMessage,
  isPlural,
  isRequiredAltMessage,
  isRequirementStep,
  repRequirementToLabel,
  requirementToLabel
} from "pages/accounts/AccountDetail/capabilities/requirementMaps";
import { STEP_IDS } from "pages/accounts/AccountModals/Steps/Steps.model";

type RequirementID = components["schemas"]["RequirementID"];
type Representative = components["schemas"]["Representative"];

// TODO: replace getName with this
export const getRepresentativeName = (representative?: Representative) => {
  if (!representative) return;
  const { name } = representative;
  return `${name.firstName} ${name.lastName}`;
};

export const getConsolidatedLabel = (requirement: string) => {
  if (isRequirementStep(requirement)) {
    return consolidatedRequirementsToLabel[requirement];
  }
};

export const getRequirementLabel = (issue: CapabilitiesErrorObject) => {
  let label = `${
    requirementToLabel[issue.requirement] ??
    repRequirementToLabel[issue.requirement] ??
    getConsolidatedLabel(issue.requirement)
  } ${errorCodeToMessage[issue.errorCode]}`;
  if (isPlural.includes(issue.requirement)) {
    label = label.replace(" is ", " are ");
  }

  if (
    issue.requirement === STEP_IDS.BUS_CONTACT ||
    issue.requirement === STEP_IDS.INDV_CONTACT ||
    issue.requirement === STEP_IDS.BUS_DETAILS ||
    issue.requirement === STEP_IDS.INDV_DETAILS ||
    issue.requirement === STEP_IDS.TRANSACTIONS
  ) {
    label = label.replace(" is ", " has ").replace(".", " fields.");
  }

  if (isRequiredAltMessage.includes(issue.requirement)) {
    label = label.replace("missing or invalid", "required");
  }

  if (issue.requirement === "account.tos-acceptance") {
    label = label.replace("missing or invalid", "missing");
  }

  return label;
};

export const getRepresentativeRequirementLabel = (
  issue: RepresentativeIssueObject,
  representatives?: Representative[]
) => {
  if (!representatives) return "";
  const matchingRepresentative = representatives.find(
    (rep) => rep.representativeID === issue.representativeId
  );
  const name = getRepresentativeName(matchingRepresentative);
  if (issue.requirement.includes("document")) {
    return `${name} ${issue.type === CapabilitiesIssueKind.Error ? "has invalid" : "requires"} ${
      issue.requirement.includes("verification")
        ? "verification documents"
        : "SSN/ITIN verification documents"
    }`;
  }
  return `${name} has missing or invalid information.`;
};

export const hasErrors = (capability: LegacyCapability): boolean =>
  !!capability.requirements.errors?.length;

export const hasCurrentlyDue = (capability: LegacyCapability): boolean =>
  !!capability.requirements.currentlyDue?.length;

export const hasErrorsOrCurrentlyDue = (capability: LegacyCapability): boolean =>
  hasErrors(capability) || hasCurrentlyDue(capability);

export function requestedTransfersOnly(capabilities: LegacyCapability[] | string[]): boolean {
  return (
    capabilities.length === 1 &&
    capabilities.some((c) =>
      typeof c === "string" ? c === "transfers" : c.capability === "transfers"
    )
  );
}

export const CapabilitiesErrorObjectCurrentlyDue = {
  PLURAL: "currently-due-plural",
  SINGULAR: "currently-due"
} as const;

export type CapabilitiesErrorObjectCurrentlyDue = Enum<typeof CapabilitiesErrorObjectCurrentlyDue>;

export type CapabilitiesErrorObject = {
  requirement: string;
  errorCode: string;
};

export const CapabilitiesIssueKind = {
  Error: "errorCode",
  Due: "currentlyDue"
};

export type CapabilitiesIssueKind =
  (typeof CapabilitiesIssueKind)[keyof typeof CapabilitiesIssueKind];

export type RepresentativeIssueObject = {
  representativeId?: string;
  type: CapabilitiesIssueKind;
  requirement: string;
};

export function checkForBusinessRepRequirement(issue: CapabilitiesErrorObject) {
  let result = false;
  if (issue.requirement === "business.owners" || issue.requirement === "business.controllers") {
    result = true;
  }
  return result;
}

export function isForOwnersProvided(issue: CapabilitiesErrorObject) {
  let result = false;
  if (issue.requirement === "business.indicate-owners-provided") {
    result = true;
  }
  return result;
}
export function listErrorsOrCurrentlyDueForAccount(capabilities: LegacyCapability[]) {
  const result: CapabilitiesErrorObject[] = [];
  const seenRequirements = new Set();
  capabilities.map(({ requirements }) => {
    const currentlyDue = requirements.currentlyDue ?? [];
    const errors = requirements.errors ?? [];
    currentlyDue.forEach((currentlyDue) => {
      if (!currentlyDue.includes("representative.")) {
        const currentlyDueObject = {
          requirement: currentlyDue,
          errorCode: isPlural.includes(currentlyDue)
            ? CapabilitiesErrorObjectCurrentlyDue.PLURAL
            : CapabilitiesErrorObjectCurrentlyDue.SINGULAR
        };
        if (seenRequirements.has(currentlyDueObject.requirement)) return;
        result.push(currentlyDueObject);
        seenRequirements.add(currentlyDueObject.requirement);
      }
    });
    errors.forEach((error) => {
      if (!error.requirement.includes("representative.")) {
        if (seenRequirements.has(error.requirement)) return;
        result.push(error);
        seenRequirements.add(error.requirement);
      }
    });
  });
  return result;
}

function isCurrentlyDue(
  errorOrCurrentlyDue: string | CapabilityError
): errorOrCurrentlyDue is string {
  return typeof errorOrCurrentlyDue === "string";
}

export function listErrorsOrCurrentlyDueForRepresentatives(capabilities: LegacyCapability[]) {
  const result: RepresentativeIssueObject[] = [];
  const seenRepIds = new Set();
  const seenDocuments = new Set();
  capabilities.map(({ requirements }) => {
    const currentlyDue = requirements.currentlyDue ?? [];
    const errors = requirements.errors ?? [];
    const errorsAndCurrentlyDue: Array<CapabilityError | string> = [...currentlyDue, ...errors];
    errorsAndCurrentlyDue.forEach((errorOrCurrentlyDue) => {
      // We need to figure out if we're dealing with Errors or Currently Due object first
      const issueKind = isCurrentlyDue(errorOrCurrentlyDue)
        ? CapabilitiesIssueKind.Due
        : CapabilitiesIssueKind.Error;
      const stringToAnalyze: string = isCurrentlyDue(errorOrCurrentlyDue)
        ? errorOrCurrentlyDue
        : errorOrCurrentlyDue.requirement;
      if (stringToAnalyze.includes("representative.")) {
        const isDocument = stringToAnalyze.includes("document.");
        const requirementDetails = stringToAnalyze.split(".");
        const repId = isDocument ? requirementDetails[2] : requirementDetails[1];

        // avoid adding more than one issue per repId
        if (seenRepIds.has(repId) && !isDocument) return;
        if (seenDocuments.has(stringToAnalyze)) return;
        const issue = {
          representativeId: repId,
          type: issueKind,
          requirement: stringToAnalyze
        };
        result.push(issue);
        if (!isDocument) {
          seenRepIds.add(repId);
        } else {
          seenDocuments.add(stringToAnalyze);
        }
      }
    });
  });
  return result;
}

const isBusinessRequirement = (requirement: string) => {
  if (requirement.includes("business.")) {
    if (requirement === "business.controllers") return false;
    if (requirement === "business.indicate-owners-provided") return false;
    if (requirement === "business.owners") return false;
    return true;
  }
  return false;
};

const isIndividualRequirement = (requirement: string) => {
  if (requirement.startsWith("individual.")) return true;
  return false;
};

const isRepresentativeRequirement = (requirement: string) => {
  if (requirement.includes("representative.")) return true;
  if (requirement === "business.controllers") return true;
  if (requirement === "business.indicate-owners-provided") return true;
  if (requirement === "business.owners") return true;
  return false;
};

export function hasRepresentativeRequirement(capabilities: LegacyCapability[]) {
  const hasRepresentativeRequirement = capabilities.some(
    (capability) =>
      capability.requirements.currentlyDue?.some((due) => isRepresentativeRequirement(due)) ||
      capability.requirements.errors?.some((error) =>
        isRepresentativeRequirement(error.requirement)
      )
  );
  return hasRepresentativeRequirement;
}

export function hasAccountRequirement(capabilities: LegacyCapability[]) {
  const hasBusinessRequirement = capabilities.some(
    (capability) =>
      capability.requirements.currentlyDue?.some(
        (due) => isBusinessRequirement(due) || isIndividualRequirement(due)
      ) ||
      capability.requirements.errors?.some(
        (error) =>
          isBusinessRequirement(error.requirement) || isIndividualRequirement(error.requirement)
      )
  );
  return hasBusinessRequirement;
}

/** Returns true if there is a capability error that cannot be fixed by the user */
export function hasUnfixableError(capabilities: LegacyCapability[]): boolean {
  return capabilities.some((capability) =>
    capability.requirements.errors?.some((error) => error.requirement === "business.admins")
  );
}

/** Maps requirement error codes to corresponding fields in the Account object. */
export const requirementToAccountFieldMap: Partial<Record<RequirementID, string>> = {
  "individual.mobile": "profile.individual.phone",
  "individual.email": "profile.individual.email",
  "individual.firstname": "profile.individual.name.firstName",
  "individual.lastname": "profile.individual.name.lastName",
  "individual.address": "profile.individual.address.addressLine1",
  "individual.ssn-last4": "profile.individual.governmentID.ssn.lastFour",
  "individual.ssn": "profile.individual.governmentID.ssn.full",
  "individual.birthdate": "profile.individual.birthDate",
  "business.legalname": "profile.business.legalBusinessName",
  "business.entity-type": "profile.business.businessType",
  "business.dba": "profile.business.doingBusinessAs",
  "business.ein": "profile.business.taxID.ein.number",
  "business.address": "profile.business.address.addressLine1",
  "business.phone": "profile.business.phone.number",
  "business.classification": "profile.business.industryCodes",
  "business.industry-code-mcc": "profile.business.industryCodes",
  "business.description-or-website": "profile.business.website",
  "business.average-monthly-transaction-volume": "underwriting.averageMonthlyTransactionVolume",
  "business.average-transaction-size": "underwriting.averageTransactionSize",
  "business.max-transaction-size": "underwriting.maxTransactionSize",
  "business.description": "profile.business.description"
};

export type RepresentativeFormPathFields =
  | "phone.number"
  | "email"
  | "name.firstName"
  | "name.lastName"
  | "address"
  | "governmentID.ssn.lastFour"
  | "governmentID.ssn.full"
  | "governmentID.itin.lastFour"
  | "governmentID.itin.full"
  | "birthDate"
  | "responsibilities.jobTitle"
  | "responsibilities.isController"
  | "responsibilities.isOwner"
  | "responsibilities.ownershipPercentage";

export type RepresentativeBackEndLeafFields =
  | "mobile"
  | "email"
  | "firstname"
  | "lastname"
  | "address"
  | "ssn-last4"
  | "ssn"
  | "tin"
  | "birthdate"
  | "job-title"
  | "is-controller"
  | "is-owner"
  | "ownership"
  | "email-or-mobile";

/** Maps requirement error codes to corresponding fields in the Representative object.
 * You must remove the "representative.<repID>." substring on the requirement code to use this map */
export const requirementToRepresentativeFieldMap: Record<
  RepresentativeBackEndLeafFields,
  RepresentativeFormPathFields
> = {
  mobile: "phone.number",
  email: "email",
  firstname: "name.firstName",
  lastname: "name.lastName",
  address: "address",
  "ssn-last4": "governmentID.ssn.lastFour",
  ssn: "governmentID.ssn.full",
  birthdate: "birthDate",
  "job-title": "responsibilities.jobTitle",
  "is-controller": "responsibilities.isController",
  "is-owner": "responsibilities.isOwner",
  ownership: "responsibilities.ownershipPercentage",
  "email-or-mobile": "email",
  tin: "governmentID.itin.full"
};

/**
 * Used to translate the FE path field names to a human readable field name
 */
export const formFieldPathToSimpleFieldName: Record<RepresentativeFormPathFields, string> = {
  "phone.number": "Phone number",
  email: "Email",
  "name.firstName": "First name",
  "name.lastName": "Last name",
  address: "Address",
  "governmentID.ssn.lastFour": "Last 4 of SSN",
  "governmentID.ssn.full": "SSN",
  birthDate: "Date of birth",
  "responsibilities.jobTitle": "Job title",
  "responsibilities.isController": "Controller status",
  "responsibilities.isOwner": "Owner status",
  "responsibilities.ownershipPercentage": "Ownership percentage",
  "governmentID.itin.lastFour": "Last 4 of ITIN",
  "governmentID.itin.full": "ITIN"
};
