import type { DeepPartial } from "@moovfinancial/common/types/DeepTypes";
import { components } from "@moovfinancial/common/types/__generated-types__/moov-api";
import { DetailItem } from "components/lists/DetailItem";
import { MissingInfoWarning } from "./ReviewStep";
import styles from "./ReviewStep.module.scss";

export type CardVolumeDistribution =
  components["schemas"]["Underwriting"]["cardVolumeDistribution"];

export const VolumeMakeupDetails = ({
  cardVolumeDistribution
}: {
  cardVolumeDistribution?: DeepPartial<CardVolumeDistribution>;
}) => {
  const volumeTypes = [
    { value: cardVolumeDistribution?.ecommercePercentage, label: "eCommerce" },
    { value: cardVolumeDistribution?.mailOrPhonePercentage, label: "Mail/phone" },
    { value: cardVolumeDistribution?.cardPresentPercentage, label: "Card present" },
    { value: cardVolumeDistribution?.debtRepaymentPercentage, label: "Debit repayment" }
  ] as const;

  const hasVolume = volumeTypes.some(({ value }) => !!value);

  return (
    <DetailItem label="Volume makeup">
      {hasVolume ? (
        volumeTypes.map(
          ({ value, label }) =>
            !!value && <div className={styles.detailValue} key={label}>{`${value}% ${label}`}</div>
        )
      ) : (
        <MissingInfoWarning />
      )}
    </DetailItem>
  );
};
