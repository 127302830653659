import { clone, merge } from "remeda";
import { components } from "@moovfinancial/common/types/__generated-types__/moov-api";
import { SparsePath, setSparsePath } from "@moovfinancial/common/utils/setSparsePath";
import { BooleanAccountMap, BooleanAccountUnderwritingMap } from "api/v2";
import { requirementToAccountFieldMap } from "helpers/capabilities";

type Capability = components["schemas"]["Capability"];

const UNDERWRITING_PATHS = new Set([
  "underwriting.averageMonthlyTransactionVolume",
  "underwriting.maxTransactionSize",
  "underwriting.averageTransactionSize"
]);

export const unlockErroredFields = (
  accountLocks: BooleanAccountMap,
  underwritingLocks: BooleanAccountUnderwritingMap,
  capabilities: Capability[]
): {
  newAccountLocks: BooleanAccountMap;
  newUnderwritingLocks: BooleanAccountUnderwritingMap;
} => {
  let newAccountLocks = clone(accountLocks);
  let newUnderwritingLocks = clone(underwritingLocks);
  const accountErrors: string[] = [];

  capabilities.forEach((capability) => {
    capability.requirements?.errors?.forEach((error) => {
      const errorPath = error.requirement && requirementToAccountFieldMap[error.requirement];
      if (errorPath) accountErrors.push(errorPath);
    });
  });

  accountErrors.forEach((errorPath) => {
    if (UNDERWRITING_PATHS.has(errorPath)) {
      // Note: we don't want to use setSparsePath for UNDERWRITING_PATHS because the fields should only be one level deep
      // i.e., we want updated underwriting locks like { averageMonthlyTransactionVolume: false }, not { underwriting: { averageMonthlyTransactionVolume: false } }
      const key = errorPath.split(".")[1];
      newUnderwritingLocks = merge(newUnderwritingLocks, {
        [key]: false
      });
    } else {
      newAccountLocks = setSparsePath(
        newAccountLocks,
        errorPath.split(".") as SparsePath<BooleanAccountMap>,
        false
      );
    }
  });

  return { newAccountLocks, newUnderwritingLocks };
};

export const unlockCurrentlyDueFields = (
  accountLocks: BooleanAccountMap,
  underwritingLocks: BooleanAccountUnderwritingMap,
  capabilities: Capability[]
): {
  newAccountLocks: BooleanAccountMap;
  newUnderwritingLocks: BooleanAccountUnderwritingMap;
} => {
  let newAccountLocks = clone(accountLocks);
  let newUnderwritingLocks = clone(underwritingLocks);
  const accountErrors: string[] = [];

  capabilities.forEach((capability) => {
    capability.requirements?.currentlyDue?.forEach((due) => {
      const errorPath = requirementToAccountFieldMap[due];
      if (errorPath) accountErrors.push(errorPath);
    });
  });

  accountErrors.forEach((errorPath) => {
    if (UNDERWRITING_PATHS.has(errorPath)) {
      // Note: we don't want to use setSparsePath for UNDERWRITING_PATHS because the fields should only be one level deep
      // i.e., we want updated underwriting locks like { averageMonthlyTransactionVolume: false }, not { underwriting: { averageMonthlyTransactionVolume: false } }
      const key = errorPath.split(".")[1];
      newUnderwritingLocks = merge(newUnderwritingLocks, {
        [key]: false
      });
    } else {
      newAccountLocks = setSparsePath(
        newAccountLocks,
        errorPath.split(".") as SparsePath<BooleanAccountMap>,
        false
      );
    }
  });

  return { newAccountLocks, newUnderwritingLocks };
};
