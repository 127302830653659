import { useContext } from "react";
import { useLocation } from "react-router";
import { FacilitatorContext } from "contexts/FacilitatorContext";
import { UserContext } from "contexts/UserContext";

/**
 * A hook to immediately return the facilitator ID from URL parameters if the current user is a Moov super user.
 *
 * This allows components that are dependant on facilitator ID changes to mount and make API calls without awaiting the facilitator ID rewrite from useRouteBasedAccounts.
 */
export const useLocationFacilitatorID = () => {
  const currentLocation = useLocation();
  const { facilitatorID: currentFacilitatorID } = useContext(FacilitatorContext);
  const { isSuper } = useContext(UserContext);

  const searchParams = new URLSearchParams(currentLocation.search);
  const urlFacilitatorID = isSuper ? searchParams.get("facilitator-id") : null;
  return urlFacilitatorID ?? currentFacilitatorID;
};
