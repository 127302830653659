import { Chance } from "chance";
import { clone } from "remeda";
import {
  AdminBulkDisputeUpdateResponse,
  AdminDispute,
  AdminDisputeEnriched,
  type AdminDisputeStatus,
  Dispute,
  DisputeEnriched,
  DisputePhase,
  DisputeStatus,
  adminDisputeStatusMap,
  disputeStatusMap
} from "api/v2";

const chance = new Chance();

/**
 * @deprecated Should use dynamic mocks in the future;
 */
export const mockDispute: Dispute = {
  disputeID: "ec7e1848-dc80-4ab0-8827-dd7fc0737b43",
  createdOn: "2021-09-21T18:53:04Z",
  respondBy: "2021-09-25T18:53:04Z",
  networkReasonCode: "10.3",
  networkReasonDescription: "Fraudulent transaction; no cardholder authorization",
  transfer: {
    transferID: "ec7e1848-dc80-4ab0-8827-dd7fc0737b43"
  },
  amount: {
    currency: "USD",
    value: 1202
  }
};

export const mockLostDispute = {
  ...mockDispute,
  phase: "chargeback" as DisputePhase,
  status: "lost" as DisputeStatus
};

export const mockDisputeEnrichedList = () => {
  const mockedDisputes: DisputeEnriched[] = [];
  for (let i = 0; i < 18; i += 1) {
    const disputeItem = clone(mockDispute) as DisputeEnriched;
    disputeItem.disputeID = chance.guid();
    disputeItem.status = chance.pickone(Object.keys(disputeStatusMap)) as DisputeStatus;
    disputeItem.phase = chance.pickone(["inquiry", "chargeback"]);
    disputeItem.amount.value = chance.integer({ min: 100, max: 2000 });
    disputeItem.displayName = chance.name();
    disputeItem.respondBy =
      disputeItem.status === "response-needed" ? chance.date().toISOString() : undefined;
    mockedDisputes.push(disputeItem);
  }
  return mockedDisputes;
};

export const mockAdminDisputeEnrichedList = () => {
  const mockedDisputes: AdminDisputeEnriched[] = [];
  for (let i = 0; i < 18; i += 1) {
    const disputeItem = clone(mockDispute) as AdminDisputeEnriched;
    disputeItem.amount.value = chance.integer({ min: 100, max: 2000 });
    disputeItem.brand = chance.pickone(["American Express", "Discover", "Visa", "Mastercard"]);
    disputeItem.callingAccountID = chance.guid();
    disputeItem.caseNumber = chance.guid();
    disputeItem.displayName = chance.name();
    disputeItem.disputeID = chance.guid();
    disputeItem.facilitator = chance.name();
    disputeItem.phase = chance.pickone(["inquiry", "chargeback"]);
    disputeItem.status = chance.pickone(Object.keys(adminDisputeStatusMap)) as AdminDisputeStatus;
    disputeItem.submittedOn = chance.date().toISOString();
    mockedDisputes.push(disputeItem);
  }
  return mockedDisputes;
};

/**
 * @deprecated Should use DisputeEnriched type, to be removed with PERK-649;
 */
export const mockDisputeList: Dispute[] = [];
for (let i = 0; i < 18; i += 1) {
  const disputeItem = clone(mockDispute);
  disputeItem.disputeID = chance.guid();
  disputeItem.status = chance.pickone([
    "",
    "resolved",
    "response-needed",
    "bad-status"
  ]) as DisputeStatus;
  disputeItem.amount.value = chance.integer({ min: 100, max: 2000 });
  mockDisputeList.push(disputeItem);
}

/**
 * @deprecated Should use dynamic mocks in the future;
 */
export const mockAdminDispute: AdminDispute = {
  ...mockDispute,
  callingAccountID: "ec7e1848-dc80-4ab0-8827-dd7fc0737b43",
  merchantAccountID: "ec7e1848-dc80-4ab0-8827-dd7fc0737b43",
  caseNumber: "1234",
  moovResponseDueDate: "2021-09-26T18:53:04Z"
} as AdminDispute;

export const mockEnrichedAdminDispute: AdminDisputeEnriched = {
  ...mockAdminDispute,
  displayName: "Fake display name",
  facilitator: "Test facilitator"
};

export const mockAdminDisputeList: AdminDispute[] = [];
for (let i = 0; i < 18; i += 1) {
  const disputeItem = clone(mockAdminDispute);
  disputeItem.disputeID = chance.guid();
  disputeItem.status = chance.pickone([
    "",
    "resolved",
    "response-needed",
    "bad-status"
  ]) as AdminDisputeStatus;
  disputeItem.amount.value = chance.integer({ min: 100, max: 200000 });
  disputeItem.callingAccountID = chance.guid();
  disputeItem.merchantAccountID = chance.guid();
  mockAdminDisputeList.push(disputeItem);
}

export const mockAdminBulkUpdateResponse: AdminBulkDisputeUpdateResponse = {
  successes: [],
  errors: [
    {
      disputeID: chance.guid(),
      status: "closed"
    }
  ]
};
