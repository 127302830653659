import type { Representative } from "./AddOwner";

/**
 * Calculates the remaining ownership percentage available for a representative.
 * @param representatives - The full list of representatives.
 * @param currentRepresentativeID - The ID of the representative being added.
 * @returns The available ownership percentage.
 */
export const calculateAvailableOwnership = (
  representatives: Representative[],
  currentRepresentativeID?: string
): number => {
  const otherOwnersTotal = representatives
    .filter((rep) => rep.representativeID !== currentRepresentativeID)
    .reduce((sum, rep) => sum + (rep.responsibilities?.ownershipPercentage ?? 0), 0);

  return 100 - otherOwnersTotal;
};
