import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { To, useBeforeUnload, useBlocker, useLocation, useParams } from "react-router";
import { isDeepEqual } from "remeda";
import { UnsavedChangesModal } from "@moovfinancial/cargo";
import { NavigationStep } from "@moovfinancial/cargo/src/components/Complex/Stepper/step.model";
import { FieldMeta } from "@moovfinancial/cargo/src/hooks/useValidatedFields";
import type { DeepPartial } from "@moovfinancial/common/types/DeepTypes";
import { components } from "@moovfinancial/common/types/__generated-types__/moov-api";
import { isFinancialInstitution } from "@moovfinancial/common/utils/Industry";
import {
  BankAccountFormState,
  mapAccountToBankAccountFormState
} from "pages/onboarding/bank-account/BankAccountForm";
import {
  CompanyAddressFormState,
  mapAccountToAddressFormState
} from "pages/onboarding/company/CompanyAddress";
import {
  CompanyInfoFormState,
  mapAccountToCompanyInfoFormState
} from "pages/onboarding/company/CompanyInformation";
import {
  FulfillmentFormState,
  mapUnderwritingToFormState
} from "pages/onboarding/expected-activity/Fulfillment";
import {
  TransactionsFormState,
  mapUnderwritingToTransactionsFormState
} from "pages/onboarding/expected-activity/Transactions";
import {
  AddOwnerFormState,
  DEFAULT_ADD_OWNER_FORM_STATE,
  type Representative,
  mapRepresentativeToFormState
} from "pages/onboarding/owners/AddOwner";
import {
  ReviewOwnersFormState,
  mapRepresentativeToReviewOwnersFormState
} from "pages/onboarding/owners/ReviewOwners";
import { Account, OnboardingContext, Underwriting } from "./OnboardingContext";
import { OnboardingInviteContext } from "./OnboardingInviteContext";
import {
  ONBOARDING_PATH_WITH_SUB_STEP,
  ValidatedBankAccountFields,
  ValidatedCompanyAddressFields,
  ValidatedCompanyInfoFields,
  ValidatedOwnerFields,
  ValidatedTransactionsFields,
  areBusinessCapabilities,
  findCurrentStep,
  getStepNavigation,
  getValidatedBankAccountFields,
  getValidatedCompanyAddressFields,
  getValidatedCompanyInfoFields,
  getValidatedOwnerFields,
  getValidatedTransactionsFields,
  isAtFinishStep,
  isBankAccountsComplete,
  isBusinessAddressComplete,
  isBusinessInfoComplete,
  isProcessingStatementsComplete,
  isRepresentativesComplete,
  isUnderwritingComplete
} from "./OnboardingStepsContext.utils";

export type BankAccount = components["schemas"]["BankAccount"];
export type FileDetails = components["schemas"]["FileDetails"];
export type StepNavigation = Readonly<Record<string, { next: To; previous: To }>>;
export type FormFields<T extends string> = Record<T, Partial<FieldMeta>>;

interface OnboardingStepsContextProps {
  children: React.ReactNode;
}

interface OnboardingSteps {
  bankAccountStep: {
    formState: BankAccountFormState;
    formFields: FormFields<ValidatedBankAccountFields>;
    setFormState: Dispatch<SetStateAction<BankAccountFormState>>;
  };
  companyAddressStep: {
    formState: CompanyAddressFormState;
    formFields: FormFields<ValidatedCompanyAddressFields>;
    setFormState: Dispatch<SetStateAction<CompanyAddressFormState>>;
  };
  companyInfoStep: {
    formState: CompanyInfoFormState;
    formFields: FormFields<ValidatedCompanyInfoFields>;
    setFormState: Dispatch<SetStateAction<CompanyInfoFormState>>;
  };
  ownersStep: {
    formFields: FormFields<ValidatedOwnerFields>;
    formState: AddOwnerFormState;
    representative: Representative | undefined;
    setFormState: Dispatch<SetStateAction<AddOwnerFormState>>;
    setRepresentative: Dispatch<SetStateAction<Representative | undefined>>;
  };
  transactionsStep: {
    formState: TransactionsFormState;
    formFields: FormFields<ValidatedTransactionsFields>;
    setFormState: Dispatch<SetStateAction<TransactionsFormState>>;
  };
  fulfillmentStep: {
    formState: FulfillmentFormState;
    setFormState: Dispatch<SetStateAction<FulfillmentFormState>>;
  };
  reviewOwnersStep: {
    formState: ReviewOwnersFormState;
    setFormState: Dispatch<SetStateAction<ReviewOwnersFormState>>;
  };
  getNextStepUrl: () => To;
  getPreviousStepUrl: () => To;
  hasExpectedActivityStep: () => boolean;
  hasFulfillmentStep: () => boolean;
  hasOwnersStep: () => boolean;
  isComplete: boolean;
  steps: NavigationStep[];
  setRenderFormValidated: Dispatch<SetStateAction<boolean>>;
  renderFormValidated: boolean;
}

export const OnboardingStepsContext = createContext<OnboardingSteps>({
  bankAccountStep: {
    formState: {} as BankAccountFormState,
    formFields: {} as FormFields<ValidatedBankAccountFields>,
    setFormState: () => {}
  },
  companyAddressStep: {
    formState: {} as CompanyAddressFormState,
    formFields: {} as FormFields<ValidatedCompanyAddressFields>,
    setFormState: () => {}
  },
  companyInfoStep: {
    formState: {} as CompanyInfoFormState,
    formFields: {} as FormFields<ValidatedCompanyInfoFields>,
    setFormState: () => {}
  },
  ownersStep: {
    formState: DEFAULT_ADD_OWNER_FORM_STATE,
    formFields: {} as FormFields<ValidatedOwnerFields>,
    representative: undefined,
    setFormState: () => {},
    setRepresentative: () => {}
  },
  transactionsStep: {
    formState: {} as TransactionsFormState,
    formFields: {} as FormFields<ValidatedTransactionsFields>,
    setFormState: () => {}
  },
  fulfillmentStep: {
    formState: {} as FulfillmentFormState,
    setFormState: () => {}
  },
  reviewOwnersStep: {
    formState: {} as ReviewOwnersFormState,
    setFormState: () => {}
  },
  getNextStepUrl: () => "/",
  getPreviousStepUrl: () => "/",
  hasExpectedActivityStep: () => false,
  hasFulfillmentStep: () => false,
  hasOwnersStep: () => false,
  isComplete: false,
  steps: [],
  renderFormValidated: false,
  setRenderFormValidated: () => {}
});

export interface LocationState {
  renderFormValidated?: boolean;
  skipUnsavedChangesCheck?: boolean;
}

export interface OnboardingLocation {
  state: LocationState;
}

export function OnboardingStepsContextProvider({ children }: OnboardingStepsContextProps) {
  const { invite } = useContext(OnboardingInviteContext);
  const { account, bankAccounts, files, certifiedNoStatements, representatives, underwriting } =
    useContext(OnboardingContext);
  const currentLocation = useLocation();
  const { token } = useParams();

  const [renderFormValidated, setRenderFormValidated] = useState(false);
  const [representative, setRepresentative] = useState<Representative | undefined>();
  const [stepNavigation, setStepNavigation] = useState<StepNavigation>({});
  const [steps, setSteps] = useState<NavigationStep[]>([]);

  ///// FORM STATES FOR EACH ONBOARDING STEP /////
  // Stored here for validation purposes, so we have a single source of truth for onboarding completion
  const [addOwnerFormState, setAddOwnerFormState] = useState<AddOwnerFormState>(
    DEFAULT_ADD_OWNER_FORM_STATE
  );
  const [bankAccountFormState, setBankAccountFormState] = useState<BankAccountFormState>(
    mapAccountToBankAccountFormState(account)
  );
  const [companyAddressFormState, setCompanyAddressFormState] = useState<CompanyAddressFormState>(
    mapAccountToAddressFormState(account)
  );
  const [companyInfoFormState, setCompanyInfoFormState] = useState<CompanyInfoFormState>(
    mapAccountToCompanyInfoFormState(account)
  );
  const [fulfillmentFormState, setFulfillmentFormState] = useState<FulfillmentFormState>(
    mapUnderwritingToFormState(underwriting)
  );
  const [reviewOwnersFormState, setReviewOwnersFormState] = useState<ReviewOwnersFormState>(
    mapRepresentativeToReviewOwnersFormState(account)
  );
  const [transactionsFormState, setTransactionsFormState] = useState<TransactionsFormState>(
    mapUnderwritingToTransactionsFormState(underwriting)
  );

  // Memoizes initial form states to compare against when checking for unsaved changes
  const initialFormStates = useMemo(
    () => ({
      bankAccount: mapAccountToBankAccountFormState(account),
      companyAddress: mapAccountToAddressFormState(account),
      companyInfo: mapAccountToCompanyInfoFormState(account),
      fulfillment: mapUnderwritingToFormState(underwriting),
      owner: mapRepresentativeToFormState(representative),
      transactions: mapUnderwritingToTransactionsFormState(underwriting)
    }),
    [account, representative, underwriting]
  );

  // Checks for form changes whenever form states update
  const hasUnsavedChanges = useMemo(() => {
    return (
      !isDeepEqual(addOwnerFormState, initialFormStates.owner) ||
      !isDeepEqual(bankAccountFormState, initialFormStates.bankAccount) ||
      !isDeepEqual(companyAddressFormState, initialFormStates.companyAddress) ||
      !isDeepEqual(companyInfoFormState, initialFormStates.companyInfo) ||
      !isDeepEqual(fulfillmentFormState, initialFormStates.fulfillment) ||
      !isDeepEqual(transactionsFormState, initialFormStates.transactions)
    );
  }, [
    addOwnerFormState,
    bankAccountFormState,
    companyAddressFormState,
    companyInfoFormState,
    fulfillmentFormState,
    initialFormStates,
    transactionsFormState
  ]);

  // Blocks navigation if there are unsaved changes
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname &&
      ONBOARDING_PATH_WITH_SUB_STEP.test(currentLocation.pathname) &&
      hasUnsavedChanges &&
      !(nextLocation.state as LocationState)?.skipUnsavedChangesCheck
  );

  // Uses native browser warning to alert the user if they try to leave/close the page with unsaved changes
  useBeforeUnload((e) => {
    if (hasUnsavedChanges) {
      e.preventDefault();
      return "Are you sure you want to leave this page? Changes you made will not be saved.";
    }
  });

  // Keeps all forms related to the account in sync when changes are made to the account
  useEffect(() => {
    setBankAccountFormState(mapAccountToBankAccountFormState(account));
    setCompanyInfoFormState(mapAccountToCompanyInfoFormState(account));
    setCompanyAddressFormState(mapAccountToAddressFormState(account));
    setReviewOwnersFormState(mapRepresentativeToReviewOwnersFormState(account));
  }, [account]);

  // Keeps all forms related to the underwriting in sync when changes are made to underwriting
  useEffect(() => {
    setTransactionsFormState(mapUnderwritingToTransactionsFormState(underwriting));
    setFulfillmentFormState(mapUnderwritingToFormState(underwriting));
  }, [underwriting]);

  // Keeps the owner form in sync with the representative when changes are made to representatives
  useEffect(() => {
    setAddOwnerFormState(mapRepresentativeToFormState(representative));
  }, [representative]);

  const onboardingPath = useMemo(() => `/onboarding/${token}`, [token]);

  const getCurrentStepNavigation = useCallback(() => {
    if (!steps) return;
    const currentStep = findCurrentStep(steps, currentLocation.pathname);
    if (!currentStep) return;
    const stepKey =
      typeof currentStep.to === "string" ? currentStep.to : (currentStep.to?.pathname ?? "");
    return stepNavigation[stepKey];
  }, [currentLocation.pathname, stepNavigation, steps]);

  const getNextStepUrl = useCallback(
    () => getCurrentStepNavigation()?.next ?? onboardingPath,
    [getCurrentStepNavigation, onboardingPath]
  );

  const getPreviousStepUrl = useCallback(
    () => getCurrentStepNavigation()?.previous ?? onboardingPath,
    [getCurrentStepNavigation, onboardingPath]
  );

  const hasOwnersStep = useCallback(
    () =>
      areBusinessCapabilities(invite?.capabilities ?? []) &&
      !isFinancialInstitution(
        companyInfoFormState.industryCodes ?? account.profile?.business?.industryCodes
      ),
    [
      invite?.capabilities,
      companyInfoFormState.industryCodes,
      account.profile?.business?.industryCodes
    ]
  );

  const hasExpectedActivityStep = useCallback(
    () => areBusinessCapabilities(invite?.capabilities ?? []),
    [invite?.capabilities]
  );

  const hasFulfillmentStep = useCallback(
    () => (invite?.capabilities ?? []).includes("collect-funds"),
    [invite?.capabilities]
  );

  //// REQUIRED FIELDS FOR FORM STEPS ////
  const companyInfoFields = useMemo(
    () =>
      getValidatedCompanyInfoFields(
        companyInfoFormState,
        hasExpectedActivityStep(),
        // Checks the form state first so required fields update in real time when the user changes the industry code
        isFinancialInstitution(
          companyInfoFormState.industryCodes ?? account.profile?.business?.industryCodes
        )
      ),
    [companyInfoFormState, hasExpectedActivityStep, account.profile?.business?.industryCodes]
  );

  const companyAddressFields = useMemo(
    () => getValidatedCompanyAddressFields(companyAddressFormState),
    [companyAddressFormState]
  );

  const transactionsFields = useMemo(() => getValidatedTransactionsFields(), []);

  //// COMPLETION STATES ////
  const businessComplete = useMemo(
    () =>
      isBusinessInfoComplete(account, companyInfoFields) &&
      isBusinessAddressComplete(account, companyAddressFields),
    [account, companyInfoFields, companyAddressFields]
  );

  ///// FORM STEPS /////
  const companyAddressStep = useMemo(
    () => ({
      formState: companyAddressFormState,
      formFields: companyAddressFields,
      setFormState: setCompanyAddressFormState
    }),
    [companyAddressFormState, companyAddressFields, setCompanyAddressFormState]
  );

  const companyInfoStep = useMemo(
    () => ({
      formState: companyInfoFormState,
      formFields: companyInfoFields,
      setFormState: setCompanyInfoFormState
    }),
    [companyInfoFormState, companyInfoFields, setCompanyInfoFormState]
  );

  const ownersStep = useMemo(
    () => ({
      formState: addOwnerFormState,
      formFields: getValidatedOwnerFields(addOwnerFormState),
      representative: representative,
      setFormState: setAddOwnerFormState,
      setRepresentative: setRepresentative
    }),
    [addOwnerFormState, representative, setAddOwnerFormState, setRepresentative]
  );

  const transactionsStep = useMemo(
    () => ({
      formState: transactionsFormState,
      formFields: transactionsFields,
      setFormState: setTransactionsFormState
    }),
    [transactionsFormState, transactionsFields, setTransactionsFormState]
  );

  const bankAccountStep = useMemo(
    () => ({
      formState: bankAccountFormState,
      formFields: getValidatedBankAccountFields(),
      setFormState: setBankAccountFormState
    }),
    [bankAccountFormState, setBankAccountFormState]
  );

  const fulfillmentStep = useMemo(
    () => ({
      formState: fulfillmentFormState,
      setFormState: setFulfillmentFormState
    }),
    [fulfillmentFormState, setFulfillmentFormState]
  );

  const reviewOwnersStep = useMemo(
    () => ({
      formState: reviewOwnersFormState,
      setFormState: setReviewOwnersFormState
    }),
    [reviewOwnersFormState, setReviewOwnersFormState]
  );

  const areAllStepsComplete = useCallback(
    (
      account: DeepPartial<Account>,
      bankAccounts: BankAccount[],
      files: FileDetails[],
      certifiedNoStatements: boolean,
      representatives: Representative[],
      underwriting: DeepPartial<Underwriting>
    ) => {
      const bankAccountComplete = isBankAccountsComplete(bankAccounts);
      const processingStatementsCompleteOrNotRequired = hasExpectedActivityStep()
        ? isProcessingStatementsComplete(files, certifiedNoStatements)
        : true;
      const representativesCompleteOrNotRequired = hasOwnersStep()
        ? isRepresentativesComplete(account, representatives)
        : true;
      const underwritingCompleteOrNotRequired = hasExpectedActivityStep()
        ? isUnderwritingComplete(underwriting, transactionsFields)
        : true;

      return (
        bankAccountComplete &&
        businessComplete &&
        processingStatementsCompleteOrNotRequired &&
        representativesCompleteOrNotRequired &&
        underwritingCompleteOrNotRequired
      );
    },
    [businessComplete, hasExpectedActivityStep, hasOwnersStep, transactionsFields]
  );

  useEffect(() => {
    setStepNavigation(
      getStepNavigation(
        onboardingPath,
        invite?.capabilities ?? [],
        isFinancialInstitution(
          companyInfoFormState.industryCodes ?? account.profile?.business?.industryCodes
        )
      )
    );
  }, [
    invite,
    onboardingPath,
    companyInfoFormState.industryCodes,
    account.profile?.business?.industryCodes
  ]);

  useEffect(() => {
    const settableSteps: NavigationStep[] = [
      {
        Component: "Business",
        to: `${onboardingPath}/business`,
        children: [
          {
            Component: "Business information",
            to: `${onboardingPath}/business/information`
          },
          { Component: "Business address", to: `${onboardingPath}/business/address` }
        ],
        isComplete: businessComplete
      }
    ];

    if (invite?.capabilities) {
      // determine which steps to show based on capabilities
      if (hasOwnersStep()) {
        // if the account is a financial institution, don't ask for owners & officers
        // Checks the form state first so the step is updated in real time when the user changes the industry code
        if (
          !isFinancialInstitution(
            companyInfoFormState.industryCodes ?? account.profile?.business?.industryCodes
          )
        ) {
          settableSteps.push({
            Component: "Owners & officers",
            isComplete: isRepresentativesComplete(account, representatives),
            to: `${onboardingPath}/owners`
          });
        }
      }

      if (hasExpectedActivityStep()) {
        const expectedActivityStep: NavigationStep = {
          Component: "Expected activity",
          to: `${onboardingPath}/expected-activity`,
          children: [
            {
              Component: "Transactions",
              to: `${onboardingPath}/expected-activity/transactions`
            },
            {
              Component: "Processing statements",
              to: `${onboardingPath}/expected-activity/processing-statements`
            }
          ],
          isComplete:
            isUnderwritingComplete(underwriting, transactionsFields) &&
            isProcessingStatementsComplete(files, certifiedNoStatements)
        };

        if (hasFulfillmentStep()) {
          const fulfillmentStep = {
            Component: "Fulfillment",
            to: `${onboardingPath}/expected-activity/fulfillment`
          };
          // insert fulfillment step within expected activity (will assign an array to expectedActivityStep.children if it doesn't exist)
          (expectedActivityStep.children ||= []).splice(1, 0, fulfillmentStep);
        }
        settableSteps.push(expectedActivityStep);
      }
    }
    // Bank account & review step are always included
    settableSteps.push(
      {
        Component: "Bank account",
        isComplete: isBankAccountsComplete(bankAccounts),
        to: `${onboardingPath}/bank-account`
      },
      {
        Component: "Review & finish",
        isComplete: isAtFinishStep(currentLocation.pathname),
        to: `${onboardingPath}/review`
      }
    );

    // replacing the entire array of steps. we don't expect capabilities to change once we get the invite
    setSteps(settableSteps);
  }, [
    account,
    businessComplete,
    bankAccounts,
    certifiedNoStatements,
    companyAddressFields,
    companyInfoFields,
    currentLocation.pathname,
    files,
    hasFulfillmentStep,
    hasOwnersStep,
    invite?.capabilities,
    onboardingPath,
    representatives,
    transactionsFields,
    underwriting
  ]);

  const isComplete = useMemo(
    () =>
      areAllStepsComplete(
        account,
        bankAccounts,
        files,
        certifiedNoStatements,
        representatives,
        underwriting
      ),
    [
      account,
      areAllStepsComplete,
      bankAccounts,
      certifiedNoStatements,
      files,
      representatives,
      underwriting
    ]
  );

  const value = useMemo<OnboardingSteps>(
    () => ({
      bankAccountStep,
      companyAddressStep,
      companyInfoStep,
      ownersStep,
      transactionsStep,
      fulfillmentStep,
      reviewOwnersStep,
      getNextStepUrl,
      getPreviousStepUrl,
      hasExpectedActivityStep,
      hasFulfillmentStep,
      hasOwnersStep,
      isComplete,
      steps,
      renderFormValidated,
      setRenderFormValidated
    }),
    [
      bankAccountStep,
      companyAddressStep,
      companyInfoStep,
      ownersStep,
      transactionsStep,
      fulfillmentStep,
      reviewOwnersStep,
      getNextStepUrl,
      getPreviousStepUrl,
      hasExpectedActivityStep,
      hasFulfillmentStep,
      hasOwnersStep,
      isComplete,
      steps,
      renderFormValidated,
      setRenderFormValidated
    ]
  );

  return (
    <OnboardingStepsContext.Provider value={value}>
      {children}
      <UnsavedChangesModal
        cancelButtonText="Stay on page"
        confirmButtonText="Leave"
        isOpen={blocker.state === "blocked"}
        onClose={() => blocker.reset && blocker.reset()}
        onConfirm={() => blocker.proceed && blocker.proceed()}
      />
    </OnboardingStepsContext.Provider>
  );
}
