import { isDeepEqual, isPlainObject } from "remeda";
import pruneObject from "@moovfinancial/common/utils/pruneObject";

/**
 * Recursively computes the diff between two objects.
 *
 * The diff object contains:
 * - Keys that exist in the revised object but not in the original, with their values.
 * - Keys that exist in both objects but whose values differ. For nested objects, the diff is computed recursively.
 * - Keys that exist in the original but are missing in the revised object are marked with "" (empty string) if the original values were strings,
 *   otherwise, the property is not included in the diff.
 *
 * The revised value always takes precedence in the diff.
 *
 * @param original - The original object.
 * @param revised - The revised object.
 * @returns An object representing the differences between the original and revised objects.
 */
export function deepDiff<O extends Record<string, unknown>, R extends Record<string, unknown>>(
  original: O,
  revised: R
): O {
  const diff: Record<string, unknown> = {};
  const keys = new Set([...Object.keys(original), ...Object.keys(revised)]);

  for (const key of keys) {
    if (key in revised) {
      if (!(key in original)) {
        // Newly added property.
        diff[key] = revised[key];
      } else {
        const origVal = original[key];
        const revVal = revised[key];

        if (isPlainObject(origVal) && isPlainObject(revVal)) {
          // Recursively diff nested objects.
          const nestedDiff = deepDiff(
            origVal as Record<string, unknown>,
            revVal as Record<string, unknown>
          );
          if (Object.keys(nestedDiff).length > 0) {
            diff[key] = nestedDiff;
          }
        } else {
          // If values differ (using Remeda's deep equality), use the revised value.
          if (!isDeepEqual(origVal, revVal)) {
            diff[key] = revVal;
          }
        }
      }
    } else {
      // Property exists in original but was deleted in revised.
      if (typeof original[key] === "string") {
        diff[key] = "";
      } else {
        diff[key] = undefined;
      }
    }
  }

  // Prune undefined values
  return pruneObject(diff, (_key, value: unknown) => value === undefined) as O;
}

export default deepDiff;
