/**
 * Singleton OpenAPI client factory.
 */
import createOpenApiClient, { Middleware } from "openapi-fetch";
import createOpenApiReactClient from "openapi-react-query";
import type { paths } from "@moovfinancial/common/types/__generated-types__/moov-api";
import type { paths as opPaths } from "@moovfinancial/common/types/__generated-types__/operations-api";

export type { Middleware };

type PathType = paths & opPaths;

const DEFAULT_API_BASE_PATH = "/api";
const MOOV_API_HEADER = "x-moov-version";

// Private variables to hold the current openApi and openApiReactClient instances
let _openApi: ReturnType<typeof createOpenApiClient<PathType>>;
let _$api: ReturnType<typeof createOpenApiReactClient<PathType>>;

type OpenApiClientOptions = {
  /**
   *
   * The default baseUrl for the OpenAPI client
   *
   * @default "/api"
   */
  baseUrl?: string;
  /**
   *
   * Optionally pass additional Middlewares to apply to the client
   *
   * @default []
   */
  middlewares?: Middleware[];
  /**
   *
   * Forces the client to be recreated with the new options instead of returning the existing instance
   *
   * @default false
   */
  forceRecreate?: boolean;
};

/**
 *  Middlewares
 */

const removeUndefinedMiddleware: Middleware = {
  async onRequest({ request }: { request: Request }) {
    // If not on POST, PUT, or PATCH, do nothing
    if (!["POST", "PUT", "PATCH"].includes(request.method)) {
      return request;
    }

    try {
      // Clone the request to get the body
      const clonedRequest = request.clone();
      const bodyParams = (await clonedRequest.json()) as Record<string, unknown>;

      // Remove null or undefined fields from body
      Object.keys(bodyParams).forEach((key) => {
        if (bodyParams[key] === undefined) {
          delete bodyParams[key];
        }
      });

      // Create new request with cleaned body
      return new Request(request.url, {
        method: request.method,
        headers: request.headers,
        body: JSON.stringify(bodyParams)
      });
    } catch (_e) {
      // If the body is not JSON, do nothing
      return request;
    }
  }
};

/**
 * Adds the API version to the request headers for both internal and public APIs, if defined in the .env file at the root of the project
 */
const addVersionHeaderMiddleware: Middleware = {
  onRequest({ request }: { request: Request }) {
    const internalVersion = import.meta.env.VITE_OPERATIONS_API_VERSION as unknown;
    const publicVersion = import.meta.env.VITE_PUBLIC_API_VERSION as unknown;
    if (
      !!request.url.match("/api/ops/") &&
      internalVersion &&
      typeof internalVersion === "string" &&
      internalVersion !== ""
    ) {
      request.headers.set(MOOV_API_HEADER, internalVersion);
    } else if (publicVersion && typeof publicVersion === "string" && publicVersion !== "") {
      request.headers.set(MOOV_API_HEADER, publicVersion);
    }
    return request;
  }
};

/**
 * Singleton that Gets/Creates an OpenAPI client with the passed options
 *
 * If the `openApiClient` instance does not already exist, it will be created and stored in the local variable so
 * subsequent calls to this function will return the same instance.
 *
 * 🔵 This is, and should be, the only way to get the OpenAPI client in the whole codebase, otherwise we can't guarantee when the first instantiation will happen, leading to Mocks not working
 */
export const getOpenApiClient = (options: OpenApiClientOptions = {}) => {
  const { baseUrl = DEFAULT_API_BASE_PATH, middlewares = [], forceRecreate = false } = options;

  if (_openApi && !forceRecreate) {
    return _openApi;
  }

  _openApi = createOpenApiClient<PathType>({ baseUrl });
  _$api = createOpenApiReactClient<PathType>(_openApi);

  _openApi.use(removeUndefinedMiddleware);
  _openApi.use(addVersionHeaderMiddleware);
  middlewares.forEach((middleware) => _openApi.use(middleware));
  return _openApi;
};

/**
 * Gets/Creates a React OpenAPI client.
 *
 * If the `$api` instance is not already created, it will be created and stored in the local variable so
 * subsequent calls to this function will return the same instance.
 *
 */
export const getOpenApiReactClient = () => {
  if (_$api) {
    return _$api;
  }

  _$api = createOpenApiReactClient<PathType>(getOpenApiClient());
  return _$api;
};

/**
 * Gets/Creates both OpenAPI clients.
 *
 * Usage:
 *
 * const { openApi, $api } = getOpenApiClients();
 *
 */
export const getOpenApiClients = () => {
  return { openApi: getOpenApiClient(), $api: getOpenApiReactClient() };
};

export const _test = {
  addVersionHeaderMiddleware,
  removeUndefinedMiddleware
};
