"use client";

import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { FormGroup } from "../FormGroup";
import styles from "./Select.module.scss";

interface BaseSelectProps extends React.InputHTMLAttributes<HTMLSelectElement> {
  /**
   * Options to show in the select in the format of {value: label}
   *
   * Note that this will override the `children` prop
   */
  options?: Record<string, string>;
  /**
   * OnChange hook that triggers when a new option is selected
   */
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  /**
   * Error message to display under the input
   */
  error?: string;
  /**
   * Warning message to display under the input
   */
  warning?: string;
  /**
   * Is the input in an error state?
   * NOTE: This will be ignored if either `error` or `warning` is passed
   */
  isErroring?: boolean;
  /**
   * Is the input in a warning state?
   * NOTES:
   * - This will be ignored if either `error` or `warning` is passed
   * - `error` or `isErroring` will take precedence over this prop
   */
  isWarning?: boolean;
  /**
   * When turned on, the passed `label`, if passed -- or `defaultValue`, if passed,
   * will occupy the main space of the input when no value is selected
   * and will float to the top left when a value is selected
   *
   * WARNING:
   *
   * - Either `label` or `placeholder` are **required** when this prop is true
   * - `defaultValue` prop will be totally ignored when this prop is true
   * - `label` will take precedence over `placeholder` when both are passed and this prop is true
   */
  floatingLabelStyle?: boolean;
  /**
   * Label to display above the input. Not recommended to use in conjunction with
   * `floatingLabel`, as they serve the same purpose
   */
  label?: string;
  /**
   *  Creates a synthetic option as the first AND selected option of the dropdown which will be both
   *  selected by default and, at the same time, **unselectable** by the user
   *
   *  WARNING
   *
   * - Using this prop will override `defaultValue` prop
   *
   */
  placeholder?: string;
  /**
   * Helper text to display under the input
   */
  helper?: string;
  /**
   * Name you want to give to the input field
   */
  name?: string;
  /**
   * Is the input disabled?
   */
  disabled?: boolean;
  /**
   * Is the input locked (disabled with a lock icon)?
   */
  isLocked?: boolean;
}

// Ensure that when floatingLabelStyle is true, either label or placeholder is passed
type SelectPropsWithFloatingLabelConditional =
  | (BaseSelectProps & {
      floatingLabelStyle: true;
      label: string;
    })
  | (BaseSelectProps & {
      floatingLabelStyle: true;
      placeholder: string;
    })
  | (BaseSelectProps & {
      floatingLabelStyle?: false | undefined;
      placeholder?: string;
      label?: string;
    });

// Either `children` or `options` must be passed to the component
export type SelectProps =
  | (SelectPropsWithFloatingLabelConditional & {
      children: React.InputHTMLAttributes<HTMLSelectElement>["children"];
      options?: never;
    })
  | (SelectPropsWithFloatingLabelConditional & {
      options: Record<string, string>;
      children?: never;
    });

const generateChildrenOptions = (options: Record<string, string>) => {
  return Object.entries(options).map(([value, label]) => (
    <option key={value} value={value}>
      {label}
    </option>
  ));
};

export const Select = ({
  children,
  options,
  className,
  onChange,
  isLocked,
  error,
  warning,
  isErroring,
  isWarning,
  disabled,
  placeholder,
  helper,
  floatingLabelStyle,
  label,
  value,
  ...rest
}: SelectProps) => {
  const [floating, setFloating] = useState(!!value);
  useEffect(() => {
    if (value && !floating) {
      setFloating(true);
    }
  }, [value]);
  const PLACEHOLDER_VALUE = "";
  const needsPlaceholder = placeholder || floatingLabelStyle;
  const labelCalc = label || placeholder;
  // this will be the top option element when either placeholder or floatingLabelStyle is passed
  const placeholderElem = needsPlaceholder ? (
    <option value={PLACEHOLDER_VALUE} disabled>
      {floatingLabelStyle ? "--" : placeholder}
    </option>
  ) : null;
  const severity = error || isErroring ? "error" : warning || isWarning ? "warning" : null;
  const maybeFloatingLabelElem = floatingLabelStyle && (
    <label className={clsx(styles.floatingLabel, floating && styles.floating)}>{labelCalc}</label>
  );
  const maybeRegularLabelElem = !floatingLabelStyle && label && (
    <label className={styles.label}>{label}</label>
  );
  const defaultValueCalc = needsPlaceholder ? PLACEHOLDER_VALUE : value;
  const childrenCalc = options ? generateChildrenOptions(options) : children;
  return (
    <FormGroup
      className={clsx(styles.inputContainer)}
      errorMessage={error}
      warningMessage={warning}
      helper={helper}
      noMargins
    >
      {maybeFloatingLabelElem}
      {maybeRegularLabelElem}
      <select
        onChange={(e) => {
          onChange && onChange(e);
        }}
        className={clsx(
          styles.input,
          styles.select,
          floatingLabelStyle && styles.floatingLabelStyle,
          isLocked && styles.isLocked,
          severity && styles[severity],
          // hide the placeholder option when in floatingStyle and
          // NOT currently floating (e.g. when no value is selected yet)
          floatingLabelStyle && !floating && styles.hidden,
          className
        )}
        disabled={isLocked || disabled}
        value={value || defaultValueCalc}
        {...rest}
      >
        {placeholderElem}
        {childrenCalc}
      </select>
    </FormGroup>
  );
};
