import { http } from "./http";
import type { NewTransfer, Transfer, TransferOptions } from "./v2";

function create({
  accountID,
  transfer,
  idempotencyKey
}: {
  accountID: string;
  transfer: NewTransfer;
  idempotencyKey: string;
}): Promise<Transfer> {
  return http("/transfers", {
    xAccountID: accountID,
    method: "POST",
    json: transfer,
    headers: {
      "x-idempotency-Key": idempotencyKey,
      "x-wait-for": "rail-response"
    }
  });
}

function options({
  accountID,
  transfer
}: {
  accountID: string;
  transfer: Partial<NewTransfer>;
}): Promise<TransferOptions> {
  return http("/transfer-options", {
    method: "POST",
    xAccountID: accountID,
    json: transfer
  });
}

const TransfersAPI = {
  create,
  options
};

export default TransfersAPI;
