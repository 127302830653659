import { HttpResponse, http } from "msw";
import { DeepPartial } from "@moovfinancial/common/types/DeepTypes";
import { Transfer as LegacyTransfer } from "api/v2";
import { generateMockTransfer, mockGroup, mockGroupParent } from "tests/mocks/data/transfers.mock";

export const getGetTransferHandler = (transfer?: DeepPartial<LegacyTransfer>) =>
  http.get("*/api/accounts/:accountID/transfers/:transferId", () =>
    HttpResponse.json(generateMockTransfer(transfer))
  );

export const getGetTransfersHandler = (transfers?: DeepPartial<LegacyTransfer>[]) =>
  http.get("*/api/accounts/:accountID/transfers", () =>
    HttpResponse.json(transfers?.map(generateMockTransfer) ?? [])
  );

export const transferHandlers = [
  getGetTransferHandler(mockGroupParent),
  getGetTransfersHandler(mockGroup)
];
