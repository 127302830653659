import { Helmet, HelmetProvider } from "react-helmet-async";
import { RouterProvider, createBrowserRouter } from "react-router";
import { ToastContainer, cssTransition } from "react-toastify";
import GoogleRecaptcha from "@moovfinancial/common/utils/GoogleRecaptcha";
import { getEnv } from "@moovfinancial/common/utils/env";
import { initOpenTelemetry } from "@moovfinancial/common/utils/openTelemetry";
import useServiceWorker from "hooks/useServiceWorker";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "api/queryClient";
import { LiveMoovAPIClient, MoovAPIClient } from "api/v2";
import APIContextProvider from "contexts/APIContext";
import UserContextProvider from "contexts/UserContext";
import WindowResizeContextProvider from "contexts/WindowResizeContext";
import initFeatureFlagHelper from "helpers/featureFlags";
import { AppRoutes } from "./Routes/AppRoutes";
import { ErrorPage } from "./Routes/ErrorPage";

initFeatureFlagHelper();
initOpenTelemetry("dashboard");
const env = getEnv(true);
const moov = initAPIClient(env);

const ToastTransition = cssTransition({
  enter: "toast-slideUp",
  exit: "toast-slideDown",
  collapseDuration: 500,
  appendPosition: false,
  collapse: false
});

GoogleRecaptcha.init({
  environment: env
});

// eslint-disable-next-line no-console
console.log(`ℹ️ Moov Dashboard: %cv${__APP_VERSION__}`, "font-weight: bold");
// eslint-disable-next-line no-console
console.log(
  `ℹ️ Private API version: %c${import.meta.env.VITE_OPERATIONS_API_VERSION}`,
  "font-weight: bold"
);
// eslint-disable-next-line no-console
console.log(
  `ℹ️ Public API version: %c${import.meta.env.VITE_PUBLIC_API_VERSION}`,
  "font-weight: bold"
);

const router = createBrowserRouter([
  { path: "/*", element: <Root />, errorElement: <ErrorPage /> }
]);

function App() {
  return <RouterProvider router={router} />;
}

function Root() {
  useServiceWorker();

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <APIContextProvider moov={moov}>
          <UserContextProvider>
            <WindowResizeContextProvider>
              <HelmetProvider>
                <Helmet titleTemplate="%s | Moov Dashboard" defaultTitle="Moov Dashboard" />
                <AppRoutes />
                <ToastContainer
                  autoClose={4000}
                  className="toast-container"
                  closeButton={false}
                  hideProgressBar
                  limit={3}
                  pauseOnFocusLoss={false}
                  position="bottom-center"
                  toastClassName="toast"
                  transition={ToastTransition}
                />
              </HelmetProvider>
            </WindowResizeContextProvider>
          </UserContextProvider>
        </APIContextProvider>
      </QueryClientProvider>
    </div>
  );
}

function initAPIClient(env: string): MoovAPIClient {
  const baseURL =
    env === "production"
      ? "https://dashboard.moov.io/api"
      : env === "staging"
        ? "https://dashboard.moov-staging.io/api"
        : "/api";
  const moov = new LiveMoovAPIClient({ baseURL });
  (window as unknown as { moovAPI: MoovAPIClient }).moovAPI = moov;

  return moov;
}

export default App;
