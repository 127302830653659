import {
  components,
  type operations
} from "@moovfinancial/common/types/__generated-types__/moov-api";
import { Amount, PagingFilter } from "./common.model";
import { PaymentMethod } from "./paymentMethods/paymentMethods.model";

type Metadata = components["schemas"]["Metadata"];
export type Transfer = components["schemas"]["Transfer"];
export type TransferListFilters = operations["listTransfers"]["parameters"]["query"];
export type RTPFailureCode = components["schemas"]["RTPFailureCode"];
export type DebitHoldPeriod = "no-hold" | "1-day" | "2-days";
export type RTPTransactionDetails = components["schemas"]["RTPTransactionDetails"];
export type CardFailureCode = components["schemas"]["CardTransactionFailureCode"];
export type TransferSource = components["schemas"]["Transfer"]["source"];
export type RTPTransferStatus = components["schemas"]["RTPTransactionStatus"];
export type ACHTransferStatus = components["schemas"]["ACHTransactionStatus"];
export type RefundStatus = components["schemas"]["RefundStatus"];
export type CancellationStatus = components["schemas"]["CancellationStatus"];

export type ACHTransferStatusUpdates = {
  initiatedOn?: string;
  originatedOn?: string;
  failedOn?: string;
  completedOn?: string;
  returnedOn?: string;
  correctedOn?: string;
  canceledOn?: string;
  pendingCancellationOn?: string;
};

export interface ACHDetail extends ACHTransferStatusUpdates {
  status: ACHTransferStatus;
  traceNumber: string;
  return?: {
    code: string;
    reason: string;
    description: string;
  };
  correction?: {
    code: string;
    reason: string;
    description: string;
  };
  debitHoldPeriod?: DebitHoldPeriod;
  originatingCompanyName?: string;
  companyEntryDescription?: string;
  secCode?: "WEB" | "PPD" | "CCD" | "TEL";
}

export type RTPTransferStatusUpdates = {
  initiatedOn?: string;
  completedOn?: string;
  failedOn?: string;
  acceptedWithoutPostingOn?: string;
};

export interface RefundAmount {
  amount: number;
}

export const cardFailureReasons: Record<CardFailureCode, string> = {
  "amount-limit-exceeded": "The amount entered exceeds issuer or approved amount",
  "call-issuer": "Contact card issuer for decline reason",
  "card-not-activated": "The card has not been properly unblocked",
  "cardholder-account-closed": "The account is closed",
  "could-not-route":
    "The financial institution cannot be found for routing (receiving institution ID is invalid)",
  "cvv-mismatch": "The CVV entered is incorrect",
  "do-not-honor": "The issuer will not allow the transaction",
  "expired-card": "The card has expired or expiration date is missing",
  "incorrect-pin": "The PIN is incorrect or missing",
  "insufficient-funds": "This card has insufficient funds to complete the transaction",
  "invalid-amount": "The amount entered is invalid",
  "invalid-card-number": "The card number is incorrect",
  "invalid-merchant": "The merchant is invalid",
  "invalid-transaction": "The transaction is invalid",
  "issuer-not-available":
    "The Issuer was unavailable for the transaction and STIP is not applicable or not available for this transaction",
  "lost-or-stolen": "The card was reported as lost or stolen",
  "no-such-issuer": "The issuer is invalid",
  "processing-error": "The transaction could not be processed due to processing error",
  "reenter-transaction": "The transaction needs to be re-entered",
  "revocation-of-authorization": "Revocation of authorization order",
  "suspected-fraud": "The transaction was declined because of suspected fraud",
  "transaction-not-allowed": "The transaction was not allowed",
  "velocity-limit-exceeded": "The transaction exceeds withdrawal frequency limit",
  "unknown-issue": "The transaction was declined for an unknown reason",
  "duplicate-transaction": "The transaction was declined because it was a duplicate transaction"
};

export interface CardTransferStatusUpdates {
  initiatedOn?: string;
  failedOn?: string;
  canceledOn?: string;
  confirmedOn?: string;
  settledOn?: string;
  completedOn?: string;
}

export interface CardTransferDetail extends CardTransferStatusUpdates {
  status: CardTransferStatus;
  failureCode?: CardFailureCode;
  dynamicDescriptor?: string;
  transactionSource?: "first-recurring" | "recurring" | "unscheduled";
}

export type ApplePayTransferSource = components["schemas"]["ApplePayPaymentMethod"]["applePay"];

export type CardTransferStatus =
  | "initiated"
  | "confirmed"
  | "canceled"
  | "settled"
  | "failed"
  | "completed";

export type TransferPartialAccount = {
  accountID: string;
  displayName: string;
  email: string;
  profile?: unknown;
};

export interface MoovFeeDetails {
  cardScheme: string;
  interchange?: string;
  moovProcessing: string;
  discount?: string;
}
export interface Refund {
  refundID: string;
  createdOn: string;
  updatedOn: string;
  status: RefundStatus;
  amount: Amount;
  failureReason?: CardFailureCode;
  cardDetails?: CardTransferDetail;
}

export interface Cancellation {
  cancellationID: string;
  status: CancellationStatus;
  createdOn: string;
}

export interface Reversal {
  cancellation?: Cancellation;
  refund?: Refund;
}

export type TransferStatus =
  | "created"
  | "pending"
  | "completed"
  | "failed"
  | "canceled"
  | "reversed"
  | "queued"
  | "refunded" // fe-only
  | "disputed" // fe-only
  | "dispute-resolved" // fe-only
  | "pending-cancellation"; // fe-only

export type TransferLegStatus =
  | "initiated"
  | "originated"
  | "corrected"
  | "returned"
  | "completed"
  | "confirmed"
  | "settled"
  | "failed"
  | "refunded"
  | "refund-created"
  | "refund-pending"
  | "refund-completed"
  | "refund-failed"
  | "disputed"
  | "dispute-resolved"
  | "dispute-response-needed"
  | "dispute-reversal";

export type FailureReason =
  | "source-payment-error"
  | "destination-payment-error"
  | "wallet-insufficient-funds"
  | "rejected-high-risk"
  | "processing-error";

export interface NewTransfer {
  source: {
    paymentMethodID?: string;
    accountID?: string;
  };
  destination: {
    paymentMethodID?: string;
    accountID?: string;
  };
  amount: Amount;
  description: string;
}

export interface GetTransferOptions {
  source?: {
    paymentMethodID?: string;
    accountID?: string;
  };
  destination?: {
    paymentMethodID?: string;
    accountID?: string;
  };
  amount: Amount;
}

export interface TransferOptions {
  sourceOptions?: PaymentMethod[];
  destinationOptions?: PaymentMethod[];
}

export interface UpdateTransferMetadata {
  metadata?: Metadata;
}

export interface TransferListFilter extends PagingFilter {
  status?: string;
  accountIDs?: string;
  startDateTime?: Date;
  endDateTime?: Date;
  groupID?: string;
  refunded?: boolean;
  disputed?: boolean;
}

export const defaultTransferListFilter: TransferListFilter = {
  count: 20,
  skip: 0
};
