import { Fragment } from "react";
import { useId } from "@moovfinancial/common/hooks/useGetId";
import { SwitchButtonOptionType } from "./SwitchButton";

type SwitchButtonOptionProps = {
  option: SwitchButtonOptionType;
  selectedValue: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, option: SwitchButtonOptionType) => void;
};

export const SwitchButtonOption = ({
  option,
  selectedValue,
  onChange
}: SwitchButtonOptionProps) => {
  const id = useId(option.id, option.value);
  return (
    <Fragment key={`radio-button-label-${option.value}`}>
      <input
        id={id}
        value={option.value}
        type="radio"
        onChange={(event) => onChange(event, option)}
        // if we have a selectedValue, use that to determine which option is selected
        // otherwise, select the first option
        checked={selectedValue === option.value}
        disabled={option.disabled}
      />
      <label htmlFor={id}>{option.label}</label>
    </Fragment>
  );
};
