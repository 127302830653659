// prettier-ignore
export enum Resource {
  Accounts          = "/accounts/{accountID}",
  ApplePay          = "/accounts/{accountID}/apple-pay",
  Applications      = "/accounts/{accountID}/applications",
  BankAccounts      = "/accounts/{accountID}/bank-accounts",
  Capabilities      = "/accounts/{accountID}/capabilities",
  Cards             = "/accounts/{accountID}/cards",
  Connections       = "/accounts/{accountID}/connections",
  Files             = "/accounts/{accountID}/files",
  Invites           = "/accounts/{accountID}/invites",
  Keys              = "/accounts/{accountID}/keys",
  PaymentMethods    = "/accounts/{accountID}/payment-methods",
  Profile           = "/accounts/{accountID}/profile",
  Representatives   = "/accounts/{accountID}/representatives",
  Roles             = "/accounts/{accountID}/roles",
  Transfers         = "/accounts/{accountID}/transfers",
  Wallets           = "/accounts/{accountID}/wallets",
  Webhooks          = "/accounts/{accountID}/webhooks",
}

export enum MoovAdminResource {
  achConfig = "/ops/transfers-config/ach/{accountID}",
  adminAccountPatch = "/admin/accounts/{accountID}",
  adminBankAccounts = "/accounts/facilitators/{accountID}/bank-accounts/{bankAccountID}",
  adminMatch = "/dashboard/admin/accounts/{accountID}/match",
  adminRepVerification = "/admin/accounts/{accountID}/representatives/{representativeID}/manual-verification-requests",
  adminUnderwriting = "/admin/accounts/{accountID}/underwriting",
  adminVerification = "/admin/accounts/{accountID}/verifications",
  bankAccountBypass = "/admin/bank-accounts/bypass-list/{accountID}",
  adminDisputes = "/dashboard/admin/disputes/",
  disputeEvidence = "/admin/disputes/${disputeID}/evidence",
  encryptedData = "/admin/accounts/{accountID}/encrypted-data",
  statements = "/admin/accounts/{accountID}/underwriting/{underwritingID}/statements",
  lookbackReplay = "/admin/events/reproduce",
  documentStatus = "/admin/accounts/{accountID}/files/{fileID}",
  writeFinance = "finance.write",
  writeRisk = "risk.write",
  writeSupport = "support.write",
  readSupport = "support.read"
}

export enum Action {
  Read = "read",
  Write = "write",
  Admin = "admin"
}

export interface Policy {
  resource: string;
  action: Action;
}

export interface RoleTemplate {
  name: string;
  policies: Policy[];
  subjects: string[];
}

export interface Role {
  roleID: string;
  accountId: string;
  createdOn: string;
  createdBy: string;
  lastUpdatedOn: string;
  lastUpdatedBy: string;
  deletedOn?: string;
  deletedBy?: string;
  name: string;
  subjects: string[];
  policies: Policy[];
}

export interface CreateRole extends Pick<Role, "name" | "subjects" | "policies"> {}

export type PolicyTable = Record<Resource, [boolean, boolean] | null>;

export type MoovAdminRole = "finance" | "risk" | "support";
