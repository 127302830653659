import { Enum } from "@moovfinancial/common/types/Enum";
import { PartialRecord } from "@moovfinancial/common/types/PartialRecord";
import { Amount } from "./common.model";
import { CardBrand } from "./paymentMethods/cards.model";

export const disputePhaseMap = {
  "pre-dispute": "Pre-Dispute",
  inquiry: "Inquiry",
  chargeback: "Chargeback",
  unknown: "Unknown"
};

export type DisputePhase = keyof typeof disputePhaseMap;

export const disputeStatusMap = {
  "response-needed": "Needs Response",
  "under-review": "Under Review",
  accepted: "Accepted",
  closed: "Closed",
  expired: "Expired",
  lost: "Lost",
  resolved: "Resolved",
  submitted: "Submitted",
  won: "Won"
};

export type DisputeStatus = keyof typeof disputeStatusMap;

export const adminDisputeStatusMap = {
  "accepted-needs-review": "NR - Accepted",
  "accepted-submitted": "Accepted",
  "needs-review": "Needs Review",
  "pending-response": "Pending Response",
  closed: "Closed",
  expired: "Expired",
  lost: "Lost",
  resolved: "Resolved",
  submitted: "Submitted",
  won: "Won"
};

export type AdminDisputeStatus = keyof typeof adminDisputeStatusMap;

export interface Dispute {
  disputeID: string;
  createdOn: string;
  respondBy?: string;
  status?: DisputeStatus;
  amount: Amount;
  networkReasonCode: string;
  networkReasonDescription?: string;
  transfer: {
    transferID: string;
  };
  phase?: DisputePhase;
  submittedOn?: string;
}

export interface DisputeEnriched extends Dispute {
  displayName: string;
}

export interface DisputeReversal {
  disputeID: string;
  amount?: number;
}

export interface DisputeReversalResponse {
  Reversed: null | DisputeReversal[];
  Skipped:
    | null
    | {
        Reason: string;
        Dispute: DisputeReversal;
      }[];
}

export interface DisputeFilter {
  callingAccountID: string;
  cardholderAccountID: string;
  disputeIDs: string; // Comma-separated list of dispute ids
  endDateTime: string; // ISO-8601 string
  merchantAccountID: string;
  phase: DisputePhase;
  respondEndDateTime: string; // ISO-8601 string
  respondStartDateTime: string; // ISO-8601 string
  startDateTime: string; // ISO-8601 string
  status: DisputeStatus;
  transferIDs: string; // Comma-separated list of transfer ids
}

export type PartialDisputeFilter = PartialRecord<keyof DisputeFilter, string>;

export interface AdminDispute extends Omit<Dispute, "status"> {
  brand: CardBrand;
  callingAccountID: string;
  caseNumber: string;
  merchantAccountID: string;
  moovResponseDueDate: string;
  partialCreditReason: string;
  participantResponse: string;
  seNumber: string;
  status: AdminDisputeStatus;
  submittedOn: string;
}

export interface AdminDisputeEnriched extends AdminDispute {
  displayName: string;
  facilitator: string;
}

export interface AdminDisputeFilter {
  merchantAccountID: string;
  callingAccountID: string;
  transferIDs: string; // Comma-separated list of transfer ids
  disputeIDs: string; // Comma-separated list of dispute ids
  status: DisputeStatus; // Comma-separated list of statuses
  phase: DisputePhase;
  startDateTime: string;
  endDateTime: string;
  moovResponseDueStartDate: string;
  moovResponseDueEndDate: string;
  brand: string;
  orderBy: string; // field-name:direction
}

export type PartialAdminDisputeFilter = PartialRecord<keyof AdminDisputeFilter, string>;

export const evidenceTypeMap = {
  receipt: "Receipt",
  "proof-of-delivery": "Proof of delivery",
  "cancelation-policy": "Cancelation policy",
  "terms-of-service": "Terms of service",
  "customer-communication": "Customer communication",
  "generic-evidence": "Generic evidence",
  "cover-letter": "Cover letter",
  other: "Other"
};

export type EvidenceType = Enum<typeof evidenceTypeMap>;

export interface DisputeEvidence {
  createdOn: string;
  disputeID: string;
  evidenceID: string;
  evidenceType: EvidenceType;
  filename: string;
  mimeType: string;
  size: number;
  text: string;
  updatedOn: string;
}

export interface AdminDisputeEvidence extends DisputeEvidence {
  isAdminEvidence?: boolean;
}

export interface DisputeEvidenceUpdate {
  evidenceType: string;
  text?: string;
}

export interface DisputeEvidenceUpdateMetadata extends Omit<DisputeEvidence, "text"> {}

export interface DisputeEvidenceText {
  evidenceType: EvidenceType;
  text: string;
}

export interface AdminDisputeUpdate {
  status: string;
  amount?: number;
  caseNumber?: string;
}

export type AdminBulkDisputeUpdate = {
  disputeID: string;
  disputeStatus: AdminDisputeStatus | "";
  amount?: number;
  caseNumber?: string;
}[];

type AdminBulkUpdateSuccesses = {
  disputeID: string;
  status: AdminDisputeStatus;
}[];

type AdminBulkUpdateErrors = {
  disputeID: string;
  status: AdminDisputeStatus;
}[];

export interface AdminBulkDisputeUpdateResponse {
  successes: AdminBulkUpdateSuccesses | null;
  errors: AdminBulkUpdateErrors | null;
}

export type DisputeReversalType = "" | "full" | "partial";
