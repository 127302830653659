// @TODO: typeInOperationsApi: Remove this once the User type is defined in `operations-api`

/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Action, MoovAdminResource, MoovAdminRole, Resource, Role } from "api/Role.model";
import * as rolesApi from "api/Roles";
import { Session } from "api/Session.model";
import * as sessionsApi from "api/Sessions";
import { User, UserInvite } from "api/User.model";
import { UserAccount } from "api/v2/accounts.model";
import { accountToUserAccount } from "helpers/accountToUserAccount";
import { universalErrorHandlerToString } from "helpers/errorMessages";
import { loadFromLocalStorage, saveToLocalStorage } from "helpers/localStorage";
import { APIContext } from "./APIContext";

interface ContextProps {
  children: React.ReactNode;
}

export interface UserContextType {
  /** The active, logged-in user */
  user: User | null;
  /** Setter for the active, logged-in user */
  setUser: (user: User | Session | null) => void;
  /** Is the user verified? */
  isVerified: boolean;
  /** Setter for the user's list of facilitator accounts */
  setIsVerified: (isVerified: boolean) => void;
  /** The user's list of facilitator accounts */
  userAccounts: UserAccount[];
  /** Setter for the user's list of facilitator accounts */
  setUserAccounts: (userAccounts: UserAccount[]) => void;
  /** The currently selected user account. Either a facilitator id or "moov-admin" */
  activeUserAccountID: string;
  /** Setter for the currently selected user account */
  setActiveUserAccountID: (id: string) => void;
  /** Sets the currently selected user account. Handles the case where the account is not included in userAccounts. */
  setActiveUserAccountIDWithSuper: (id: string) => void;
  /** Stores the user's email during registration */
  registerEmail: string | null;
  /** Setter for the user's email during registration */
  setRegisterEmail: (email: string) => void;
  /** List of invites, where each one invites this user to a facilitator account */
  invites: UserInvite[];
  /** Setter for the user's list of invites */
  setInvites: (invites: UserInvite[]) => void;
  /** Determines if the user can perform a certain action on a given resource */
  userCan: (action: Action, resource: Resource | MoovAdminResource) => boolean;
  /** Determines if user is a Moov admin. Equivalent to activeUserAccountID === "moov-admin" */
  isSuper: boolean;
  /** Resets the user context */
  reset: () => void;
}

/** A single source of truth for the logged-in user.
 * Tracks the user and their selected user account (Facilitator ID or Moov Admin) */
export const UserContext = createContext<UserContextType>({
  registerEmail: null,
  setRegisterEmail: () => {},
  isVerified: false,
  setIsVerified: () => {},
  user: null,
  setUser: () => {},
  userAccounts: [],
  setUserAccounts: () => {},
  userCan: () => false,
  invites: [],
  setInvites: () => {},
  activeUserAccountID: "",
  setActiveUserAccountID: () => {},
  setActiveUserAccountIDWithSuper: () => {},
  isSuper: false,
  reset: () => {}
});

export default function UserContextProvider({ children }: ContextProps) {
  const { moov } = useContext(APIContext);
  const [registerEmail, setRegisterEmail] = useState<string | null>(null);
  // @TODO: typeInOperationsApi: Remove this once the User type is defined in `operations-api`
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [user, setUser] = useState<User>(null);
  const [userAccounts, setUserAccounts] = useState<UserAccount[]>([]);
  const [activeUserAccountID, setActiveUserAccountID] = useState<string>("");
  const [userRole, setUserRole] = useState<Role | null>(null);
  const [invites, setInvites] = useState<UserInvite[]>([]);
  const [isSuper, setIsSuper] = useState<boolean>(false);
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [moovAdminRoles, setMoovAdminRoles] = useState<Record<
    MoovAdminRole,
    Record<Action.Read | Action.Write, boolean>
  > | null>(null);

  useEffect(() => {
    setIsSuper(activeUserAccountID === "moov-admin");
  }, [activeUserAccountID]);

  // Get the user's role
  useEffect(() => {
    if (user && activeUserAccountID && isVerified) {
      if (activeUserAccountID === "moov-admin") {
        setUserRole(null);
        return;
      }
      rolesApi
        // @TODO: typeInOperationsApi: Remove this once the User type is defined in `operations-api`
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        .getUserRole(activeUserAccountID, user.userID)
        .then((r) => setUserRole(r || null))
        .catch((err: unknown) => {
          toast(universalErrorHandlerToString(err));
        });
    } else {
      setUserRole(null);
    }
  }, [user, activeUserAccountID, isVerified]);

  // Get user's super/moov admin status
  useEffect(() => {
    if (!user) return;
    let cancel = false;
    setMoovAdminRoles(null);

    const getMoovAdminRoles = [
      sessionsApi.hasFinanceRead(),
      sessionsApi.hasFinanceWrite(),
      sessionsApi.hasRiskRead(),
      sessionsApi.hasRiskWrite(),
      sessionsApi.hasSupportRead(),
      sessionsApi.hasSupportWrite()
    ];

    void Promise.allSettled(getMoovAdminRoles).then((response) => {
      if (!cancel) {
        const roleArray = response.map((role) => {
          if (role.status === "fulfilled") return role.value;
          return false;
        });

        const [financeRead, financeWrite, riskRead, riskWrite, supportRead, supportWrite] =
          roleArray;
        if (supportRead) setIsSuper(true);

        setMoovAdminRoles({
          finance: {
            read: financeRead,
            write: financeWrite
          },
          risk: {
            read: riskRead,
            write: riskWrite
          },
          support: {
            read: supportRead,
            write: supportWrite
          }
        });
      }
    });
    return () => {
      cancel = true;
    };
  }, [user, activeUserAccountID]);

  // Save activeUserAccountID to local storage
  useEffect(() => {
    if (activeUserAccountID) {
      const lasActiveAccountsByUser = loadFromLocalStorage<Record<string, string>>(
        "moovAccounts",
        {}
      );
      // @TODO: typeInOperationsApi: Remove this once the User type is defined in `operations-api`
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      lasActiveAccountsByUser[user?.userID as string] = activeUserAccountID;
      saveToLocalStorage("moovAccounts", lasActiveAccountsByUser);
    }
  }, [activeUserAccountID, userAccounts]);

  // Determines if user can perform some action
  const userCan = useCallback(
    (action: Action, resource: Resource | MoovAdminResource) => {
      const accountResource = resource?.replace("{accountID}", activeUserAccountID);
      const internalAccountResource = `/.internal/accounts/${activeUserAccountID}`;
      const adminResource = rolesApi.moovAdminPolicyTable[resource];

      if (adminResource) {
        const allowedMoovAdminRoles = adminResource[action];
        if (isSuper && moovAdminRoles && allowedMoovAdminRoles && action !== Action.Admin) {
          return !!allowedMoovAdminRoles.find((role) => moovAdminRoles[role][action]);
        }
        return false;
      }

      return !!userRole?.policies.find(
        (p) =>
          (p.resource === accountResource && p.action === action) ||
          (p.resource === internalAccountResource && p.action === Action.Admin)
      );
    },
    [userRole, activeUserAccountID, moovAdminRoles]
  );

  // Allows Moov Admins to set a facilitator as the active useraccount, even if they're not invited
  const setActiveUserAccountIDWithSuper = useCallback(
    async (accountID: string) => {
      if (!accountID || accountID === activeUserAccountID) {
        return;
      }
      if (accountID === "moov-admin") {
        setActiveUserAccountID("moov-admin");
        return;
      }
      setActiveUserAccountID(accountID);
      if (!userAccounts.find((ua) => ua.accountID === accountID)) {
        const [result, error] = await moov.accounts.get(accountID, accountID);
        if (error) {
          toast("Error getting account");
        } else if (result) {
          const newUserAccount = accountToUserAccount(result);
          newUserAccount.superaccess = true;
          setUserAccounts([...userAccounts, newUserAccount]);
        }
      }
    },
    [userAccounts, activeUserAccountID]
  );

  const reset = useCallback(() => {
    setUser(null);
    setUserAccounts([]);
    setActiveUserAccountID("");
    setIsVerified(false);
    setRegisterEmail(null);
    setInvites([]);
  }, []);

  return (
    <UserContext.Provider
      value={{
        registerEmail,
        setRegisterEmail,
        // @TODO: typeInOperationsApi: Remove this once the User type is defined in `operations-api`
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        user,
        setUser,
        userAccounts,
        setUserAccounts,
        userCan,
        invites,
        setInvites,
        activeUserAccountID,
        setActiveUserAccountID,
        setActiveUserAccountIDWithSuper,
        isSuper,
        isVerified,
        setIsVerified,
        reset
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
