import { Chance } from "chance";
import { Account, DateObject, DecryptedAccountData, Representative, UserAccount } from "api/v2";
import { mockRepresentative } from "./representatives.mock";

const chance = new Chance();

export const mockAccountID = chance.guid();

interface mockBusinessOptions {
  representatives?: Representative[];
  ownersProvided?: boolean;
}

export const generateMockBusinessAccount = ({
  representatives,
  ownersProvided
}: mockBusinessOptions = {}): Account => ({
  accountID: chance.guid(),
  accountType: "business",
  displayName: chance.company(),
  profile: {
    business: {
      legalBusinessName: chance.company(),
      businessType: chance.pickone([
        "llc",
        "partnership",
        "publicCorporation",
        "soleProprietorship",
        "trust",
        "privateCorporation",
        "unincorporatedAssociation",
        "unincorporatedNonProfit",
        "incorporatedNonProfit",
        "governmentEntity"
      ]),
      doingBusinessAs: chance.company(),
      website: chance.url(),
      address: {
        addressLine1: chance.address(),
        addressLine2: `#${chance.integer()}`,
        city: chance.city(),
        stateOrProvince: chance.state(),
        postalCode: chance.zip(),
        country: chance.country()
      },
      phone: {
        countryCode: chance.string({ alpha: false, numeric: true, length: 2 }),
        number: chance.phone({ formatted: false })
      },
      email: chance.email(),
      taxIDProvided: chance.bool(),
      description: chance.sentence(),
      representatives: representatives ?? [mockRepresentative],
      ownersProvided:
        ownersProvided ?? (representatives ? !!representatives?.length : chance.bool()),
      industryCodes: {
        naics: chance.natural().toString(),
        sic: chance.natural().toString(),
        mcc: chance.natural().toString()
      }
    }
  },
  createdOn: chance.date().toISOString(),
  updatedOn: chance.date().toISOString()
});

export const generateMockIndividualAccount = (): Account => ({
  accountID: chance.guid(),
  accountType: "individual",
  displayName: chance.company(),
  profile: {
    individual: {
      name: {
        firstName: chance.first(),
        middleName: chance.first(),
        lastName: chance.last(),
        suffix: chance.suffix()
      },
      address: {
        addressLine1: chance.address(),
        addressLine2: `#${chance.integer()}`,
        city: chance.city(),
        stateOrProvince: chance.state(),
        postalCode: chance.zip(),
        country: chance.country()
      },
      phone: {
        countryCode: chance.string({ alpha: false, numeric: true, length: 2 }),
        number: chance.phone({ formatted: false })
      },
      email: chance.email(),
      governmentIDProvided: chance.bool(),
      birthDateProvided: chance.bool()
    }
  },
  createdOn: chance.date().toISOString(),
  updatedOn: chance.date().toISOString()
});

export const generateMockGovernmentID = (type?: "ssn" | "itin", length?: "full" | "lastFour") => ({
  [type ?? chance.pickone(["ssn", "itin"])]: {
    [length ?? "full"]:
      length === "lastFour"
        ? chance.string({ pool: "0123456789", length: 4 })
        : chance.ssn({ dashes: false })
  }
});

export const generateMockBirhtDate = (date?: Partial<DateObject>) => ({
  day: date?.day ?? chance.integer({ min: 1, max: 28 }),
  month: date?.month ?? chance.integer({ min: 1, max: 12 }),
  year: date?.year ?? chance.integer({ min: 1900, max: 2021 })
});

export const generateMockEIN = () => ({
  number: chance.string({ pool: "0123456789", length: 9 })
});

export const generateDecryptedAccount = (): DecryptedAccountData => ({
  governmentID: generateMockGovernmentID(),
  taxID: { ein: generateMockEIN() },
  birthDate: generateMockBirhtDate()
});

export const mockBusinessAccount: Account = generateMockBusinessAccount();
export const mockIndividualAccount: Account = generateMockIndividualAccount();

/**
 * @deprecated Should use dynamic mocks in the future;
 */
export const mockUserAccount: UserAccount = {
  accountID: "ec7e1848-dc80-4ab0-8827-dd7fc0737b43",
  displayName: "Amanda Yang",
  accountMode: "production"
};

/**
 * @deprecated Should use dynamic mocks in the future;
 */
export const mockAccount: Account = {
  accountID: "ec7e1848-dc80-4ab0-8827-dd7fc0737b43",
  accountType: "business",
  displayName: "Amanda Yang",
  profile: {
    individual: {
      name: {
        firstName: "Amanda",
        middleName: "Mandy",
        lastName: "Yang",
        suffix: "Jr"
      },
      phone: {
        number: "818.555.1212",
        countryCode: "1"
      },
      email: "amanda@classbooker.dev",
      address: {
        addressLine1: "123 Main Street",
        addressLine2: "Apt 302",
        city: "Boulder",
        stateOrProvince: "Colorado",
        postalCode: "80301",
        country: "US"
      },
      birthDateProvided: false,
      governmentIDProvided: false
    },
    business: {
      legalBusinessName: "Moov Financial",
      doingBusinessAs: "Moov",
      businessType: "llc",
      address: {
        addressLine1: "123 Main Street",
        addressLine2: "Apt 302",
        city: "Boulder",
        stateOrProvince: "Colorado",
        postalCode: "80301",
        country: "US"
      },
      phone: {
        number: "818.555.1212",
        countryCode: "1"
      },
      email: "amanda@classbooker.dev",
      website: "https://www.wholebodyfitnessgym.com",
      description: "Local fitness gym paying out instructors",
      taxIDProvided: false,
      representatives: [
        {
          representativeID: "ec7e1848-dc80-4ab0-8827-dd7fc0737b43",
          name: {
            firstName: "Amanda",
            middleName: "Mandy",
            lastName: "Yang",
            suffix: "Jr"
          },
          phone: {
            number: "818.555.1212",
            countryCode: "1"
          },
          email: "amanda@classbooker.dev",
          address: {
            addressLine1: "123 Main Street",
            addressLine2: "Apt 302",
            city: "Boulder",
            stateOrProvince: "Colorado",
            postalCode: "80301",
            country: "US"
          },
          birthDateProvided: false,
          governmentIDProvided: false,
          responsibilities: {
            isController: false,
            isOwner: true,
            ownershipPercentage: 38,
            jobTitle: "CEO"
          },
          createdOn: "2019-08-24T14:15:22Z",
          updatedOn: "2019-08-24T14:15:22Z",
          disabledOn: "2019-08-24T14:15:22Z"
        },
        {
          representativeID: "ec7e1848-dc80-4ab0-8827-dd7fc0737b55",
          name: {
            firstName: "Christina",
            middleName: "Nicole",
            lastName: "Miller",
            suffix: "Jr"
          },
          phone: {
            number: "818.555.1212",
            countryCode: "1"
          },
          email: "christina@classbooker.dev",
          address: {
            addressLine1: "123 Main Street",
            addressLine2: "Apt 302",
            city: "Boulder",
            stateOrProvince: "Colorado",
            postalCode: "80301",
            country: "US"
          },
          birthDateProvided: false,
          governmentIDProvided: false,
          responsibilities: {
            isController: false,
            isOwner: true,
            ownershipPercentage: 38,
            jobTitle: "CEO"
          },
          createdOn: "2019-08-24T14:15:22Z",
          updatedOn: "2019-08-24T14:15:22Z",
          disabledOn: "2019-08-24T14:15:22Z"
        }
      ],
      ownersProvided: true,
      industryCodes: {
        naics: "811412",
        sic: "7623",
        mcc: "7623"
      }
    }
  },
  metadata: {
    property1: "string",
    property2: "string"
  },
  termsOfService: {
    token: "1234567890",
    acceptedDate: "2019-08-24T14:15:22Z",
    acceptedIP: "123.123.123.123"
  },
  customerSupport: {
    address: {
      addressLine1: "123 Anystreet",
      addressLine2: "Apt. 202",
      city: "Anytown",
      stateOrProvince: "IA"
    },
    email: "support@classbooker.dev",
    phone: {
      number: "867-530-9999",
      countryCode: "+1"
    },
    website: "https://support.classbooker.dev"
  },
  settings: {
    achPayment: {
      companyName: "Classbooker"
    },
    cardPayment: {
      statementDescriptor: "CLASSBKR"
    }
  },
  foreignID: "4528aba-b9a1-11eb-8529-0242ac13003",
  createdOn: "2019-08-24T14:15:22Z",
  updatedOn: "2019-08-24T14:15:22Z"
};

/**
 * @deprecated Should use dynamic mocks in the future;
 */
export const getFakeAccount = () => {
  return { ...mockBusinessAccount };
};
