import GoogleRecaptcha from "@moovfinancial/common/utils/GoogleRecaptcha";
import { getFingerprint } from "@moovfinancial/common/utils/fingerprint";
import { UserAccount } from "./accounts.model";
import { LiveMoovAPIClient } from "./api";
import { ErrorResponse } from "./request";
import {
  DeviceVerification,
  RegisterUser,
  UpdatePassword,
  UpdateUser,
  User,
  UserInvite
} from "./users.model";

export interface UsersAPI {
  /** Begins the sign up process for a new Moov user */
  signUp(email: string, referral?: string): Promise<[null | undefined, ErrorResponse | undefined]>;
  /** Registers a user as a new Moov user */
  register(registerUser: RegisterUser): Promise<[User | undefined, ErrorResponse | undefined]>;
  /** Signs a user into the dashboard */
  signIn(email: string, password: string): Promise<[User | undefined, ErrorResponse | undefined]>;
  /** Updates user data */
  updateUser(
    userID: string,
    updateUser: UpdateUser
  ): Promise<[UpdateUser | undefined, ErrorResponse | undefined]>;
  /** Changes the password for a user */
  changePassword(
    updatePassword: UpdatePassword
  ): Promise<[null | undefined, ErrorResponse | undefined]>;
  /** Recovers the password for a user */
  recoverPassword(email: string): Promise<[User | undefined, ErrorResponse | undefined]>;
  /** Resets password for recovery */
  resetPasswordForRecovery(
    email: string,
    password: string,
    confirmPassword: string,
    token: string
  ): Promise<[User | undefined, ErrorResponse | undefined]>;
  /** Verifies the user's device */
  verifyDevice(
    fingerprint?: string | null
  ): Promise<[DeviceVerification[] | undefined, ErrorResponse | undefined]>;
  /** Sends a code to verify the user's device */
  sendVerifyDeviceCode(): Promise<[null | undefined, ErrorResponse | undefined]>;
  /** Registers a new device to a Moov user */
  registerDevice(
    code: string
  ): Promise<[DeviceVerification | undefined, ErrorResponse | undefined]>;
  /** Pings the Moov API */
  ping(): Promise<[null | undefined, ErrorResponse | undefined]>;
  /** List the Moov facilitators connected to this Moov user */
  listUserAccounts(userID: string): Promise<[UserAccount[] | undefined, ErrorResponse | undefined]>;
  /** List the invites received by this user */
  listUserInvites(userID: string): Promise<[UserInvite[] | undefined, ErrorResponse | undefined]>;
}

export class LiveUsersAPI implements UsersAPI {
  private _client: LiveMoovAPIClient;

  constructor(client: LiveMoovAPIClient) {
    this._client = client;
  }

  async signUp(
    email: string,
    referral?: string
  ): Promise<[null | undefined, ErrorResponse | undefined]> {
    const body = { email, referral };
    if (!referral) delete body.referral;
    const recaptchaToken = await GoogleRecaptcha.execute("signUp");
    const [result, err] = await this._client.request<null>("/signup", {
      method: "POST",
      json: body,
      headers: recaptchaToken ? { "x-recaptcha": recaptchaToken } : undefined
    });
    return [result, err];
  }

  async register(
    registerUser: RegisterUser
  ): Promise<[User | undefined, ErrorResponse | undefined]> {
    const fingerprint = await getFingerprint();
    window.sessionStorage.setItem("fingerprint", fingerprint);
    const recaptchaToken = await GoogleRecaptcha.execute("register");
    const [result, err] = await this._client.request<User>("/auth/signin/password/register", {
      method: "POST",
      json: { ...registerUser, fingerprint },
      headers: recaptchaToken ? { "x-recaptcha": recaptchaToken } : undefined
    });
    return [result, err];
  }

  async signIn(
    email: string,
    password: string
  ): Promise<[User | undefined, ErrorResponse | undefined]> {
    const recaptchaToken = await GoogleRecaptcha.execute("signIn");
    const [result, err] = await this._client.request<User>("/auth/signin/password", {
      method: "POST",
      json: { email, password },
      headers: recaptchaToken ? { "x-recaptcha": recaptchaToken } : undefined
    });
    return [result, err];
  }

  async updateUser(
    userID: string,
    updateUser: UpdateUser
  ): Promise<[UpdateUser | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<UpdateUser>(`/users/${userID}`, {
      method: "PUT",
      json: updateUser
    });
    return [result, err];
  }

  async changePassword(
    updatePassword: UpdatePassword
  ): Promise<[null | undefined, ErrorResponse | undefined]> {
    const recaptchaToken = await GoogleRecaptcha.execute("changePassword");
    const [result, err] = await this._client.request<null>(
      "/auth/signin/password/change-password",
      {
        method: "POST",
        json: updatePassword,
        headers: recaptchaToken ? { "x-recaptcha": recaptchaToken } : undefined
      }
    );
    return [result, err];
  }

  async recoverPassword(email: string): Promise<[User | undefined, ErrorResponse | undefined]> {
    const recaptchaToken = await GoogleRecaptcha.execute("recoverPassword");
    const [result, err] = await this._client.request<User>("/auth/signin/password/recover", {
      method: "POST",
      json: { email },
      headers: recaptchaToken ? { "x-recaptcha": recaptchaToken } : undefined
    });
    return [result, err];
  }

  async resetPasswordForRecovery(
    email: string,
    password: string,
    confirmPassword: string,
    token: string
  ): Promise<[User | undefined, ErrorResponse | undefined]> {
    const recaptchaToken = await GoogleRecaptcha.execute("resetPassword");
    const [result, err] = await this._client.request<User>(
      "/auth/signin/password/recover/reset-password",
      {
        method: "POST",
        json: { email, password, confirmPassword, token },
        headers: recaptchaToken ? { "x-recaptcha": recaptchaToken } : undefined
      }
    );
    return [result, err];
  }

  async verifyDevice(
    fingerprint?: string | null
  ): Promise<[DeviceVerification[] | undefined, ErrorResponse | undefined]> {
    let validFingerprint = fingerprint;
    if (!fingerprint) {
      validFingerprint = await getFingerprint();
      window.sessionStorage.setItem("fingerprint", validFingerprint);
    }
    const recaptchaToken = await GoogleRecaptcha.execute("verify");
    const [result, err] = await this._client.request<DeviceVerification[]>("/auth/verify", {
      method: "POST",
      json: { fingerprint: validFingerprint },
      headers: recaptchaToken ? { "x-recaptcha": recaptchaToken } : undefined
    });
    return [result, err];
  }

  async sendVerifyDeviceCode(): Promise<[null | undefined, ErrorResponse | undefined]> {
    const recaptchaToken = await GoogleRecaptcha.execute("sendVerify");
    const [result, err] = await this._client.request<null>("/auth/verify/email/send", {
      method: "POST",
      json: {},
      headers: recaptchaToken ? { "x-recaptcha": recaptchaToken } : undefined
    });
    return [result, err];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async registerDevice(code: string): Promise<[DeviceVerification | undefined, any]> {
    let fingerprint;
    try {
      fingerprint = await getFingerprint();
      window.sessionStorage.setItem("fingerprint", fingerprint);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      return [undefined, err];
    }
    const [result, err] = await this._client.request<DeviceVerification>("/auth/verify/email", {
      method: "POST",
      json: { code, fingerprint }
    });
    return [result, err];
  }

  async ping(): Promise<[null | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<null>("/ping");
    return [result, err];
  }

  async listUserAccounts(
    userID: string
  ): Promise<[UserAccount[] | undefined, ErrorResponse | undefined]> {
    const [result, err] = await this._client.request<UserAccount[]>(`/users/${userID}/accounts`);
    return [result, err];
  }

  async listUserInvites(
    userID: string
  ): Promise<[UserInvite[] | undefined, ErrorResponse | undefined]> {
    const [result, err, resp] = await this._client.request<UserInvite[]>(
      `/users/${userID}/invites`
    );
    if (resp?.status === 404) {
      return [[], undefined];
    }
    return [result, err];
  }
}
